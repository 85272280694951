﻿angular.module('natApp').factory('LoginwemailService', ['$http', '$q', 'WebApiUrl', 'AppConfig', function ($http, $q, WebApiUrl, AppConfig) {
    return {
        Login: function (Email, Password, LoginWithoutEmail, AppDomain) {
            return $http.post(WebApiUrl + 'User/Login?Source=' + AppConfig.Source, {
                Email: Email,
                Password: Password,
                LastName: '',
                AccessCode: '',
                LicenseNumber: '',
                LoginWithoutEmail: LoginWithoutEmail,
                AppDomain: (AppDomain ? AppDomain : '')
            });
        },
        EntityLogin: function (Email, Password, LoginWithoutEmail) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'User/EntityLogin?Source=cbafirm', {
                Email: Email,
                Password: Password,
                LastName: '',
                AccessCode: '',
                LicenseNumber: '',
                LoginWithoutEmail: LoginWithoutEmail
            }).then(function (res) {
                deferred.resolve(res);
            }, function (res) {
                deferred.reject(res);
            });
            return deferred.promise;
        },
        ForgetPassword: function (Email, source) {

            return $http.post(WebApiUrl + 'User/ForgetPassword?Source=' + source, {
                "Email": Email
            });
        },
        ValidateUserEmailandSendOTP: function (Email, OTPby) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'User/ValidateUserEmailandSendOTP?Email=' + Email + '&OTPby=' + OTPby + '&Source=' + AppConfig.Source).then(function (res) {
                deferred.resolve(res);
            }, function (res) {
                deferred.reject(res);
            });
            return deferred.promise;
        },
        ValidateStaffEmailandSendOTP: function (Email, OTPby) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'User/ValidateStaffEmailandSendOTP?Email=' + Email + '&OTPby=' + OTPby + '&Source=' + AppConfig.Source).then(function (res) {
                deferred.resolve(res);
            }, function (res) {
                deferred.reject(res);
            });
            return deferred.promise;
        },
        VerifyOTP: function (data) {
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'User/VerifyOTP', data).then(function (res) {
                deferred.resolve(res);
            }, function (res) {
                deferred.reject(res);
            });
            return deferred.promise;
        },
        ConfigurationGetbySettings: function (key, setting) {
            return $http.get(WebApiUrl + 'Configuration/ConfigurationGetbySettings/' + key + '?setting=' + setting);
        },
        UserRoleGetBYUserID: function (urlParm) {
            ApiPath = 'Users/UserRoleGetBYUserID' + urlParm;
            return $http.get(WebApiUrl + ApiPath);
        },
        FirmLogging: function (reqData, key) {
            var logSaveReq = {
                ModelData: reqData
            };
            var deferred = $q.defer();
            $http.post(WebApiUrl + 'Provider/ProviderCommentLogSave/' + key + '/' , logSaveReq).then(function (data) {
                data = data.data;
                deferred.resolve(data);
            }, function (error) {
                error = error.data;
                deferred.reject(error);
            });
            return deferred.promise;
        },
        AuditvisitInfoSave: function (params, data) {
            return $http.post(WebApiUrl + 'Logging/AuditvisitInfoSave/' + params, data);
        },
        LogSave: function (params, data) {
            return $http.post(WebApiUrl + 'Logging/LogSave/' + params, data);
        },
        ApplicationBYIndividualId: function (urlParm) {
            ApiPath = 'Individual/ApplicationBYIndividualId' + urlParm;
            return $http.get(WebApiUrl + ApiPath);
        },
        logout: function () {
            return $http.get(WebApiUrl + 'User/LogOut/' + sessionStorage.Key);
        }
    };
}]);