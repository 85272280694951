(function () {
    'use strict';

    angular
        .module('natApp')
        .controller("ucLicenseRenewalApplicationController", ucLicenseRenewalApplicationController);

    ucLicenseRenewalApplicationController.$inject = ["$scope", "$timeout", "$rootScope", "initialApplicationstatusService", "deficiencyService", "backofficeIndividualPersonalService", "backofficeIndividualAdressService",
        "htmlToPdfGenerationService", "LicenseRenewalHASnAService", "utilityService", "typeValuesService", "licenseRenewalService", "licenseApplicationListingService",
        "$q", "$state", "TypeValue", "$uibModal", "initialApplicationAcknowledgementService", "licenseOutputService", "Messages", "dcaIService", "ApplicationSettings", "backofficeIndividualLicenseService"
    ];

    function ucLicenseRenewalApplicationController($scope, $timeout, $rootScope, initialApplicationstatusService, deficiencyService, backofficeIndividualPersonalService, backofficeIndividualAdressService,
        htmlToPdfGenerationService, LicenseRenewalHASnAService, utilityService, typeValuesService, licenseRenewalService, licenseApplicationListingService,
        $q, $state, TypeValue, $uibModal, initialApplicationAcknowledgementService, licenseOutputService, Messages, dcaIService, ApplicationSettings, backofficeIndividualLicenseService) {

        var curDate = null;
        sessionStorage.PaymentMethodType = "";
        $scope.PersonalInfo = {};
        $scope.isRenewal = true;
        $scope.additionalCss = ['app/content/css/processHtmltoPdf.css'];
        $scope.renewalInfoHtmlContent = null;
        $scope.acknowledgmentAndDeclarationHtmlContent = null;
        $scope.paymentHtmlContent = null;
        $scope.CEHtmlContent = null;
        $scope.personalHtmlContent = null;
        
        $scope.init = function () {
            $scope.DisplayApplication = false;
            try {
                if (sessionStorage.PaymentDone == "true") {
                    $state.go('app.Loginwoemail');
                }
                if ($scope.isUserSessionActive()) {
                    curDate = sessionStorage.curDate;
                    $scope.AckAppliciantDate = curDate;
                    $scope.getIndividualViewConfig();
                } else {
                    $state.go('app.Loginwoemail');
                }
            } catch (ex) {
                if (ex != null) {
                    showStatusMessage(ex.message, "error");
                }
            }
        };

        $scope.deleteApplicationAndMoveToDashboard = function (applicationInfo) {
            var modalInstance = $uibModal.open({
                animation: true,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: 'deleteApplication.html',
                controller: 'ExtendLicensePreviewController',
                size: 'lg',
                resolve: {
                    contentInput: {
                        contentTypeId: 98,
                        headerForPopup: "Delete Application"
                    }
                }
            });

            modalInstance.result.then(function () {
                ShowLoader();
                licenseRenewalService.individualTablesDelete(sessionStorage.Key,
                    sessionStorage.IndividualId, applicationInfo.ApplicationId, false)
                    .then(function (response) {
                        if ($scope.checkServerResponse(response)) {
                            $state.go("app.Dashboard");
                        }
                    }, function (data) {
                        HideLoader();
                        if (data != null) {
                            $scope.showStatusMessage(data.message, "error");
                        }
                    });
            }, function () {
                console.log("Not Extension");
                return;
            });
        };

        var processTabsToBeShownForDeficiency = function () {
            var allTabs = $scope.individualViewConfig.processedTabs;
            var processedTabs = [];
            angular.forEach(allTabs, function (thisTab) {
                // if (!thisTab.hideInDeficiencyProcessing) {
                    processedTabs.push(thisTab);
                // }
            });

            $scope.grabHTMLElements();

            $scope.processHiddenTabs();
        };

        $scope.processHiddenTabs = function () {
            var allTabs = $scope.individualViewConfig.processedTabs;
            var processedTabs = [];
            angular.forEach(allTabs, function (thisTab) {
                if (!thisTab.hideInDeficiencyProcessing) {
                    processedTabs.push(thisTab);
                }
            });
            $scope.individualViewConfig.processedTabs = processedTabs;
        };

        $scope.$on('processTabsToBeShown', function (event, data) {
            processTabsToBeShown(data.hideCETab);
        });

        // $scope.$on('processTabsToBeShownIfNotRetired', function (event, data) {
        //     processTabsToBeShownIfNotRetired(data);
        // }); 

        // var processTabsToBeShownIfNotRetired = function (data) {
        //     // $scope.processedTabsIfNotRetired = true;
        //     // var processedTabs = [];
        //     angular.forEach($scope.individualViewConfig.renewalConfig.tabs, function (tab) {
        //         if ((data=='9' || data=='10' || data=='4') && $rootScope.isRetiredOrDelinquent &&
        //             (tab.name == "feeAndPayment" || tab.name == "acknowledgmentAndDeclaration" || tab.name == "Submission")){
        //             tab.isEnabled = true;
        //         } else if ((data=='7' || data=='8' || data=='5'|| data=='1') && $rootScope.isRetiredOrDelinquent &&
        //             (tab.name == "feeAndPayment" || tab.name == "acknowledgmentAndDeclaration" || tab.name == "Submission")) {
        //                 tab.isEnabled = false;
        //         }  
        //     });
        // };

        var processTabsToBeShown = function (hideCETab) {
            var processedTabs = [];
            $scope.validator = {};
            $scope.individualViewConfig.paymentTabNumber = 0;

            angular.forEach($scope.individualViewConfig.renewalConfig.tabs, function (tab) {
                if ((tab.name == 'continuingeducation') || ($rootScope.isRetiredOrDelinquent && (tab.name == 'feeAndPayment' || tab.name == 'acknowledgmentAndDeclaration' || tab.name == "Submission"))) {
                    if ($scope.individualLicense?.LicenseStatusTypeId == 4 
                            || $scope.individualLicense?.LicenseStatusTypeId == 9 
                            || $scope.individualLicense?.LicenseStatusTypeId == 10) {
                        hideCETab = true;
                    }
                    if (!hideCETab) {
                        tab.isEnabled = true;
                    }
                    else {
                        tab.isEnabled = false;
                    }
                }

                if ($rootScope.isRetiredOrDelinquent) {
                    if ( (!(tab.name == "instructions" || tab.name == "personalData" || tab.name == "renewalInfo" || tab.name == "acknowledgmentAndDeclaration" || tab.name == "feeAndPayment" || tab.name == "staffReview"))) {
                        tab.isEnabled = false;
                    }
                }
            });

            angular.forEach($scope.individualViewConfig.renewalConfig.tabs, function (renewalTab) {
                if (renewalTab.isEnabled && (($scope.isBackoffice && !renewalTab.hideBackOffice) || (!$scope.isBackoffice && !renewalTab.hideOnline))) {
                    if (renewalTab.queues.indexOf($rootScope.routeData.queueType) != -1) {
                        if (renewalTab.isInitialTab == true)
                            renewalTab.showAfterRenewalTabs = true;
                        processedTabs.push(renewalTab);
                        if (!!renewalTab.isPaymentTab) {
                            $scope.individualViewConfig.paymentTabNumber = (processedTabs.length + 1);
                        }
                    }
                }
                $scope.validator[renewalTab.name] = {};
            });
            $scope.individualViewConfig.processedTabs = processedTabs;
        };

        $scope.processTabsToBeShownAsPerGroup = function () {
            angular.forEach($scope.individualViewConfig.processedTabs, function (renewalTab) {
                renewalTab.isEnabled = false;
                renewalTab.showAfterRenewalTabs = false;
                angular.forEach($scope.renewalCheckData.renewalGroups, function (renewalGroup) {
                    if (renewalTab.renewalGroups.indexOf(renewalGroup) != -1) {
                        renewalTab.isEnabled = true;
                        renewalTab.showAfterRenewalTabs = true;
                    }
                });
                if (!!$scope.currentRenewalItem && !!$scope.currentRenewalItem.ApplicationReviewReasonIds) {
                    if (renewalTab.name == 'historyDisclosure') {
                        if ($scope.currentRenewalItem.ApplicationReviewReasonIds.indexOf('1') != -1) {
                            renewalTab.showVerificationIcon = true;
                        }
                    } else if (renewalTab.name == 'personalData') {
                        if ($scope.currentRenewalItem.ApplicationReviewReasonIds.indexOf('3') != -1) {
                            renewalTab.showVerificationIcon = true;
                        }
                    } else if (renewalTab.name == 'renewaltype') {
                        if ($scope.currentRenewalItem.ApplicationReviewReasonIds.indexOf('4') != -1) {
                            renewalTab.showVerificationIcon = true;
                        }
                    }
                }
            });
        };

        $scope.getIndividualViewConfig = function () {
            if ($scope.isUserSessionActive()) {
                var relativeFilePath = "/components/individual/application/application.server-config.json";
                utilityService.getConfigData(relativeFilePath)
                    .then(function (data) {
                        console.log("personal check", data);
                        $scope.individualViewConfig = data;
                        $scope.listConfig.individualViewConfig = $scope.individualViewConfig;
                        //processTabsToBeShown();
                        $scope.getInitData();
                    }, function (error) {

                    });
            } else {
                $state.go('app.BackofficeLoginstaff')
            }
        };

        $scope.openNextTab = function (currentTab, donotProcessLoader) {
            var feePaymentTabEnable = true;
            for (var i = 0; i < $scope.individualViewConfig.processedTabs.length; i++) {
                if ($scope.individualViewConfig.processedTabs[i].name == currentTab.name) {
                    var dataPromises = [];
                    dataPromises.push($scope.saveTabStatus(currentTab));
                    //dataPromises.push($scope.saveTabHtml(currentTab, currentTab.tabNumber));
                    if (!donotProcessLoader)
                        ShowLoader();
                    $q.all(dataPromises)
                        .then(function (response) {
                            $scope.individualViewConfig.processedTabs[i].isSaved = true;
                            if (!donotProcessLoader)
                                HideLoader();

                            if (!!$scope.individualViewConfig.processedTabs[i].isEnabled && $scope.individualViewConfig.processedTabs[i].name != 'feeAndPayment' && !$scope.individualViewConfig.processedTabs[i].isSaved) {
                                feePaymentTabEnable = false;
                            }

                            for (var j = i + 1; j < $scope.individualViewConfig.processedTabs.length; j++) {
                                if (!!$scope.individualViewConfig.processedTabs[j].isEnabled && $scope.individualViewConfig.processedTabs[j].name != 'feeAndPayment' && !$scope.individualViewConfig.processedTabs[j].isSaved) {
                                    feePaymentTabEnable = false;
                                }
                                if (!!feePaymentTabEnable) {
                                    $scope.ProceedtoPaymentclick();
                                }
                            }

                            for (var j = i + 1; j < $scope.individualViewConfig.processedTabs.length; j++) {
                                if ($scope.individualViewConfig.processedTabs[j].isEnabled === true) {
                                    if (currentTab.name == "renewaltype") {
                                        $scope.processTabsToBeShownAsPerGroup();
                                    }
                                    $scope.setCurrentTab($scope.individualViewConfig.processedTabs[j]);
                                    break;
                                }
                            }
                        });
                    break;
                }
                else {
                    if (!!$scope.individualViewConfig.processedTabs[i].isEnabled && $scope.individualViewConfig.processedTabs[i].name != 'feeAndPayment' && !$scope.individualViewConfig.processedTabs[i].isSaved) {
                        feePaymentTabEnable = false;
                    }
                }
            }
        };

        $scope.saveTabStatus = function (tab) {
            var applicableQueueType = $rootScope.routeData.queueType;
            if (applicableQueueType == "DeficiencyQ" || applicableQueueType == "TechDeficiencyQ") {
                applicableQueueType = "IntakeQ";
            }
            var reqData = {
                ApplicationTabStatusId: tab.ApplicationTabStatusId,
                ApplicationId: $scope.applicationId,
                PageModuleId: 0,
                PageModuleTabSubModuleId: 0,
                PageTabSectionId: 0,
                IndividualId: $scope.individualId,
                ProviderId: $scope.individualId,
                TabName: (applicableQueueType + "-" + tab.name),
                ApplicationTabStatus: 1,
                SortOrder: 1,
                IsActive: 1,
                IsDeleted: 0,
                CreatedBy: $scope.userId,
                CreatedOn: new Date()
            };
            return licenseRenewalService.saveTabStatus(reqData, sessionStorage.Key);
        };

        $scope.saveTabHtml = function (tab, tabNumber) {
            var htmlContent = angular.element('#tabSection' + tab.name).parent().html();
            htmlContent = htmlContent.replace(/\<textarea /g, '<div class="textAreaReplaced"><div ').replace(/\<\/textarea>/g, '</div></div>');
            htmlContent = htmlContent.replace(/\n|\t/g, ' ');
            htmlContent = htmlContent.replace(/\>\s\s+\</g, '><');
            htmlContent = htmlContent.replace(/ng[-,a-z,A-Z,0-9,.]*="[a-z,A-Z,0-9,.,\s,(,),;,=,',!,$,{,},:,?,-]*"/g, '');
            htmlContent = htmlContent.replace(/ng-binding|ng-pristine|ng-untouched|ng-isolate-scope|ng-not-empty|ng-invalid|ng-invalid-date/g, '');
            var htmlContentElement = angular.element('<div>' + htmlContent + '</div>');
            var textAreaElements = angular.element(htmlContentElement).find('.textAreaReplaced');
            angular.forEach(textAreaElements, function (textAreaElement, index) {
                if (!!textAreaElement.firstChild && !!textAreaElement.firstChild.attributes && !!textAreaElement.firstChild.attributes.value && !!textAreaElement.firstChild.attributes.value.value)
                    textAreaElement.innerHTML = "<pre>" + textAreaElement.firstChild.attributes.value.value + "</pre>";
            });
            var reqData = {
                ApplicationID: $scope.applicationId,
                IndividualID: $scope.individualId,
                TabName: ($rootScope.routeData.queueType + "-" + tab.name),
                CreatedBy: $scope.userId,
                DataDesc: "New Application section " + tab.name + " Html",
                HTMLContent: angular.element(htmlContentElement).html(),
                SortingOrder: tabNumber
            };

            return htmlToPdfGenerationService.saveTabHtml(reqData, sessionStorage.Key);
        };

        var processIsDeficient = function (isInitialProcessing) {
            var isDeficient = false;
            var isDeficiencyResolved = true;
            var isDefeciencyReviewedByIndividual = true;
            var isStaffDeficiencyExists = false;
            var onlystaffDeficiencyExists = true;

            $scope.individualViewConfig.processedTabs.forEach(function (tabData, j) {
                var isTabDeficient = false;
                var isTabDeficiencyResolved = true;
                var isTabDefeciencyReviewedByIndividual = true;

                if (!!tabData.individualDefeciency && !!tabData.individualDefeciency.IndividualDefeciencyDtlList && tabData.individualDefeciency.IndividualDefeciencyDtlList.length > 0) {
                    tabData.individualDefeciency.IndividualDefeciencyDtlList.forEach(function (deficiencyDtl, i) {
                        if (deficiencyDtl.IsDefecient && (!deficiencyDtl.ResolvedByStaff || deficiencyDtl.ResolvedByStaff.toLowerCase() != 'y')) {
                            isDeficient = true;
                            isTabDeficient = true;

                            if (!tabData.individualDefeciency.IndividualDefeciencyDtlList[i].IsDefeciencyResolved) {
                                onlystaffDeficiencyExists = false;
                                isDeficiencyResolved = false;
                                isTabDeficiencyResolved = false;
                            }
                            if (!tabData.individualDefeciency.IndividualDefeciencyDtlList[i].IsDefeciencyReviewedByIndividual && (!tabData.individualDefeciency.IndividualDefeciencyDtlList[i].ResolvedByStaff || tabData.individualDefeciency.IndividualDefeciencyDtlList[i].ResolvedByStaff.toLowerCase() != 'y')) {
                                isDefeciencyReviewedByIndividual = false;
                                isTabDefeciencyReviewedByIndividual = false;
                            }
                        } else if (deficiencyDtl.IsDefecient && (!!deficiencyDtl.ResolvedByStaff && deficiencyDtl.ResolvedByStaff.toLowerCase() == 'y')) {
                            if (!tabData.individualDefeciency.IndividualDefeciencyDtlList[i].IsDefeciencyResolved) {
                                isStaffDeficiencyExists = true;
                            }
                            isDeficient = true;
                            if (!!$scope.isBackoffice)
                                isTabDeficient = true;
                            if (!!$scope.isBackoffice && !tabData.individualDefeciency.IndividualDefeciencyDtlList[i].IsDefeciencyResolved) {
                                isDeficiencyResolved = false;
                                isTabDeficiencyResolved = false;
                            }
                        }
                    });
                }
                tabData.isTabDeficient = isTabDeficient;
                tabData.isTabDeficiencyResolved = isTabDeficiencyResolved;
                tabData.isTabDefeciencyReviewedByIndividual = isTabDefeciencyReviewedByIndividual;
                if (!$scope.isBackoffice && !!isInitialProcessing && ($scope.applicationInfo.DeficiencyStatusId == 1)) {
                    if (!tabData.isTabDeficient || !!tabData.isTabDeficiencyResolved) {
                        tabData.hideInDeficiencyProcessing = true;
                        if (tabData.name == "personalData") {
                            tabData.hideInDeficiencyProcessing = false;
                        }
                    }
                    //if (!tabData.isTabDeficient) { // && tabData.name != 'renewaltype') {
                    //    tabData.hideInDeficiencyProcessing = true;
                    //} else {
                    tabData.showAfterRenewalTabs = true;
                    //}
                }
            });

            if (!$scope.isBackoffice && !!isInitialProcessing && ($scope.applicationInfo.DeficiencyStatusId == 1)) {
                processTabsToBeShownForDeficiency();
            }
            $scope.individualViewConfig.isDeficient = isDeficient;
            $scope.individualViewConfig.isDeficiencyResolved = isDeficiencyResolved;
            $scope.individualViewConfig.isDefeciencyReviewedByIndividual = isDefeciencyReviewedByIndividual;
            $scope.individualViewConfig.onlystaffDeficiencyExists = onlystaffDeficiencyExists;
            if (!!$scope.isBackoffice || (!$scope.isBackoffice && !!isInitialProcessing)) {
                $scope.individualViewConfig.isStaffDeficiencyExists = isStaffDeficiencyExists;
            }
        };

        $scope.$on("deficiencyChanged", function (event, args) {
            processIsDeficient();
        });

        $scope.$on("personalDataChanged", function (event, args) {
            processIsDeficient(true);
        });

        var getDeficiencyData = function (tab, savedDeficiencydata) {
            var tabDeficiencyData = tab.deficiencyData;
            var deficiencyObj = {};
            if (!savedDeficiencydata) {
                deficiencyObj = {
                    ApplicationId: $scope.applicationId,
                    IndividualId: $scope.individualId,
                    IndividualLicenseId: $scope.individualLicense.IndividualLicenseId,
                    WorkflowQueueName: "IntakeQ",
                    TabName: tab.name,
                    SortOrder: tab.tabNumber,
                    IsActive: true,
                    IndividualDefeciencyDtlList: []
                };
                angular.forEach(tabDeficiencyData, function (tabDeficiencyDataItem) {
                    deficiencyObj.IndividualDefeciencyDtlList.push({
                        IndividualId: $scope.individualId,
                        IsDefecient: false,
                        DefeciencyReasonId: tabDeficiencyDataItem.DeficiencyReasonId,
                        DefeciencyReasonText: tabDeficiencyDataItem.DeficiencyReasonMessage,
                        ResolvedByStaff: tabDeficiencyDataItem.ResolvedByStaff,
                        DeficiencyReasonTypeId: tabDeficiencyDataItem.DeficiencyReasonTypeId,
                        IsRecordBasedDeficiency: tabDeficiencyDataItem.IsRecordBasedDeficiency,
                        VisibleInQueueMenuIds: tabDeficiencyDataItem.VisibleInQueueMenuIds,
                        EditableInQueueMenuIds: tabDeficiencyDataItem.EditableInQueueMenuIds,
                        AdditionalText: tabDeficiencyDataItem.AdditionalText,
                        ExplanationRequired: tabDeficiencyDataItem.ExplanationRequired,
                        IsActive: true,
                    });
                })
            } else {
                deficiencyObj = savedDeficiencydata;
                var deficiencyDetails = []
                angular.forEach(tabDeficiencyData, function (tabDeficiencyDataItem) {
                    var deficiencyExists = false;
                    angular.forEach(deficiencyObj.IndividualDefeciencyDtlList, function (individualDefeciencyDtlItem) {
                        if (individualDefeciencyDtlItem.DefeciencyReasonId == tabDeficiencyDataItem.DeficiencyReasonId) {
                            deficiencyExists = true;
                            individualDefeciencyDtlItem.ResolvedByStaff = tabDeficiencyDataItem.ResolvedByStaff;
                            individualDefeciencyDtlItem.DeficiencyReasonTypeId = tabDeficiencyDataItem.DeficiencyReasonTypeId;
                            individualDefeciencyDtlItem.IsRecordBasedDeficiency = tabDeficiencyDataItem.IsRecordBasedDeficiency;
                            individualDefeciencyDtlItem.VisibleInQueueMenuIds = tabDeficiencyDataItem.VisibleInQueueMenuIds;
                            individualDefeciencyDtlItem.EditableInQueueMenuIds = tabDeficiencyDataItem.EditableInQueueMenuIds;
                            individualDefeciencyDtlItem.AdditionalText = tabDeficiencyDataItem.AdditionalText;
                            individualDefeciencyDtlItem.ExplanationRequired = tabDeficiencyDataItem.ExplanationRequired;
                            // individualDefeciencyDtlItem.name = tabDeficiencyDataItem.name;
                            deficiencyDetails.push(individualDefeciencyDtlItem);
                        }
                    });
                    if (!deficiencyExists) {
                        deficiencyDetails.push({
                            IndividualId: $scope.individualId,
                            IsDefecient: false,
                            DefeciencyReasonId: tabDeficiencyDataItem.DeficiencyReasonId,
                            DefeciencyReasonText: tabDeficiencyDataItem.DeficiencyReasonMessage,
                            ResolvedByStaff: tabDeficiencyDataItem.ResolvedByStaff,
                            DeficiencyReasonTypeId: tabDeficiencyDataItem.DeficiencyReasonTypeId,
                            IsRecordBasedDeficiency: tabDeficiencyDataItem.IsRecordBasedDeficiency,
                            VisibleInQueueMenuIds: tabDeficiencyDataItem.VisibleInQueueMenuIds,
                            EditableInQueueMenuIds: tabDeficiencyDataItem.EditableInQueueMenuIds,
                            AdditionalText: tabDeficiencyDataItem.AdditionalText,
                            ExplanationRequired: tabDeficiencyDataItem.ExplanationRequired,
                            IsActive: true,
                            IsDefeciencyResolved: false
                        });
                    }
                });
                deficiencyObj.IndividualDefeciencyDtlList = deficiencyDetails;
            }
            return deficiencyObj;
        };

        var getDeficiencyReasonDataforTab = function (tabItem) {
            var deficiencyData = [];
            angular.forEach($scope.deficiencyReasonData, function (deficiencyReason) {
                if (tabItem.tabNumber == deficiencyReason.PageModuleTabSubModuleId) {
                    deficiencyData.push(deficiencyReason);
                }
            });
            return deficiencyData;
        };

        $scope.getAllTabStatus = function () {
            var applicableQueueType = $rootScope.routeData.queueType;
            if (applicableQueueType == "DeficiencyQ" || applicableQueueType == "TechDeficiencyQ") {
                applicableQueueType = "IntakeQ";
            }
            licenseRenewalService.getAllTabStatus($scope.applicationId, $scope.individualId, sessionStorage.Key)
                .then(function (response) {
                    if (response.Status == true) {
                        $scope.tabStatusList = response.ProviderTabStatusList;
                        var deficiencyDataPromise = [
                            deficiencyService.getIndividualDeficiency(sessionStorage.Key, $scope.individualId, $scope.applicationId, ''),
                            typeValuesService.getTypeValuesByTypeNameAndParams(TypeValue.RefTablesGetAll, sessionStorage.Key, true, ("?tableKey=tabdeficiencyreason")),
                            initialApplicationAcknowledgementService.acknowledgementGet(sessionStorage.Key, $scope.individualId, $scope.applicationId, '107'),
                            backofficeIndividualPersonalService.individualBYIndividualId(sessionStorage.Key, $scope.individualId)
                        ];
                        $q.all(deficiencyDataPromise).then(function (responseData) {
                            var feePaymentTabEnable = true;
                            $scope.deficiencyReasonData = responseData[1].ReferenceEntities.whereEquals($scope.applicationInfo.ApplicationTypeId, 'ApplicationTypeId');
                            if (!!responseData[3] && responseData[3].IndividualResponse)
                                $scope.individualDetail = responseData[3].IndividualResponse[0];
                            $scope.individualAffidavit = responseData[2].IndividualAffidavitResponseList;;
                            $scope.individualDefeciencyAllList = responseData[0].IndividualDefeciencyAllList;
                            for (var j = 0; j < $scope.individualViewConfig.processedTabs.length; j++) {
                                $scope.individualViewConfig.processedTabs[j].isSaved = $scope.tabStatusList.whereContains($scope.individualViewConfig.processedTabs[j].name, 'TabName').select('ApplicationTabStatus').firstOrDefault();
                                $scope.individualViewConfig.processedTabs[j].isStatusLoaded = true;
                                $scope.individualViewConfig.processedTabs[j].deficiencyData = getDeficiencyReasonDataforTab($scope.individualViewConfig.processedTabs[j]);
                                if (!!$scope.individualViewConfig.processedTabs[j].deficiencyData && $scope.individualViewConfig.processedTabs[j].deficiencyData.length > 0) {
                                    if (!!$scope.individualDefeciencyAllList) {
                                        for (var i = 0; i < $scope.individualDefeciencyAllList.length; i++) {
                                            if ($scope.individualDefeciencyAllList[i].TabName == $scope.individualViewConfig.processedTabs[j].name) {
                                                $scope.individualViewConfig.processedTabs[j].individualDefeciency = getDeficiencyData($scope.individualViewConfig.processedTabs[j], $scope.individualDefeciencyAllList[i]);
                                            }
                                        }
                                    }
                                    if (!$scope.individualViewConfig.processedTabs[j].individualDefeciency)
                                        $scope.individualViewConfig.processedTabs[j].individualDefeciency = getDeficiencyData($scope.individualViewConfig.processedTabs[j], null);
                                }
                            }
                            for (var j = 0; j < $scope.individualViewConfig.processedTabs.length; j++) {
                                if (!!$scope.individualViewConfig.processedTabs[j].isEnabled && $scope.individualViewConfig.processedTabs[j].name != 'feeAndPayment' && !$scope.individualViewConfig.processedTabs[j].isSaved) {
                                    feePaymentTabEnable = false;
                                }
                            }
                            if (!!feePaymentTabEnable) {
                                $scope.ProceedtoPaymentclick();
                            }
                            processAffidavits();
                            processIsDeficient(true);
                            if (!!$scope.openPaymentTab) {
                                setTabByNumber($scope.individualViewConfig.paymentTabNumber);
                            } else {
                                if (utilityService.isLocalServerAndDebuggingEnabled()) {
                                    setTabByNumber(0);
                                } else {
                                    setTabByNumber(0);
                                }
                            }
                        });
                    }
                });
        };

        $scope.setCurrentTab = function (tab) {
            if (tab != null) {
                if (!$scope.isBackoffice && !!tab.openIfAllPreviousComplete) {
                    var isAlltabsComplete = true;
                    for (var i = 0; i < $scope.individualViewConfig.processedTabs.length; i++) {
                        if ($scope.individualViewConfig.processedTabs[i].name == tab.name) {
                            break;
                        }
                        if (!$scope.individualViewConfig.processedTabs[i].isSaved && $scope.individualViewConfig.processedTabs[i].isEnabled)
                            isAlltabsComplete = false;
                    }
                    if (!isAlltabsComplete) {
                        showStatusMessage(Messages.General.Errors.CompleteTab($scope.individualViewConfig.processedTabs[i].text), 'warning');
                        utilityService.scrollTop();
                        return;
                    }
                }

                var isAlltabsSaved = true;
                var isAlltabsSavedExceptPayment = true;
                for (var i = 0; i < $scope.individualViewConfig.processedTabs.length; i++) {
                    $scope.individualViewConfig.processedTabs[i].isActiveTab = false;
                    $scope.individualViewConfig.processedTabs[i].isActiveTab = false;
                    if (!$scope.individualViewConfig.processedTabs[i].isSaved && $scope.individualViewConfig.processedTabs[i].isEnabled) {
                        isAlltabsSaved = false;
                        if (!['feeAndPayment', 'staffReview'].includes($scope.individualViewConfig.processedTabs[i].name)) {
                            isAlltabsSavedExceptPayment = false;
                        }
                    }
                }

                $scope.individualViewConfig.isAlltabsSaved = isAlltabsSaved;
                $scope.individualViewConfig.isAlltabsSavedExceptPayment = isAlltabsSavedExceptPayment;
                $scope.individualCurrentTab = tab;
                if (tab) {
                    tab.isActiveTab = true;
                    tab.isLoadedOnce = true;
                }
                auditVisitInfoSave($scope.individualCurrentTab);
                $scope.canChangeTabs = true;
                $timeout(function () {
                    utilityService.scrollToElementOffset("applicationFormRenewal");
                }, 1000)
            }
        };

        var setTabByNumber = function (tabNumber) {
            if (!!$scope.individualViewConfig.processedTabs && $scope.individualViewConfig.processedTabs.length > tabNumber)
                $scope.setCurrentTab($scope.individualViewConfig.processedTabs[tabNumber]);
        };

        var processAffidavits = function () {
            angular.forEach($scope.individualAffidavit, function (affidavitItem) {
                if (affidavitItem.ContentItemLkId == 125) {
                    $scope.Acknowledgement = affidavitItem;
                }
            })
        };

        
        $scope.DisplayCASLicensePrimaryStatus = async function(ApplicationInformation){
            ShowLoader();
            let requestData = {
                isn: ApplicationInformation,
            }
                try {
                    return await dcaIService.getCaLicFilePrimStatCde(sessionStorage.Key, requestData)
                        .then(function (response){
                        
                            if (!!response.EntityRecords && response.EntityRecords.length > 0) {
                                // return response.EntityRecords;
                                angular.forEach(response.EntityRecords, function (eachRecord) {
                                    if  (eachRecord.primStatCde.includes(13))
                                    {
                                        $scope.isRetiredOrDelinquent = true;
                                        $rootScope.isRetiredOrDelinquent = true;
                                    }
                                    else {
                                        $scope.isRetiredOrDelinquent = false;
                                        $rootScope.isRetiredOrDelinquent = false;
                                    }
                                });
                            }
                            
                        }, function(data) {
                            $scope.showStatusMessage(data, "error");
                           
                        });
                } catch (ex) {
                    if (ex != null) {
                        $scope.showStatusMessage(ex.message, "error");
                    }
                }
            }

        

        $scope.individualRenewalGet = async function () {
            $scope.userId = (!$scope.isBackoffice ? sessionStorage.UserId : sessionStorage.UserID);
            $scope.showRenewalTypeEnabled = true;
            $scope.DisplayApplication = true;
            $scope.ShowRenewalApplicationHASnA = true;
            $scope.PersonalInfo = [];
            $scope.IndividualRenewal = [];
            $scope.personalData = {};
            $scope.applicationId = $scope.renewalCheckData.Application.ApplicationId;
            $scope.individualId = $scope.renewalCheckData.IndividualId;
            $scope.individualLicense = $scope.renewalCheckData.IndividualLicenseList[0];
            $scope.individualIdNumber = $scope.individualLicense.IDNumber;
            await $scope.DisplayCASLicensePrimaryStatus($scope.individualIdNumber);
            $scope.isRetiredOrDelinquent = $rootScope.isRetiredOrDelinquent;
            $scope.getActiveLicense();
            var hideCETab = false;
            if ($scope.individualLicense.LicenseStatusTypeId == 9 || $scope.individualLicense.LicenseStatusTypeId == 10) {
                $rootScope.submissionLicenseStatusTypeId = 3;
                hideCETab = true;
            }
            processTabsToBeShown(hideCETab);
            $scope.licenseTypeGetAll();
            $scope.getAllTabStatus();
            //required

            $scope.applicationInfo = $scope.renewalCheckData.Application;

            if (!!$scope.currentDeficiencyItemData) {
                $scope.currentDeficiencyItemData.ApplicationId = $scope.applicationInfo.ApplicationId;
                $scope.currentDeficiencyItemData.ApplicationStatusId = $scope.applicationInfo.ApplicationStatusId;
            }
            $scope.feeDetails = {
                licenseRenewalFee: 100,
                firstLicenseFee: 125,
                additionalLicenseFee: 75,
                certificateFee: 100,
                retiredWithWallFee: 20,
                retiredWithoutWallFee: 0,
                lateFee: 0
            };
            $scope.initializeAcknowledgement = true;
            $scope.initializeFeeDetails = true;
            processApplicationFeeAndRenewalData();
            if (($scope.isBackoffice && !!$scope.IsPendingApplication && !!$scope.DisplayAddApplication) || (!$scope.isBackoffice && $scope.renewalCheckData.Application.DeficiencyStatusId != 1)) {
                var renewalItem = {
                    ApplicationStatusId: $scope.renewalCheckData.Application.ApplicationStatusId,
                    ApplicationId: $scope.renewalCheckData.Application.ApplicationId,
                    IndividualId: $scope.renewalCheckData.IndividualId,
                    LicenseNumber: ((!!$scope.renewalCheckData && !!$scope.renewalCheckData.IndividualLicenseList && $scope.renewalCheckData.IndividualLicenseList.length > 0) ? $scope.renewalCheckData.IndividualLicenseList[0].LicenseNumber : '')
                };
                if (!$scope.currentRenewalItem)
                    $scope.currentRenewalItem = {};
                if ($scope.isBackoffice) {
                    if (renewalItem.ApplicationStatusId == 14 || renewalItem.ApplicationStatusId == 1 || renewalItem.ApplicationStatusId == 10) {
                        //Edit
                        $scope.IsPendingApplication = true;
                        renewalItem.disableSubmitControls = false;
                        renewalItem.disableRenewalForm = false;
                        renewalItem.DisableSaveButton = false;
                        renewalItem.DisableSubmitButton = false;
                        renewalItem.ShowPayAndApproveButton = true;
                        renewalItem.ShowConfirmAndApproveButton = true;
                        $scope.ProceedtoPaymentSectionEnable = false;
                        //"Save" ,"Pay Only","Pay and Approve", "Close"
                        //Form Not disabled

                    } else if (renewalItem.ApplicationStatusId == 2 || renewalItem.ApplicationStatusId == 13 || renewalItem.ApplicationStatusId == 15) {
                        //Verify
                        renewalItem.disableSubmitControls = false;
                        renewalItem.disableRenewalForm = true;
                        renewalItem.DisableSaveButton = true;
                        renewalItem.DisableSubmitButton = true;
                        renewalItem.ShowPayAndApproveButton = true;
                        renewalItem.ShowConfirmAndApproveButton = true;
                        $scope.ProceedtoPaymentSectionEnable = true;
                        //"Save" ,"Confirm And Approve", "Close"
                        //Form Not disabled
                    } else if (renewalItem.ApplicationStatusId == 3) {
                        //View
                        renewalItem.disableSubmitControls = true;
                        renewalItem.disableRenewalForm = true;
                        renewalItem.DisableSaveButton = true;
                        renewalItem.DisableSubmitButton = true;
                        renewalItem.ShowPayAndApproveButton = false;
                        renewalItem.ShowConfirmAndApproveButton = true;
                        $scope.ProceedtoPaymentSectionEnable = true;

                        //"Save"----Disabled ,"Confirm And Approve"----Disabled, "Close"
                        //Form disabled
                    }
                }
                $scope.currentRenewalItem = renewalItem;
                if ($scope.callCreatePendingRenewal)
                    $scope.createPendingRenewal();
                else
                    $scope.proceedToRenewalApp(false);
            } else {
                if (!$scope.isBackoffice && $scope.renewalCheckData.Application.DeficiencyStatusId == 1) {
                    $scope.ProceedtoPaymentSectionEnable = true;
                } 
                // else if(!$scope.isBackoffice && $rootScope.isRetiredOrDelinquent) {
                //     renewalItem.disableSubmitControls = false;
                //     renewalItem.disableRenewalForm = false;
                //     renewalItem.DisableSaveButton = false;
                //     renewalItem.DisableSubmitButton = false;
                //     renewalItem.ShowPayAndApproveButton = true;
                //     renewalItem.ShowConfirmAndApproveButton = true;
                //     $scope.ProceedtoPaymentSectionEnable = false;
                // }
                $scope.proceedToRenewalApp(false);
            }
        };

        $scope.setShowRenewalType = function (showRenewalTypeEnabled) {
            $scope.showRenewalTypeEnabled = showRenewalTypeEnabled;
        };

        $scope.proceedToRenewalApp = function (showRenewalTypeEnabled) {
            if (!showRenewalTypeEnabled) {
                $scope.individualId = $scope.renewalCheckData.IndividualId;
                $scope.applicationInfo = $scope.renewalCheckData.Application;
                $scope.applicationId = $scope.applicationInfo.ApplicationId;
                $scope.processTabsToBeShownAsPerGroup();
            }
            $scope.showRenewalTypeEnabled = showRenewalTypeEnabled;
        };

        $scope.licenseTypeGetAll = function () {
            try {
                LicenseRenewalHASnAService.LicenseTypeGetAll(sessionStorage.Key)
                    .then(function (response) {
                        if ($scope.checkServerResponse(response)) {
                            $scope.LicenseTypeGetAll = response.LicenseTypeGetList;
                            for (var i in $scope.LicenseTypeGetAll) {
                                if ($scope.LicenseTypeGetAll[i].LicenseTypeId == $scope.individualLicense.LicenseTypeId) {
                                    $scope.individualLicense.LicenseTypeName = $scope.LicenseTypeGetAll[i].LicenseTypeName;
                                    $scope.LicenseTypeName = $scope.LicenseTypeGetAll[i].LicenseTypeName;
                                    sessionStorage.LicenseTypeName = $scope.LicenseTypeName;
                                }
                            }
                        }
                    }, function (data) {
                        if (data != null) {
                            showStatusMessage(data.message, "error");
                        }
                    });
            } catch (ex) {
                if (ex != null) {
                    showStatusMessage(ex.message, "error");
                }
            }
        };

        $scope.IsAllTheSectionSaved = false;
        $scope.saveRecord = function (isSaveOnly) {
            $rootScope.ProcessLoaderExternally = true;
            initialApplicationstatusService.resetAll($scope.individualLicense, $scope.PayandSubmitbtnClicked);
            $rootScope.ProcessLoaderExternally = true;
            ShowLoader();
            $scope.$broadcast("SaveRenewal", {
                showConfirmationPopup: (!$scope.PayandSubmitbtnClicked && !isSaveOnly)
            });
            var requestData = {
                EntityId: $scope.individualId,
                EntityType: "I"
            };
            dcaIService.updateName(sessionStorage.Key, requestData).then(function (responseUpdate) {
                if (!responseUpdate.Status) {
                    $scope.showStatusMessage({
                        messageContent: 'Some error occured !',
                        messageType: 'error'
                    });
                }
            }, function (err) {
                $scope.showStatusMessage({
                    messageContent: 'Some error occured !',
                    messageType: 'error'
                });
            });
            var intervalDataSave = setInterval(function () {
                var allTabsProcessedStatus = initialApplicationstatusService.getAllTabsProcessedStatus();
                if (!!allTabsProcessedStatus) {
                    clearInterval(intervalDataSave);
                    var allTabsStatus = initialApplicationstatusService.getAllTabStatus();
                    $scope.IsAllTheSectionSaved = allTabsStatus;
                    if (allTabsStatus) {
                        if (!$scope.PayandSubmitbtnClicked) {
                            if (!isSaveOnly) {
                                $scope.ProceedtoPaymentclick();
                                HideLoader();
                            } else {
                                HideLoader();
                            }
                        } else {
                            if ($scope.isApplicationSubmitWithoutPayment) {
                                applicationStatusUpdate(13);
                            } else {
                                $scope.PayandSubmitTabRenewal();
                            }
                        }
                    } else {
                        HideLoader();
                        $scope.ProceedtoPaymentSectionEnable = false;
                        $('#applicationIncompleteModal').modal('show');
                    }
                }
            }, 500);
        };

        $scope.ProceedtoPaymentclick = function () {
            $scope.ProceedtoPaymentSectionEnable = true;
            //$scope.$apply();
        };

        $scope.PayandSubmitTabRenewal = function () {
            $scope.PayandSubmitbtnClicked = false;
            $scope.$broadcast("PayandSubmit", {});
            //Wait for payment submit and check whether payment is succeed 
            //There may be chance to payment failure.Only the form should submit only after payment success
            var paymentStatus = '';
            var interval = setInterval(function () {
                paymentStatus = initialApplicationstatusService.getPaymetStatus();
                if (!!paymentStatus && paymentStatus != "waiting") {
                    clearInterval(interval);
                    $rootScope.ProcessLoaderExternally = false;
                    if (paymentStatus == "success") {
                        if (!$scope.isBackoffice) {
                            $state.go('app.PaymentConfirmation', {
                                entityId: $scope.individualId,
                                entityType: 'I',
                                applicationId: $scope.applicationInfo.ApplicationId,
                                applicationTypeId: $scope.applicationInfo.ApplicationTypeId,
                                paymentMethodTypeId: $rootScope.SelectedPaymentMethod,
                                isReSubmit: false,
                                applicationStatusId: ''
                            });
                        } else {
                            $scope.getLicenseeRenewalList($scope.pagination.currentPage);
                        }
                    }
                    if (paymentStatus == "error") {
                        HideLoader();
                    }
                }
            }, 500);
        };

        $scope.payandSubmitbtnClick = function () {
            $rootScope.ProcessLoaderExternally = true;
            $scope.PayandSubmitbtnClicked = true;
            $scope.saveRecord();
        };

        $scope.$on("PayandSubmitTabRenewal", function (event, args) {
            $scope.PayandSubmitTabRenewal(false);
        });

        var applicationStatusUpdate = function (status) {
            ShowLoader();
            var applicationSubmitPromise = licenseApplicationListingService.applicationStatusUpdate(sessionStorage.Key, $scope.applicationInfo.ApplicationId, status, {});
            var applicationSubmitLkPromise = applicationStatusLkSave(sessionStorage.UserID, status);
            var applicationSubmitChangeLogPromise = applicationStatusChangeLogSaveData(sessionStorage.UserID, status);
            $q.all([applicationSubmitPromise, applicationSubmitLkPromise, applicationSubmitChangeLogPromise]).
                then(function (responseAppSubmitted) {
                    if (!$scope.isBackoffice) {
                        HideLoader();
                        $state.go('app.PaymentConfirmation', {
                            entityId: $scope.individualId,
                            entityType: 'I',
                            applicationId: $scope.applicationInfo.ApplicationId,
                            applicationTypeId: $scope.applicationInfo.ApplicationTypeId,
                            paymentMethodTypeId: '',
                            isReSubmit: false,
                            applicationStatusId: ''
                        });
                    }
                    $scope.applicationInfo.ApplicationStatusId = status;
                }, function (error) {
                    HideLoader();
                    $scope.isdisable = false;
                    showStatusMessage(
                        error,
                        'error'
                    );
                });
        };

        var applicationStatusLkSave = function (userId, applicationStatusId) {
            var reqData = {
                ApplicationId: $scope.applicationInfo.ApplicationId,
                ApplicationTypeId: $scope.applicationInfo.ApplicationTypeId,
                ApplicationStatusId: applicationStatusId,
                IsActive: 1,
                CreatedBy: userId,
                HighPriority: false
            };

            return licenseApplicationListingService.applicationStatusLkSave(sessionStorage.Key, userId, reqData);
        };

        var applicationStatusChangeLogSaveData = function (userId, applicationStatusId) {
            var reqData = {
                ApplicationId: $scope.applicationInfo.ApplicationId,
                ApplicationNumber: $scope.applicationInfo.ApplicationNumber,
                ApplicationTypeId: $scope.applicationInfo.ApplicationTypeId,
                ApplicationStatusId: applicationStatusId,
                ApplicationSubmitMode: 'B',
                LicenseTypeId: $scope.applicationInfo.LicenseTypeId
            };

            return licenseApplicationListingService.applicationStatusChangeLogSave(sessionStorage.Key, userId, reqData);
        };

        $scope.$on("ApplicationSubmitWithoutPayment", function (event, args) {
            $scope.isApplicationSubmitWithoutPayment = true;
            $scope.PayandSubmitbtnClicked = true;
            $scope.saveRecord();
        });

        $scope.grabHTMLElements = function () {
            // var ceSummaryElem =  angular.element(".ce-summary-tbl");
            // angular.element(ceSummaryElem).removeClass("ng-hide");
            // var ceDetailsElem =  angular.element(".ce-details-tbl");
            // angular.element(ceDetailsElem).addClass("ng-hide");

            // if (!$scope.personalHtmlContent) {
            $scope.personalHtml = angular.element('#tabSectionpersonalData')[0]?.outerHTML;
            //     $scope.personalHtmlContent = angular.element($scope.personalHtml);
            //     if (!!$scope.personalHtmlContent)
            //     $scope.personalHtmlContent = angular.element('#tabSectionpersonalData')[0].outerHTML;
            // }
            
            if (!$scope.renewalInfoHtmlContent) {
                $scope.renewalInfoHtml = angular.element('#tabSectionrenewalInfo').parent();
                $scope.renewalInfoHtmlContent = angular.element($scope.renewalInfoHtml).html();
                if (!!$scope.renewalInfoHtmlContent)
                $scope.renewalInfoHtmlContent = $scope.renewalInfoHtmlContent.replace(new RegExp('app/content/images/', 'g'), '');
            }

            if (!$scope.acknowledgmentAndDeclarationHtmlContent) {
                $scope.acknowledgmentAndDeclarationHtml = angular.element('#tabSectionacknowledgmentAndDeclaration').parent();            
                $scope.acknowledgmentAndDeclarationHtmlContent = angular.element($scope.acknowledgmentAndDeclarationHtml).html();
                if (!!$scope.acknowledgmentAndDeclarationHtmlContent)
                $scope.acknowledgmentAndDeclarationHtmlContent = $scope.acknowledgmentAndDeclarationHtmlContent.replace(new RegExp('app/content/images/', 'g'), '');
            }
            
            // if (!$scope.CEHtmlContent) {
                // $scope.CEHtml = angular.element('#tabSectioncontinuingeducation').parent();
                // $scope.CEHtmlContent = angular.element($scope.CEHtml).html();
                // if (!!$scope.CEHtmlContent)
                $scope.CEHtmlContent = angular.element('#tabSectioncontinuingeducation')[0]?.outerHTML;
                $scope.CEHtmlContent = $scope.CEHtmlContent?.replace(new RegExp('app/content/images/', 'g'), '');
            // }
            
            if (!$scope.paymentHtmlContent) {
                $scope.paymentHtml = angular.element('#tabSectionfeeAndPayment').parent();
                $scope.paymentHtmlContent = angular.element($scope.paymentHtml).html();
                if (!!$scope.paymentHtmlContent)
                $scope.paymentHtmlContent = $scope.paymentHtmlContent.replace(new RegExp('app/content/images/', 'g'), '');
            }
            
        }

        $scope.deficiencyStatusUpdate = function () {
            $scope.isPaymentSubmitted = false;
            $scope.isConfirmPayment = false;
            $scope.transactionsData = false;
            if ($scope.individualViewConfig.isDeficient && !$scope.individualViewConfig.isDefeciencyReviewedByIndividual) {
                showStatusMessage(Messages.General.Errors.ResolveDef, "error");
                return;
            } else {
                $scope.$broadcast("SaveDeficiency", {
                    isSaveAllTabs: true
                });
                hideStatusMessage();
            }

            var data = {
                dataObj: {
                    EmailAddress: '',
                    IndividualId: $scope.individualId,
                    ApplicationId: $scope.applicationId,
                    ProcessEmailAndApplicationPdf: true,
                    PhoneNumber: '',
                    TotalApplicationFee: '',
                    ApplicationFees: '',
                    ReInstatementFee: '',
                    AdditionalApplicationFees: 0,
                    ApplicationFeeType: '',
                    LateFee: '',
                    IsConfirm: !!$scope.isConfirmPayment,
                    IsSendAttachment: false,
                    ActionFlag: '',
                    DoNotSendPaymentReceipt: '',
                    FirstName: '',
                    LastName: '',
                    IsMiscPayment: (!!$scope.isMiscPayment),
                    IsNewApplication: (!$scope.isRenewal),
                    PaymentHtmlProcessor: "",
                    LicenseNumber: $scope.individualLicense ? $scope.individualLicense.LicenseNumber : null,
                    LicenseTypeId: !!$scope.individualLicense ? $scope.individualLicense.LicenseTypeId : null,
                    QueueName: $scope.queueName
                },
 
            };

            var htmlToPdfDataPromise = htmlToPdfGenerationService.processHtmlNewApplication($scope.additionalCss);
            if ($scope.applicationInfo && $scope.applicationInfo.ApplicationTypeId == 16) {
                htmlToPdfDataPromise = htmlToPdfGenerationService.processHtml($scope.additionalCss);
            }

            htmlToPdfDataPromise.then(function (response) {
                if ($scope.applicationInfo && $scope.applicationInfo.ApplicationTypeId == 16) {
                    data.dataObj.TabHtml = [{
                        ApplicationID: $scope.applicationId,
                        IndividualID: $scope.individualId,
                        TabName: 'IntakeQ-RenewalHtml',
                        CreatedBy: $scope.userId,
                        DataDesc: "RenewalHtml",
                        HTMLContent: response,
                        SortingOrder: 1
                    }];
                } else {
                    $scope.IsPaymentSubmitted = false;
                    $scope.grabHTMLElements();
                    
                    if (!$scope.isConfirmPayment) {
                        data.dataObj.TabHtml = [{
                            ApplicationID: $scope.applicationId,
                            IndividualID: $scope.individualId,
                            TabName: 'IntakeQ-NewApplicationOuterPartHtml',
                            CreatedBy: $scope.userId,
                            DataDesc: "New Application Outer Part Html for All Tabs including the css and all sections",
                            HTMLContent: response,
                            SortingOrder: 0
                        }, {
                            ApplicationID: $scope.applicationId,
                            IndividualID: $scope.individualId,
                            TabName: 'IntakeQ-personal',
                            CreatedBy: $scope.userId,
                            DataDesc: "New Application section personal Html",
                            SortingOrder: 100,
                            HTMLContent: $scope.personalHtml
                        }, {
                            ApplicationID: $scope.applicationId,
                            IndividualID: $scope.individualId,
                            TabName: 'IntakeQ-renewalInfo',
                            CreatedBy: $scope.userId,
                            DataDesc: "New Application section renewalInfo Html",
                            HTMLContent: $scope.renewalInfoHtmlContent,
                            SortingOrder: 200
                        }, {
                            ApplicationID: $scope.applicationId,
                            IndividualID: $scope.individualId,
                            TabName: 'IntakeQ-CE',
                            CreatedBy: $scope.userId,
                            DataDesc: "New Application section CE Html",
                            HTMLContent: $scope.CEHtmlContent,
                            SortingOrder: 300
                        }, {
                            ApplicationID: $scope.applicationId,
                            IndividualID: $scope.individualId,
                            TabName: 'IntakeQ-acknowledgmentAndDeclaration',
                            CreatedBy: $scope.userId,
                            DataDesc: "New Application section acknowledgmentAndDeclaration Html",
                            HTMLContent: $scope.acknowledgmentAndDeclarationHtmlContent,
                            SortingOrder: 400
                        }, {
                            ApplicationID: $scope.applicationId,
                            IndividualID: $scope.individualId,
                            TabName: 'IntakeQ-feeAndPayment',
                            CreatedBy: $scope.userId,
                            DataDesc: "New Application section feeAndPayment Html",
                            HTMLContent: $scope.paymentHtmlContent,
                            SortingOrder: 500
                        }];
                    }

                }

                htmlToPdfGenerationService.saveUpdatedHtml(sessionStorage.Key, data.dataObj)
                    .then(function (response) {
                        console.log("Pdf generated ",response);
                    }, function (error) {
                        console.log("error",error);        
                    })

            });

            ShowLoader();
            var queueApprovalData = {
                ProcessDeficiency: true
            };
            if (!!$scope.individualViewConfig.isStaffDeficiencyExists) {
                queueApprovalData.DeficiencyStatusId = 2;
            } else {
                queueApprovalData.DeficiencyStatusId = 3;
            }
            saveApplicationStatusChangeLog($scope.applicationId, 37);
            queueApprovalData.ApplicationStatusId = $scope.applicationInfo.ApplicationStatusId;
            if (!!$scope.applicationInfo.DeficiencyStatusId && $scope.applicationInfo.DeficiencyStatusId == 1) {
                if (!!$scope.applicationInfo.ApplicationStatusId && $scope.applicationInfo.ApplicationStatusId == 43) {
                    queueApprovalData.ApplicationStatusId = 44;
                }
                if (!!$scope.applicationInfo.ApplicationStatusId && $scope.applicationInfo.ApplicationStatusId == 45) {
                    queueApprovalData.ApplicationStatusId = 44;
                }
            }

            initialApplicationstatusService.saveApplicationStatus($scope.applicationId, sessionStorage.Key, queueApprovalData.ApplicationStatusId, queueApprovalData)
                .then(function (response) {
                    if (response.Status == false) {
                        showStatusMessage(response.Message, "error");
                        HideLoader();
                        return;
                    }
                    HideLoader();

                    $state.go('app.PaymentConfirmation', {
                        entityId: $scope.individualId,
                        entityType: 'I',
                        applicationId: $scope.applicationInfo.ApplicationId,
                        applicationTypeId: $scope.applicationInfo.ApplicationTypeId,
                        paymentMethodTypeId: '',
                        isReSubmit: true
                    });
                }, function (data) {
                    showStatusMessage(error, "error");
                    HideLoader();
                });

        };

        var saveApplicationStatusChangeLog = function (applicationId, applicationStatus) {
            var reqData = {
                ApplicationId: applicationId,
                ApplicationStatusReasonId: 1,
                ApplicationNumber: '',
                ApplicationTypeId: ((!!$scope.applicationInfo) ? $scope.applicationInfo.ApplicationTypeId : 1),
                ApplicationStatusId: applicationStatus,
                ApplicationSubmitMode: ($scope.isBackoffice ? 'B' : 'O'),
                ApplicationStatusChangedBy: $scope.userId,
                ReferenceNumber: '',
                LicenseTypeId: 0,
                HighPriority: false,
                ApplicationInternalStatusId: 1,
                ApplicationInternalStatusReasonText: ''
            };
            initialApplicationstatusService.saveApplicationStatusChangeLog(sessionStorage.Key, $scope.userId, reqData)
                .then(function (response) {
                    if (applicationStatus == 2 && !$scope.isBackoffice && !($scope.IndividualRenewal.IndividualChildSupport.isAffirmativeAction || $scope.IndividualRenewal.IndividualLegal.isAffirmativeAction)) {
                        // saveApplicationStatusChangeLog(applicationId, 3)
                    }
                }, function (data) {
                    showStatusMessage(data, "error");
                });
        };

        function auditVisitInfoSave(tab) {
            var data = {
                PageName: (($scope.isBackoffice ? "Backoffice" : "Online") + " - " + ("Renewal Application - " + tab.text)),
                SessionId: sessionStorage.Key,
                IndividualId: $scope.individualId,
                EntityId: tab.tabNumber,
                UserId: $scope.userId,
                Username: "",
                RequestUrlReferrer: document.referrer
            };
            utilityService.AuditVisitInfo(data);
        };

        $scope.paymentSuccess = function (event, args) {
            $scope.applicationInfo.ApplicationStatusId = args.statusId;
            $scope.$emit('applicationStatusChanged', {
                ApplicationId: args.applicationId,
                ApplicationStatusId: args.statusId
            });
            if (!$scope.isBackoffice) {
                $state.go('app.PaymentConfirmation', {
                    entityId: $scope.individualId,
                    entityType: 'I',
                    applicationId: $scope.applicationInfo.ApplicationId,
                    applicationTypeId: $scope.applicationInfo.ApplicationTypeId,
                    paymentMethodTypeId: $rootScope.SelectedPaymentMethod,
                    isReSubmit: false,
                    applicationStatusId: ''
                });
            }
        };

        function initialProcessing() {
            angular.forEach($scope.renewalCheckData.IndividualLicenseList, function (licenseType) {
                if (licenseType.LicenseStatusTypeId == 1 || licenseType.LicenseStatusTypeId == 7 || licenseType.LicenseStatusTypeId == 8) {
                    licenseType.applicationType = 'active';
                    licenseType.currentApplicationType = 'active';
                }
            });
            // processApplicationFeeAndRenewalData();
        };

        var mapRefLicenseTypeAndFeeDetails = function () {
            $scope.renewalCheckData.IndividualLicenseList.map(function (individualLicenseType) {
                if (!!$scope.refLicenseTypes) {
                    individualLicenseType.refLicenseType = $scope.refLicenseTypes.whereEquals(individualLicenseType.LicenseTypeId, 'LicenseTypeId').firstOrDefault();
                }
                var feeDetailRowsByLicenseType = getFeeDetailsByAppType(individualLicenseType);
                individualLicenseType.applicationFee = feeDetailRowsByLicenseType.whereEquals('RENEWAL', 'FeeTypeCode').sum('FeeAmount');
                individualLicenseType.lateFee = feeDetailRowsByLicenseType.whereEquals('LATE', 'FeeTypeCode').sum('FeeAmount');
            });
        };

        var getFeeDetailsByAppType = function (licenseType) {
            var feesForLicenseType = [];
            var feechangeImplemantationDate = new Date('10/1/2022');
            if (!!$scope.feeDetailRows) {
                feesForLicenseType = $scope.feeDetailRows
                    .whereEquals($scope.applicationInfo.ApplicationTypeId, 'ApplicationTypeId')
                    .where(function (feeDetailItem) {
                        return (feeDetailItem.LicenseTypeIds.split(',').indexOf(licenseType.LicenseTypeId.toString()) != -1);
                    })
                    //.whereContains(licenseType.PreviousLicenseStatusTypeId.toString(), 'LicenseStatusTypeIds')
                    .whereEquals('RENEWAL', 'FeeTypeCode');

                if (licenseType.ApplyLateFee) {
                    var lateFeesForLicenseType = $scope.feeDetailRows
                        .whereEquals($scope.applicationInfo.ApplicationTypeId, 'ApplicationTypeId')
                        .where(function (feeDetailItem) {
                            return (feeDetailItem.LicenseTypeIds.split(',').indexOf(licenseType.LicenseTypeId.toString()) != -1);
                        })
                        //.whereContains(licenseType.PreviousLicenseStatusTypeId.toString(), 'LicenseStatusTypeIds')
                        .whereEquals('LATE', 'FeeTypeCode');
                    feesForLicenseType = feesForLicenseType.concat(lateFeesForLicenseType);
                }
            }

            feesForLicenseType = feesForLicenseType.map(function (i) {
                i.LicenseNumber = licenseType.LicenseNumber;
                return i;
            });

            
            if (!!feesForLicenseType && feesForLicenseType.length > 0 && (!$scope.applicationInfo || !$scope.applicationInfo.SubmittedDate || utilityService.checkIfDateGreaterOrEqual($scope.applicationInfo.SubmittedDate, feechangeImplemantationDate))) {
                feesForLicenseType = feesForLicenseType.filter(function (feeItemForLicenseType) {
                    return ((!feeItemForLicenseType.EndDate || utilityService.checkIfDateGreaterOrEqual(feeItemForLicenseType.EndDate, licenseType.PreviousExpirationDate))
                        && (!feeItemForLicenseType.EffectiveDate || utilityService.checkIfDateGreaterOrEqual(licenseType.PreviousExpirationDate, feeItemForLicenseType.EffectiveDate)));
                });
            }

            if (!!feesForLicenseType && feesForLicenseType.length > 0 && !!$scope.applicationInfo && !!$scope.applicationInfo.SubmittedDate && utilityService.checkIfDateGreaterOrEqual(feechangeImplemantationDate, $scope.applicationInfo.SubmittedDate)) {
                feesForLicenseType = feesForLicenseType.filter(function (feeItemForLicenseType) {
                    return ((!feeItemForLicenseType.EndDate || utilityService.checkIfDateGreaterOrEqual(feeItemForLicenseType.EndDate, $scope.applicationInfo.SubmittedDate))
                        && (!feeItemForLicenseType.EffectiveDate || utilityService.checkIfDateGreaterOrEqual($scope.applicationInfo.SubmittedDate, feeItemForLicenseType.EffectiveDate)));
                });
            }

            if ($scope.refSecCodeFeeDetailMappings && $scope.refSecCodeFeeDetailMappings.length > 0) {
                var filteredSecCodeFeeDetailMappings = $scope.refSecCodeFeeDetailMappings.filter(function (refSecCodeFeeDetailMapping) {
                    var secondaryCodeExists = false;
                    if (licenseType.individualLicenseSecondaryCodes) {
                        secondaryCodeExists = licenseType.individualLicenseSecondaryCodes.any(function (individualLicenseSecondaryCode) { return individualLicenseSecondaryCode.secStatCde == refSecCodeFeeDetailMapping.SecCode });
                    }
                    return secondaryCodeExists;
                })
            }
            feesForLicenseType = feesForLicenseType.filter(function (feesForLicenseTypeRow) {
                var isFeeApplicable = true;
                if (filteredSecCodeFeeDetailMappings && filteredSecCodeFeeDetailMappings.length > 0) {
                    filteredSecCodeFeeDetailMappings.map(function (filteredSecCodeFeeDetailMapping) {
                        var feeDetailCodeExistInMapping = (filteredSecCodeFeeDetailMapping.FeeDetailCodes && filteredSecCodeFeeDetailMapping.FeeDetailCodes.split(",").indexOf(feesForLicenseTypeRow.FeeDetailCode) != -1)
                        if (isFeeApplicable) {
                            isFeeApplicable = !feeDetailCodeExistInMapping;
                        }
                    })
                }
                if (!$scope.feeDetails.isPaymentDeferred) {
                    $scope.feeDetails.isPaymentDeferred = (!isFeeApplicable);
                }
                return isFeeApplicable;
            });

            // if (!!feesForLicenseType && feesForLicenseType.length > 0) {
            return feesForLicenseType;
            // } else {
            //     $scope.showStatusMsg('-', "No fees associated with this license type: " + licenseType.LicenseTypeName);
            //     throw ('No fees associated with this license type: ' + licenseType.LicenseTypeName);
            // }
        };

        var processApplicationFeeAndRenewalData = function () {
            $scope.feeDetails.applicationFee = 0;
            var renewalGroups = [];
            $scope.renewalCheckData.selectedGroups = {};
            var historyQuestionContentId = '';
            var feeDetailRows = [];
            var licenseType = $scope.renewalCheckData.IndividualLicenseList
                // .whereIncludes(ApplicationSettings.ActiveStatuses, 'ApplicationStatusId')
                // .whereIncludes(ApplicationSettings.AvailableTypes, 'ApplicationTypeId')
                // .whereEquals(currentApplicationId, 'ApplicationId')
                .orderByDescending('LinkIndividualLicenseId')
                .firstOrDefault();
            if (!!licenseType.IsSelectedToRenew) {
                $scope.callCreatePendingRenewal = false;
            }
            else {
                $scope.callCreatePendingRenewal = true;
                licenseType.IsSelectedToRenew = true;
            }
            angular.forEach($scope.renewalCheckData.IndividualLicenseList, function (licenseType) {
                if (licenseType.IsSelectedToRenew) {
                    if (renewalGroups.indexOf(licenseType.RenewalApplicationGrouping) == -1)
                        renewalGroups.push(licenseType.RenewalApplicationGrouping);
                    $scope.renewalCheckData.selectedGroups["group" + licenseType.RenewalApplicationGrouping] = true;
                    if (!!licenseType.RenewalApplicationHistoryQuestionContentId) {
                        if (!historyQuestionContentId)
                            historyQuestionContentId = licenseType.RenewalApplicationHistoryQuestionContentId;
                        else
                            historyQuestionContentId = historyQuestionContentId.concat(',', licenseType.RenewalApplicationHistoryQuestionContentId);
                    }
                    if ($scope.individualLicenseSecondaryCodes && $scope.individualLicenseSecondaryCodes.length > 0) {
                        var individualLicenseSecondaryCodeData = $scope.individualLicenseSecondaryCodes.whereEquals(licenseType.IndividualLicenseId, 'IndividualLicenseId').firstOrDefault();
                        if (individualLicenseSecondaryCodeData && individualLicenseSecondaryCodeData.SecStatTypeCodes && individualLicenseSecondaryCodeData.SecStatTypeCodes.length > 0) {
                            licenseType.individualLicenseSecondaryCodes = individualLicenseSecondaryCodeData.SecStatTypeCodes;
                            if (licenseType.individualLicenseSecondaryCodes && licenseType.individualLicenseSecondaryCodes.length > 0) {
                                licenseType.SecondaryStatusIds = individualLicenseSecondaryCodeData.SecStatTypeCodes.select('secStatCde').join(',');
                            }
                        }
                        // else {
                        //     licenseType.individualLicenseSecondaryCodes = [{
                        //         secStatCde: "39"
                        //     }];
                        // }
                    } else {
                        licenseType.individualLicenseSecondaryCodes = [];
                    }
                    //feeDetailRows = feeDetailRows.concat(angular.copy(getFeeDetailsByAppType(licenseType)));
                    feeDetailRows = angular.copy(getFeeDetailsByAppType(licenseType));
                }
            });
            mapRefLicenseTypeAndFeeDetails();
            $scope.renewalCheckData.RenewalApplicationHistoryQuestionContentId = historyQuestionContentId.split(',').distinct().join(',');
            $scope.renewalCheckData.renewalGroups = renewalGroups;
            $scope.feeDetails.fees = feeDetailRows;
            $scope.feeDetails.applicationFee = $scope.feeDetails.fees.whereEquals('RENEWAL', 'FeeTypeCode').sum('FeeAmount');
            $scope.feeDetails.lateFee = $scope.feeDetails.fees.whereEquals('LATE', 'FeeTypeCode').sum('FeeAmount');
            $scope.feeDetails.totalFee = $scope.feeDetails.applicationFee + $scope.feeDetails.lateFee;
            $scope.feeDetails.feeNotRequired = feeNotRequired(feeDetailRows);
        };

        function feeNotRequired(feesForLicenseType) {
            var totalFee = feesForLicenseType.sum('FeeAmount');
            if (totalFee > 0) {
                return false;
            }
            return true;
        };

        $scope.createPendingRenewal = function () {
            ShowLoader();
            //$scope.hideStatusMsg();
            // if (!validateForm(currentForm)) {
            //     initialApplicationstatusService.setRenewalType("invalid");
            //     $scope.showStatusMsg('-', $scope.natValidation.errorMessages);
            //     HideLoader();
            //     return;
            // }
            $scope.renewalCheckData.ProcessSentData = true;
            angular.forEach($scope.renewalCheckData.IndividualLicenseList, function (individualLicense) {
                if (!!individualLicense.IsSelectedToRenew && !individualLicense.LicenseExpirationDate) {
                    var expirationOptions = {
                        baseDate: individualLicense.PreviousExpirationDate,
                        dateOfBirth: (!!$scope.indInfo) ? $scope.indInfo.DateOfBirth : (new Date()),
                        dayOfMonth: 'end'
                    };
                    individualLicense.LicenseExpirationDate = individualLicense.PreviousExpirationDate;
                }
            })
            licenseRenewalService.checkAndCreatePendingRenewal(sessionStorage.Key, $scope.renewalCheckData)
                .then(function (response) {
                    if (!response.Status) {
                        initialApplicationstatusService.setRenewalType('error');
                        $scope.showStatusMsg('-', response.Message);
                    } else {
                        initialApplicationstatusService.setRenewalType('success');
                        $scope.renewalCheckData = response.RenewalCheckData;
                        $scope.individualLicense = $scope.renewalCheckData.IndividualLicenseList[0];
                        $scope.getActiveLicense();
                        initialProcessing();
                        mapRefLicenseTypeAndFeeDetails();
                        $scope.setRenewalTypeEdit(false);
                        processApplicationFeeAndRenewalData();
                        $scope.proceedToRenewalApp(false);
                        var requestData = {
                            EntityId: $scope.renewalCheckData.IndividualId,
                            EntityType: "I",
                            IndividualLicenseIds: $scope.renewalCheckData.IndividualLicenseList.select('IndividualLicenseId')
                        };
                        dcaIService.getCasSecondaryCodeForRenewal(sessionStorage.Key, requestData)
                        .then(function (res) {
                            if (!res.Status) {
                                $scope.showStatusMsg('-', 'some error occurred !');
                            } else {
                                $scope.individualLicenseSecondaryCodes = res.EntityRecords;
                                console.log('indvresdca', res);
                                processApplicationFeeAndRenewalData();
                            }
                            
                        })
                    }
                    HideLoader();
                }, function (data) {
                    initialApplicationstatusService.setRenewalType('error');
                    HideLoader();
                    $scope.showStatusMsg('-', data);
                });
        };

        $scope.setRenewalTypeEdit = function (isRenewalTypeEdit) {
            $scope.isRenewalTypeEdit = isRenewalTypeEdit;
        };

        function monthDiff(d1, d2) {
            var months;
            months = (d2.getFullYear() - d1.getFullYear()) * 12;
            months -= d1.getMonth();
            months += d2.getMonth();
            return months <= 0 ? 0 : months;
        }

        var callProcessTabsTobeShown = function () {
            $rootScope.submissionLicenseStatusTypeId = 3;
            var hideCETab = true;
            $scope.$emit('processTabsToBeShown', { hideCETab: hideCETab });
        }

        $scope.getActiveLicense = function () {
            ShowLoader();
            backofficeIndividualLicenseService.individualLicenseDetailBYIndividualId(sessionStorage.Key, $scope.renewalCheckData.IndividualId)
                .then(function (responseItem) {
                    var LinkIndividualLicenseId = 1;
                    $scope.activeIndividualLicense = responseItem.IndividualLicenseList.where('!!item.IsLicenseActive').orderByDescending('IndividualLicenseId').firstOrDefault();

                    if($scope.applicationInfo.ApplicationStatusId == 3){
                        LinkIndividualLicenseId = $scope.activeIndividualLicense?.LinkIndividualLicenseId ?? LinkIndividualLicenseId;
                        $scope.activeIndividualLicense = responseItem.IndividualLicenseList.whereEquals(LinkIndividualLicenseId,'IndividualLicenseId').firstOrDefault();
                    }

                    var noOfMonths = 24;
                    var todaysDate = new Date();
                    var submittedDate = todaysDate;
                    var expirationDate = new Date($scope.activeIndividualLicense.LicenseExpirationDate);
                    var effectiveCEDate = new Date($scope.activeIndividualLicense.OriginalLicenseDate);

                    var year = Number($scope.activeIndividualLicense.LicenseExpirationDate.substring(0, 4));
                    var month = Number($scope.activeIndividualLicense.LicenseExpirationDate.substring(5, 7));
                    var day = Number($scope.activeIndividualLicense.LicenseExpirationDate.substring(8, 10));

                    var firstYearCEEffectiveDate = new Date(year - 2, month - 1, day + 1);
                    var firstYearCEExpirationDate = new Date(year - 1, month - 1, day);
                    var secondYearCEEffectiveDate = new Date(year - 1, month - 1, day + 1);
                    var secondYearCEExpirationDate = new Date($scope.activeIndividualLicense.LicenseExpirationDate);

                    if (!!$scope.applicationInfo.SubmittedDate){
                        submittedDate = new Date($scope.applicationInfo.SubmittedDate);
                        $scope.individualLicense.isSubmitted = true;
                        $scope.individualLicense.SubmittedDate = $scope.applicationInfo.SubmittedDate;
                    }

                    if (submittedDate > expirationDate) {
                        $scope.individualLicense.isLicenseExpired = true;
                        year = submittedDate.getFullYear();
                        month = submittedDate.getMonth();
                        day = submittedDate.getDate();

                        firstYearCEEffectiveDate = new Date(year - 2, month, day + 1);
                        firstYearCEExpirationDate = new Date(year - 1, month, day);
                        secondYearCEEffectiveDate = new Date(year - 1, month, day + 1);
                        secondYearCEExpirationDate = new Date();
                    }
                    if (!!$scope.activeIndividualLicense) {
                        if (!!$scope.activeIndividualLicense.CEDate && $scope.activeIndividualLicense.CEDate.length >= 10) {
                            var year = Number($scope.activeIndividualLicense.CEDate.substring(0, 4));
                            var month = Number($scope.activeIndividualLicense.CEDate.substring(5, 7));
                            var day = Number($scope.activeIndividualLicense.CEDate.substring(8, 10));
                            var conversionDate = new Date(year, month - 1, day);
                            noOfMonths = monthDiff(conversionDate, expirationDate);
                            $scope.individualLicense.reEntryLicensee = true;
                            if (noOfMonths < 24) {
                                $scope.individualLicense.effectiveCEDate = new Date($scope.activeIndividualLicense.CEDate);
                                $scope.individualLicense.isProrated = true;
                                if (noOfMonths < 6) {
                                    callProcessTabsTobeShown();
                                }
                            }
                            else {
                                $scope.individualLicense.effectiveCEDate = firstYearCEEffectiveDate;
                            }
                        }
                        else {
                            var effectiveDate = new Date($scope.activeIndividualLicense.OriginalLicenseDate);
                            // var originalLicenseDate = new Date($scope.activeIndividualLicense.originalLicenseDate);
                            noOfMonths = monthDiff(effectiveDate, expirationDate);
                            if (!$scope.activeIndividualLicense.LicenseEffectiveDate || $scope.activeIndividualLicense.LicenseEffectiveDate == "") {
                                $scope.individualLicense.newLicensee = true;
                                if (noOfMonths < 24) {
                                    $scope.individualLicense.effectiveCEDate = effectiveCEDate;
                                    $scope.individualLicense.isProrated = true;
                                    if (noOfMonths < 6) {
                                        callProcessTabsTobeShown();
                                    }
                                }
                                else {
                                    $scope.individualLicense.effectiveCEDate = firstYearCEEffectiveDate;
                                }
                            }
                            else {
                                $scope.individualLicense.firstYearCEEffectiveDate = firstYearCEEffectiveDate;
                                $scope.individualLicense.firstYearCEExpirationDate = firstYearCEExpirationDate;
                                $scope.individualLicense.secondYearCEEffectiveDate = secondYearCEEffectiveDate;
                                $scope.individualLicense.secondYearCEExpirationDate = secondYearCEExpirationDate;
                            }
                        }
                    }
                    $scope.individualLicense.PreviousCEDate = $scope.activeIndividualLicense.PreviousCEDate;
                    HideLoader();
                }, function (error) {
                    HideLoader();
                    $scope.showStatusMsg('-', 'Some error occurred!');
                });
        }

        $scope.getInitData = function () {
            var requestData = {
                EntityId: $scope.renewalCheckData.IndividualId,
                EntityType: "I",
                IndividualLicenseIds: $scope.renewalCheckData.IndividualLicenseList.select('IndividualLicenseId')
            };
            $q.all([backofficeIndividualPersonalService.individualBYIndividualId(sessionStorage.Key, $scope.renewalCheckData.IndividualId),
            typeValuesService.getTypeValuesByTypeNameAndParams(TypeValue.RefTablesGetAll, sessionStorage.Key, true, ("?tableKey=feedetails&pullInActive=true")),
            typeValuesService.getTypeValuesByTypeNameAndParams(TypeValue.RefTablesGetAll, sessionStorage.Key, true, ("?tableKey=licensetype")),
            typeValuesService.getTypeValuesByTypeNameAndParams(TypeValue.RefTablesGetAll, sessionStorage.Key, true, ("?tableKey=seccodefeedetailmapping")),
            dcaIService.getCasSecondaryCodeForRenewal(sessionStorage.Key, requestData)
            ]).then(function (response) {

                if (!response[0].Status || !response[0].IndividualResponse || response[0].IndividualResponse.length == 0) {
                    $scope.showStatusMsg('-', 'some error occurred !');
                } else {
                    $scope.indInfo = response[0].IndividualResponse[0];
                }

                if (!response[1].Status) {
                    $scope.showStatusMsg('-', 'some error occurred !');
                } else {
                    $scope.feeDetailRows = response[1].ReferenceEntities;
                }
                if (!response[2].Status) {
                    $scope.showStatusMsg('-', 'some error occurred !');
                } else {
                    $scope.refLicenseTypes = response[2].ReferenceEntities;
                }
                if (!response[3].Status) {
                    $scope.showStatusMsg('-', 'some error occurred !');
                } else {
                    var refSecCodeFeeDetailMappings = response[3].ReferenceEntities;
                    if (refSecCodeFeeDetailMappings && refSecCodeFeeDetailMappings.length > 0) {
                        $scope.refSecCodeFeeDetailMappings = refSecCodeFeeDetailMappings.filter(function (refSecCodeFeeDetailMapping) {
                            return ((!refSecCodeFeeDetailMapping.EndDate || utilityService.checkIfDateGreaterOrEqual(refSecCodeFeeDetailMapping.EndDate, new Date()))
                                && (!refSecCodeFeeDetailMapping.EffectiveDate || utilityService.checkIfDateGreaterOrEqual(new Date(), refSecCodeFeeDetailMapping.EffectiveDate)));
                        });
                    }
                }
                if (!response[4].Status) {
                    $scope.showStatusMsg('-', 'some error occurred !');
                } else {
                    $scope.individualLicenseSecondaryCodes = response[4].EntityRecords;
                }
                $scope.individualRenewalGet();
            }, function (err) {
                HideLoader();
                $scope.showStatusMsg('-', 'some error occurred !');
            });
        };

        $scope.$on('paymentSuccess', $scope.paymentSuccess);

        $scope.init();
    }
})();