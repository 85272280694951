(function () {
    //'use strict';

    angular
        .module('app.backofficeIndividual')
        .controller("FirmsSearchDataFillController", FirmsSearchDataFillController);

    FirmsSearchDataFillController.$inject = ["$rootScope", "$scope", "$state", "$controller", "$q",
        "backofficeFirmService", "providerAddressService", "BackofficeIndividualService", "countryStateService", "WebApiUrl", "BackofficeProviderService", "typeValuesService" , "providerUserLinkingService", "EInformationDirectiveService", "BackofficeFirmRenewalListService", "providerOwnershipService", "EntityConfirmationService"];

    function FirmsSearchDataFillController($rootScope, $scope, $state, $controller, $q,
        backofficeFirmService, providerAddressService, BackofficeIndividualService, countryStateService, WebApiUrl, BackofficeProviderService, typeValuesService, providerUserLinkingService, EInformationDirectiveService, BackofficeFirmRenewalListService, providerOwnershipService, EntityConfirmationService) {
        $controller('GlobalController', { $scope: $scope });
        var currentState = $state.current.name;
        var goState = 'app.FirmsSearch';
        if (currentState == 'app.AMC') {
            goState = 'app.AMCProviderSearch';
        }
        else if (currentState == 'app.CEPP') {
            goState = 'app.CEPPProviderSearch';
        }
        if (!$scope.isSessionActive()) {
            $state.go('app.BackofficeLoginstaff');
        }
        $scope.maskConfig = {
            clearOnBlur: false,
            allowInvalidValue: true,
            clearOnBlurPlaceholder: false
        };

        var init = function () {
            $scope.Pager = {
                currentPage: 1,
                maxSize: 3,
                pageSize: 10,
                fromRow: 0
            };
            sessionStorage.setItem("isBackOfficeUser", true);
            $scope.identificationType = "EIN";
            $scope.identificationChanged();
            LoadFirmSearchContext();
            $scope.getInitData($rootScope.DefaultCountryId);
        };

        $scope.validator = {
            designatedContactPerson: {},
            owners: {},
            otherStateLicense: {}
        };

        
        $scope.natValidation = {
            beOwnership: {},
            beResponsible: {}
        };

        $scope.routeData = $state.current.routeData;

        $scope.ProviderOther = {};

        $scope.dateOptions = { format: 'MM/DD/YYYY' };

        $scope.formats = ['MM/dd/yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
        $scope.format = $scope.formats[0];

        $scope.open1 = function () {
            $scope.popup1.opened = true;
        };
        $scope.popup1 = {
            opened: false
        };
        $scope.altInputFormats = ['M!/d!/yyyy'];
        
        $scope.maskConfig = {
            clearOnBlur: false,
            allowInvalidValue: false,
            clearOnBlurPlaceholder: true
        };

        $scope.datePicker = {
            crpRejectionDate: false,
            crpReceivedDate: false,
            format: 'M!/d!/yyyy',
            dateOptions: {
                formatYear: 'yy',
                startingDay: 1
            }
        };
        $scope.listOfEmpWithAdd = [];

        $scope.individualFirmViewConfig = {
            tabs: [
                { name: 'firminformation', text: 'Firm Information', use: 'CEPPFirmAMC' },
                
                { name: 'address', text: 'Address', use: 'CEPPFirmAMC' },
                { name: 'finance', text: 'Finance', use: 'CEPPFirmAMC' },
                { name: 'license', text: 'License', use: 'FirmAMC' },
                // { name: 'creamtory', text: 'Crematory Information', use: 'CEPPFirmAMC' },
                { name: 'appdata', text: 'Application Data', use: 'CEPPFirmAMC' },
                { name: 'document', text: 'Uploaded Documents', use: 'CEPPFirmAMC' },
                { name: 'notes', text: 'Comments', use: 'CEPPFirmAMC' },
                { name: 'correspondence', text: 'Correspondence', use: 'CEPPFirmAMC' },
                { name: 'log', text: 'History View', use: 'CEPPFirmAMC' },
                { name: 'admin', text: 'Admin Hold', use: 'CEPPFirmAMC' },
                { name: 'bcheck', text: 'Background Check', use: 'CEPPFirmAMC' },
                { name: 'enforcement', text: 'Enforcement', use: 'CEPPFirmAMC' },
                //{ name: 'DCH', text: 'Associate Information', use: 'Firm' },
                //{ name: 'DCH', text: 'Appraiser Panel', use: 'AMC' },
                //{ name: 'otherStateLicense', text: 'Other State License', use: 'AMC' },
                // { name: 'designatedContact', text: 'Designated Contact', use: 'AMC' },
                // { name: 'owners', text: 'Owners', use: 'AMC' },
                // { name: 'compliance', text: 'Compliance', use: 'FirmAMC' },
                // { name: 'courses', text: 'Courses', use: 'CEPP' },
                
                // { name: 'EO', text: 'E&O Policy', use: 'Firm' },
                
                
                
                
                //{ name: 'surety', text: 'Surety', use: 'AMC' }
                // { name: 'trustAccountFundHolder', text: 'Trust Account/Fund Holder', use: 'Firm' }
                // { name: 'dea', text: 'DEA' },
                // { name: 'comment', text: 'Comment' },
                //{ name: 'other', text: 'Other' },
                // { name: 'peerreview', text: 'Peer Review' },
                // { name: 'disipline', text: 'Discipline' },
                // { name: 'compliance', text: 'Compliance' },

            ]
        };

        $scope.setCurrentTab = function (tab) {
            $rootScope.individualFirmCurrentTab = tab;
            setTimeout(function () {
                $scope.$broadcast('individualTabChanged', $scope.ProviderId, 'P');
            }, 1000);
        };

        var resetTabs = function () {
            angular.forEach($scope.individualFirmViewConfig.tabs, function (tab, key) {
                tab.isLoadedOnce = false;
                tab.isAddressLoaded = false;
                tab.isLicenseLoaded = false;
            })
            $scope.setCurrentTab($scope.individualFirmViewConfig.tabs[0]);
        };
        var searchFirmMaster = {
            Name: "",
            Id: "",
            BusinessType: ""
        };
        $scope.cancelfirm = function () {
            $scope.search = angular.copy(searchFirmMaster);
        }
        $scope.ProviderOtherByProviderId = function (ProviderId) {
            try {
                BackofficeProviderService.ProviderOtherProviderId(sessionStorage.Key, ProviderId)
                    .then(function(response){ response = response.data;
                        hideStatusMessage();
                        hideValidationSummary();
                        if ($scope.checkResponse(response) && response.ProviderOtherList && response.ProviderOtherList.length > 0) {
                            $scope.ProviderOther = response.ProviderOtherList[0];
                        } else {
                        }
                    }, function(data) { data = data.data;
                        showStatusMessage(data, "error");
                    });
            } catch (ex) {
                if (ex != null) { showStatusMessage(ex.message, "error"); }
            }
        };


        //$scope.ProviderEINByProviderId = function (ProviderId) {

        //    try {
        //        BackofficeProviderService.ProviderEINProviderId(sessionStorage.Key, ProviderId)
        //            .then(function(response){ response = response.data;
        //                hideStatusMessage();
        //                hideValidationSummary();
        //                if ($scope.checkResponse(response) && response.ProviderLicenseResponseList && response.ProviderLicenseResponseList.length>0) {
        //                    $scope.ProviderEIN = response.ProviderLicenseResponseList[0].EIN;
        //                } else {
        //                }
        //            })
        //            .error(function (data) {
        //                showStatusMessage(data, "error");
        //            });
        //    } catch (ex) {
        //        if (ex != null) { showStatusMessage(ex.message, "error"); }
        //    }
        //}

        var GetProviderStatusType = function () {
            if (!$scope.BusinessStatus) {
                // BackofficeProviderService.GetProviderStatusType(sessionStorage.Key).then(function (res) {
                typeValuesService.getTypeValuesByTypeNameAndParams("RefTablesGetAll", sessionStorage.Key, true, ("?tableKey=refproviderlicensestatus")).then(function (res) {
                    if (res.Status) {
                        $scope.BusinessStatus = res.ReferenceEntities;
                        // console.log('status list', $scope.BusinessStatus);
                    }
                });
            }
        };
        GetProviderStatusType();

        //$scope.SaveProviderOther = function () {
        //    try {
        //        ShowLoader("Please wait...");
        //        //update EIN
        //        UpdateProviderEin();
        //        $scope.ProviderOther.IsActive = true;
        //        $scope.ProviderOther.ProviderId = $scope.ProviderId;
        //        BackofficeProviderService.ProviderBusinessNameSave(sessionStorage.Key, "[" + JSON.stringify($scope.ProviderOther) + "]")
        //            .then(function(response){ response = response.data;
        //                if ($scope.checkResponse(response)) {
        //                    $scope.ProviderOtherByProviderId($scope.ProviderId);
        //                    $scope.ProviderEINByProviderId($scope.ProviderId);
        //                    HideLoader();
        //                } else {
        //                    HideLoader();
        //                }
        //            })
        //            .error(function (data) {
        //                HideLoader();
        //                showStatusMessage(data, "error");
        //            });
        //    } catch (ex) {
        //        HideLoader();
        //        if (ex != null) { showStatusMessage(ex.message, "error"); }
        //    }
        //}

        $scope.IndividualFinanceByProviderId = function (ProviderId) {
            $scope.FinanceArray = [];
            try {
                backofficeFirmService.getInvoiceMasterandDetail(sessionStorage.Key, 0, ProviderId)
                    .then(function(response){ response = response.data;
                        hideStatusMessage();
                        hideValidationSummary();
                        if ($scope.checkResponse(response)) {

                            $scope.FinanceArray = response.InvoiceMasterandDetailList;
                        } else {
                        }
                    }, function(data) { data = data.data;
                        showStatusMessage(data, "error");
                    });
            } catch (ex) {
                if (ex != null) { showStatusMessage(ex.message, "error"); }
            }
        }

        //firm search related code
        var firmSearchContent = null;
        var LoadFirmSearchContext = function () {
            try {
                firmSearchContent = BackofficeIndividualService.getFirmSearchDataContent();
                if (firmSearchContent == null) {
                    $state.go(goState);
                }
                else {
                    $scope.getEmployerList();
                }
            }
            catch (ex) {
                $state.go(goState);
            }
        };

        $scope.firmSearchWithPage = function (currentPage) {
            $scope.getEmployerList();
        };

        $scope.updateFirmNameinUsersTbl = function () {
            providerUserLinkingService.getUserByProviderId(sessionStorage.Key, $scope.ProviderId).then(function (response) {
                
                if(response.Status == true) {
                    var currentUser = response.Users;
                    currentUser.EntityName = $scope.newFirmName;
                    providerUserLinkingService.externalUserUsersSave(sessionStorage.Key, currentUser).then(function (responseUser) {
                        HideLoader();
                        console.log("responseUser", response);
                    });
                }


                
            })
        }

        //get firm search list with pagination
        $scope.getEmployerList = function () {
            if (firmSearchContent == null) {
                $state.go(goState);
            }
            ShowLoader("Please wait...");
            // backofficeIndividualAssociatedwithFirmService.getFirmSearchList(
            //     sessionStorage.Key, firmSearchContent.FirmSearchContent, 5, $scope.Pager.currentPage, $scope.Pager.pageSize, firmSearchContent.FirmSearchContentType
            // )
            BackofficeFirmRenewalListService.providerSearchWithPage(sessionStorage.Key, $scope.Pager.currentPage, 10, firmSearchContent).then(function (response) {

                $scope.listOfEmpWithAdd = response.data.ProviderList;
                $scope.Pager.totalRecords = response.data.Total_Record;
                if ($scope.listOfEmpWithAdd != null) {
                    $scope.ProviderId = $scope.listOfEmpWithAdd[0].ProviderId;
                    $scope.displayFirmSearchResult($scope.listOfEmpWithAdd[0]);
                }
                HideLoader();
            }, function (res) {
            });
        };
        //Firm Search related code ends here
        $scope.updateFirmDetails = {};
        $scope.providerSave = function (personalForm) {
            
                $scope.hideStatusMessage();
                $scope.hideValidationSummary();
                if ($scope.updateFirmDetails.ProviderName != personalForm.FirmName.$viewValue) {
                    $scope.newFirmName = personalForm.FirmName.$viewValue;
                    $scope.updateFirmNameinUsersTbl();
                }
                $scope.updateFirmDetails.ProviderId = $scope.ProviderId;
                $scope.updateFirmDetails.ProviderName = personalForm.FirmName.$viewValue;
                $scope.updateFirmDetails.ProviderDBAName = personalForm.FirmName.$viewValue;
                $scope.updateFirmDetails.TaxId = personalForm.Zip.$viewValue;
                $scope.updateFirmDetails.ClosedDate = personalForm.IssuanceDate.$viewValue;
                $scope.updateFirmDetails.IsActive = $scope.listOfEmpWithAdd[0].ProviderIsActiveStatus;
                $scope.updateFirmDetails.IsDeleted = $scope.listOfEmpWithAdd[0].ProviderIsDeleted
                $scope.updateFirmDetails.IsEnabled =  $scope.listOfEmpWithAdd[0].ProviderIsEnabledStatus
                $scope.updateFirmDetails.ProviderStatusTypeId = $scope.listOfEmpWithAdd[0].ProviderStatusTypeId; 
                $scope.updateFirmDetails.ProviderTypeId = $scope.listOfEmpWithAdd[0].ProviderTypeId
                if (!! $scope.FirmAddress) {
                    $scope.FirmAddress.Zip = personalForm.Zip.$viewValue;
                }

                EInformationDirectiveService.ProviderSave(sessionStorage.Key, $scope.applicationId, sessionStorage.UserID, $scope.updateFirmDetails).then(function (response) {
                    $scope.isPersonalEdit = false;
                }, function (data) {
                    if (data != null) {
                        $scope.showStatusMessage({
                            message: data.message,
                            type: "error"
                        });
                    }
                });
                if (!! $scope.FirmAddress) {
                    providerAddressService.SaveAddressDetails($scope.ProviderId, sessionStorage.Key, $scope.FirmAddress).then(function (response) {
                        // console.log('updated Address', response);
                    }, function (response) {
                        if (response != null) {
                            $scope.showStatusMessage({
                                message: response.message,
                                type: "error"
                            });
                        }
                    });
                }
        };

        $scope.editable = function() {
            $scope.isPersonalEdit = true;
        };

        //Firm Status Tab
        $scope.firmDetails = {};
        var loadFirmStatusSection = function () {
            $scope.isPersonalEdit = false;
            if (!$scope.firmDetails) {
                $scope.firmDetails = {};
            }
            $scope.firmDetails.fiemName = $scope.ProviderData ? angular.copy($scope.ProviderData.ProviderName) : '';
            $scope.firmDetails.firmParentName = $scope.ProviderData ? angular.copy($scope.ProviderData.ProviderParentName) : '';
            $scope.firmDetails.ProviderId = $scope.ProviderData.ProviderId;
            $scope.firmDetails.ProviderLicenseNumber = $scope.ProviderData.LicenseNumber;
            $scope.firmDetails.ProviderStatusTypeName = $scope.ProviderData.LicenseStatusTypeName;
            $scope.firmDetails.ProviderLicenseTypeName = $scope.ProviderData.LicenseTypeName;
            $scope.firmDetails.ProviderLicenseTypeId = $scope.ProviderData.ProviderLicenseTypeId;
            $scope.firmDetails.ProviderLicenseEffectiveDate = $scope.ProviderData.OriginalLicenseDate;
            $scope.firmDetails.ProviderLicenseExpirationDate = $scope.ProviderData.LicenseExpirationDate;
            $scope.firmDetails.ProviderDBAName = $scope.ProviderData.ProviderDBAName;
            $scope.firmDetails.TaxId = $scope.ProviderData.TaxId;
            $scope.firmDetails.Zip = $scope.ProviderData.ReferenceNumber;
            $scope.firmDetails.IDNumber = $scope.listOfEmpWithAdd[0].IDNumber;
            $scope.firmDetails.IssuanceDate = $scope.listOfEmpWithAdd[0].ClosedDate;
        };

        $scope.clearPerson = function (personalForm) {
            //$scope.firmDetails = angular.copy($scope.OrignalpersonDetail);
            loadFirmStatusSection();
            personalForm.$setPristine();
            $scope.isPersonalEdit = false;
        }

        //event load
        $scope.$on("loadFirmAddressData", function (evt, data) {
            // console.log('address data', data);
            
            $scope.firmDetails.mailingAddress = {};
            $scope.firmDetails.physicalAddress = {};
            $scope.firmDetails.zipAddress = {};
            if (data) {
                $scope.FirmAddress = data[0];
                var mailingAddress = $.grep(data, function (i) {
                    // return ((i.IsMailingSameasPhysical || i.AddressTypeId == 10) && i.AddressStatusId == 1)
                    return ((i.AddressTypeId == 4 && i.AddressStatusId == 1) || (i.AddressTypeId == 10 && i.AddressStatusId == 1))
                });
                var physicalAddress = $.grep(data, function (i) {
                    // return ((i.IsMailingSameasPhysical || i.AddressTypeId == 10) && i.AddressStatusId == 1)
                    return ((i.AddressTypeId == 6 && i.AddressStatusId == 1) || (i.AddressTypeId == 11 && i.AddressStatusId == 1))
                });

                var zipAddress = $.grep(data, function (i) {
                    // return ((i.IsMailingSameasPhysical || i.AddressTypeId == 10) && i.AddressStatusId == 1)
                    return (i.AddressTypeId == 1 && i.AddressStatusId == 1)
                });
                if (mailingAddress && mailingAddress.length > 0) {
                    $scope.firmDetails.mailingAddress = mailingAddress[0];
                }
                if (physicalAddress && physicalAddress.length > 0) {
                    $scope.firmDetails.physicalAddress = physicalAddress[0];
                }
                if (zipAddress && zipAddress.length > 0) {
                    $scope.firmDetails.zipAddress = zipAddress[0];
                }
            }
        });
        $scope.$on("loadFirmContactData", function (evt, data) {
            // console.log('contact data', data);
            $scope.firmDetails.contactEmail = {};
            $scope.firmDetails.contactPhone = {};
            if (data) {
                var contactEmail = $.grep(data, function (i) {
                    return (i.ContactStatusId == 1 && i.ContactTypeId == 23);
                });
                if (contactEmail && contactEmail.length > 0) {
                    $scope.firmDetails.contactEmail = contactEmail[0];
                }

                var contactPhone = $.grep(data, function (i) {
                    return (i.ContactStatusId == 1 && i.ContactTypeId == 21);
                });
                if (contactPhone && contactPhone.length > 0) {
                    $scope.firmDetails.contactPhone = contactPhone[0];
                }
            }
        });
        $scope.$on("loadFirmLicenseData", function (evt, data) {
            // console.log('license data', data);
            $scope.firmDetails.licenseList = {};
            $scope.firmDetails.activeLicense = {};
            if (data) {
                $scope.firmDetails.licenseList = data;
                var activeLicense = $.grep(data, function (i) {
                    return (i.ProviderStatusTypeId == 1);
                });
                if (activeLicense && activeLicense.length > 0) {
                    $scope.firmDetails.activeLicense = activeLicense[0];
                }
            }
        });
        $scope.$on("businessNameChanged", function (evt, data) {
            GetProviderByProviderId();
        });


        //

        //Firm Information Tab
        var GetProviderByProviderId = function () {
            if ($scope.ProviderId) {
                //get provider from list
                BackofficeProviderService.GetProviderById(sessionStorage.Key, $scope.ProviderId).then(function (res) {
                    if (res.data.Status && res.data.ProviderResponse) {
                        $scope.ProviderData = res.data.ProviderResponse;
                        $scope.identificationChanged(true);
                        loadFirmStatusSection();
                        setContentItemLkIds();
                        $scope.getEntityConfirmation();
                        sessionStorage.ProviderName = $scope.ProviderData.ProviderName;
                    }
                });
            }
        };

        $scope.individualLogGetByProviderId = function (ProviderId) {
            try {
                ShowLoader();
                BackofficeProviderService.IndividualLogGetByProviderId(sessionStorage.Key, ProviderId, 'P')
                    .then(function(response){ response = response.data;
                        hideStatusMessage();
                        hideValidationSummary();
                        if ($scope.checkResponse(response)) {
                            if (response.IndividualCommentLogRequest == null) { } else {
                                $scope.LogArray = [];
                                $scope.LogArray = response.IndividualCommentLogRequest;
                            }
                        }
                        HideLoader();
                    }, function(data) { data = data.data;
                        HideLoader();
                        showStatusMessage(data, "error");
                    });
            } catch (ex) {
                HideLoader();
                if (ex != null) { showStatusMessage(ex.message, "error"); }
            }
        }

        $scope.UpdateProvider = function (form) {
            if (!$scope.validateData(form))
                return;
            if (!$scope.ProviderData || !$scope.ProviderData.ProviderName || $scope.ProviderData.ProviderName.trim() == "") {
                return;
            }
            if ($scope.ProviderId && $scope.ProviderData) {
                ShowLoader();
                BackofficeProviderService.ProviderSave(sessionStorage.Key, 0, sessionStorage.UserID, $scope.ProviderData).then(function (res) {
                    HideLoader();
                }, function () {
                    HideLoader();
                });
            }
        };
        $scope.displayFirmSearchResult = function (emp) {

            $rootScope.designatedContactPerson = null;
            hideStatusMessage();
            hideValidationSummary();
            ShowLoader("Please wait...");
            $scope.ProviderId = emp.ProviderId;
            sessionStorage.ProviderId = emp.ProviderId;
            $scope.EmployerName = emp.EmployerName;
            $scope.IndividualFinanceByProviderId($scope.ProviderId);
            //$scope.ProviderOtherByProviderId($scope.ProviderId);
            //$scope.ProviderEINByProviderId($scope.ProviderId);
            $scope.individualLogGetByProviderId($scope.ProviderId);
            GetProviderByProviderId($scope.ProviderId);
            getProviderOwnershipNew(true);
            InitializeDocumet();
            HideLoader();

            if (sessionStorage.isFirmUserCreated == 'true') {
                sessionStorage.isFirmUserCreated = false;
                $scope.setCurrentTab($scope.individualFirmViewConfig.tabs[1]);
            }
            else {
                $scope.setCurrentTab($scope.individualFirmViewConfig.tabs[0]);;
            }

        };

        var getProviderOwnershipNew = function (isInitLoad, ProviderOwnershipId) {
            providerOwnershipService.getProviderOwnershipNew(sessionStorage.Key, ProviderOwnershipId, $scope.ProviderId).then(function (res) {
                if (res.Status) {
                    $scope.ProviderOwnershipNew = res.EntityRecords[0];
                    if (!isInitLoad)
                        HideLoader();
                }
            })
        };

        var setContentItemLkIds = function () {
            $scope.constructionTypeId = 0;
            $scope.preNeedSaleTypeId = 0;
            $scope.COANumberTypeId = 0;

            if (!!$scope.ProviderData && $scope.ProviderData.ProviderLicenseTypeId && $scope.ProviderData.ProviderLicenseTypeId == 2) {
                $scope.constructionTypeId = 267;
                $scope.preNeedSaleTypeId = 259;
                $scope.COANumberTypeId = 260;
            }
            else if (!!$scope.ProviderData && $scope.ProviderData.ProviderLicenseTypeId && $scope.ProviderData.ProviderLicenseTypeId == 3) {
                $scope.constructionTypeId = 270;
            }
            else if (!!$scope.ProviderData && $scope.ProviderData.ProviderLicenseTypeId && $scope.ProviderData.ProviderLicenseTypeId == 1) {
                $scope.constructionTypeId = 330;
            }
        }

        $scope.getEntityConfirmation = function () {
            var deferred = $q.defer();

            try {
                EntityConfirmationService.getEntityConfirmation(sessionStorage.Key, $scope.ProviderId, 'P', null)
                    .then(function (data) {
                        if (data.Status) {
                            $scope.entityConfirmations = data.EntityConfirmations;
                            $scope.constructionType = data.EntityConfirmations.whereEquals($scope.constructionTypeId, 'ContentItemLkId')[0];
                            $scope.preNeedSale = data.EntityConfirmations.whereEquals($scope.preNeedSaleTypeId, 'ContentItemLkId')[0];
                            $scope.COANumber = data.EntityConfirmations.whereEquals($scope.COANumberTypeId, 'ContentItemLkId')[0];
                        } else {
                            $scope.entityConfirmations = [];
                        }
                        deferred.resolve(data);
                    }, function (err) {
                        deferred.reject(err);
                    });
            } catch (ex) {
                deferred.reject(ex);
            }

            return deferred.promise;
        };

        $scope.getInitData = function (countryID) {
            var dataPromises = [];
            dataPromises.push(countryStateService.getStateByCountryID(sessionStorage.Key, countryID));
            $q.all(dataPromises)
                .then(function (response) {
                    angular.forEach(response, function (responseItem, itemIndex) {
                        if ($scope.checkServerResponse(responseItem)) {
                            if (itemIndex == 0) {
                                $scope.StatesData = responseItem.State;
                            }
                        }
                    });
                    HideLoader();
                }, function (data) {
                    HideLoader();
                    if (data != null) { $scope.showStatusMessage(data.message, "error"); }
                });
        };
        var InitializeDocumet = function () {
            $scope.documentUpload = undefined;
            if ($scope.ProviderId) {
                var toutDoc = setTimeout(function () {
                    $scope.documentUpload = {
                        ApiSaveEndpoint: WebApiUrl + 'Provider/ProviderDocumentSaveMultiple/',
                        // ApplicationId: _applicationId,
                        ProviderId: $scope.ProviderId,
                        Email: null,
                        SendEmail: null,
                        TransactionId: null,
                        LicenseeReprint: false,
                        DocumentLkToPageTabSectionId: 1,
                        DocumentLkToPageTabSectionCode: "FRM",
                        LoadByDocIdAndDocCode: false,
                        //DocId: 1,
                        //DocCode: "D1001",
                        LoadDocumentsWithoutBroadCast: true
                    };
                    $scope.$apply();
                    clearTimeout(toutDoc);
                }, 500)
            }
        };
        $scope.identificationChanged = function (isProviderLoad) {
            if (isProviderLoad && $scope.ProviderData) {
                if ($scope.ProviderData.TaxId && $scope.ProviderData.TaxId != '') {
                    if ($scope.ProviderData.TaxId.indexOf("-") == $scope.ProviderData.TaxId.lastIndexOf("-")) {
                        $scope.identificationType = "EIN"
                    } else {
                        $scope.identificationType = "SSN"
                    }

                }
            } else if ($scope.ProviderData) {
                $scope.ProviderData.TaxId = '';
            }

            if ($scope.identificationType == "EIN") {
                $scope.placeHolderIdentification = "XX-XXXXXXX";
                $scope.formatIdentifiactionNo = "99-9999999"
            } else {
                $scope.placeHolderIdentification = "XXX-XX-XXXX";
                $scope.formatIdentifiactionNo = "999-99-9999"
            }
        };
        //

        //Othe Section Firm Information 

        //
        init();
    }
})();
