(function () {
    'use strict'

    angular.module('app.core')
    .directive('inlumonContent', ['$q', '$compile', 'InlumonContentService', InlumonContentDirective]);

    function InlumonContentDirective($q, $compile, InlumonContentService) {
        var getNewElementHtml = function (scope, element, attrs) {
            var directiveAttrs = ['content-id', 'content-code', 'page-tab-section-id', 'look-for', 'property-name', 'display', 'tag'];
            var DOM = '';

            if (!attrs.childHtml) {
                if (scope.display=='first') {
                    DOM = angular.element('<{0} inlumon-content-editor="{1}" style="position: relative; display: inline-block;"><span ng-bind-html="{1}.ContentItemLkDesc | trustedHtml"></span></{0}>'.format(scope.tag, scope.propertyName));
                } else {
                    DOM = angular.element('<{0} inlumon-content-editor="content" style="position: relative; display: inline-block;"><span ng-bind-html="content.ContentItemLkDesc | trustedHtml"></span></{0}>'.format(scope.tag));
                    DOM.attr('ng-repeat', 'content in {0}'.format(scope.propertyName));
                }
                //Copy all of the attributes over to the new select, except those exclusive to the directive
                for (var i = 0; i < element[0].attributes.length; i++) {
                    var attr = element[0].attributes[i];
    
                    if (!directiveAttrs.includes(attr.name)) {
                        DOM.attr(attr.name, attr.nodeValue);
                    }
                }
            } else {
                DOM = angular.element(attrs.childHtml);
            }

            return DOM;
        };

        return {
            restrict: 'E',
            require: '^?waitForAll',
            scope: {
                contentId: '=?',
                contentCode: '=?',
                pageTabSectionId: '=?',
                lookFor: '@?',
                propertyName: '@?',
                onLoaded: '&?',
                replace: '=?',
                display: '@?',          //"first" or "all" (default)
                tag: '@?',               //div (default)
                replaceLinkTarget: "=?"
            },
            link: function ($scope, element, attrs, waitCtrl) {
                var defaultPropertyName = 'content{0}';

                //Get the element/scope 
                var scopeElement = null;
                if (!!$scope.lookFor) {
                    scopeElement = element.closest($scope.lookFor);
                } else {
                    scopeElement = element.parent();
                }
                var effectiveScope = scopeElement.scope();

                //Now, get the content property suffix
                var defaultPropertySuffix = '';
                var promise = null;
                if (!!$scope.contentId) {
                    defaultPropertySuffix = 'Id' + $scope.contentId;
                } else if (!!$scope.contentCode) {
                    defaultPropertySuffix = 'Code' + $scope.contentCode.replace(/\W/g, '');
                } else if (!!$scope.pageTabSectionId) {
                    defaultPropertySuffix = 'PageTabId' + $scope.pageTabSectionId;
                } else {
                    //Error
                    throw 'Unable to retrieve content - you must supply either an ID, Code, or PageTabSection';
                }

                //Get the list name
                var alreadyLoaded = true;

                if (!$scope.propertyName) {
                    $scope.propertyName = defaultPropertyName.format(defaultPropertySuffix);
                }
                if (typeof(effectiveScope[$scope.propertyName]) == 'undefined') {
                    effectiveScope[$scope.propertyName] = [];
                    alreadyLoaded = false;
                } 
                
                if (typeof($scope.replace) == 'undefined') {
                    $scope.replace = true;
                }

                //Get the content display
                if (!['first', 'all'].includes($scope.display)) {
                    $scope.display = 'all';
                }

                //Get the tag with which to layout the content
                if (!$scope.tag) {
                    $scope.tag = 'div';
                }

                var convertLinksToBlankTarget = function (htmlString) {
                    if (htmlString) {
                        // Create a temporary div element
                        var tempDiv = document.createElement('div');
                        tempDiv.innerHTML = htmlString;

                        // Select all anchor elements
                        var links = tempDiv.querySelectorAll('a');

                        // Loop through each anchor element
                        links.forEach(function (link) {
                            // Add target="_blank" attribute
                            link.setAttribute('target', '_blank');
                        });

                        // Convert the modified HTML back to string
                        var modifiedHtmlString = tempDiv.innerHTML;

                        return modifiedHtmlString;
                    } else {
                        return htmlString;
                    }
                }

                //Get Data Function
                var getContent = function () {
                    var deferred = $q.defer();

                    if (!!alreadyLoaded) {
                        deferred.resolve({
                            Status: true,
                            StatusCode: '00',
                            ContentItemLk: (Array.isArray(effectiveScope[$scope.propertyName])) ? effectiveScope[$scope.propertyName] : [effectiveScope[$scope.propertyName]]
                        });
                        return deferred.promise;
                    }

                    try {
                        if (!!$scope.contentId) {
                            promise = InlumonContentService.getContentById(sessionStorage.Key, $scope.contentId);
                        } else if (!!$scope.contentCode) {
                            promise = InlumonContentService.getContentByCode(sessionStorage.Key, $scope.contentCode);
                        } else if (!!$scope.pageTabSectionId) {
                            promise = InlumonContentService.getContentByPageTabSectionId(sessionStorage.Key, $scope.pageTabSectionId);
                        }
    
                        promise
                        .then(function (data) {
                            if (!data.Status) {
                                data.ContentItemLk = [{ ContentItemLkDesc: 'Content Not Found.'}];
                            }
                                if ($scope.replaceLinkTarget == undefined || $scope.replaceLinkTarget == true && data.ContentItemLk && data.ContentItemLk.length > 0) {
                                    for (var i = 0; i < data.ContentItemLk.length; i++) {
                                        data.ContentItemLk[i].ContentItemLkDesc = convertLinksToBlankTarget(data.ContentItemLk[i].ContentItemLkDesc);
                                    }
                                }
                            switch($scope.display) {
                                case 'first':
                                    effectiveScope[$scope.propertyName] = data.ContentItemLk.firstOrDefault();
                                    break;
                                case 'all':
                                default:
                                    effectiveScope[$scope.propertyName] = data.ContentItemLk;
                                    break;
                            }

                            deferred.resolve(data);
                        }, function (err) {
                            console.error(err);
                            deferred.reject(err);
                        });
                    } catch (ex) {
                        console.error(ex);
                        deferred.reject(ex);
                    }

                    return deferred.promise;
                };

                //Completion Function
                var contentLoadingComplete = function () {
                    if ($scope.replace) {
                        //Get the new element that will replace this directive
                        var newHtml = getNewElementHtml($scope, element, attrs);

                        var $e = $compile(newHtml)(effectiveScope);
                        element.replaceWith($e);
                    }

                    if (!!$scope.onLoaded) {
                        $scope.onLoaded({content: effectiveScope[$scope.propertyName]});
                    }
                };

                if (!waitCtrl) {
                    //No wait controller, just run and then complete.
                    getContent()
                    .then(function (data) {
                        contentLoadingComplete();
                    });
                } else {
                    //There's a wait controller, follow its instructions
                    waitCtrl.registerWaitChild({
                        getData: getContent,
                        complete: contentLoadingComplete,
                        failure: function () {}
                    });
                }
            },
            template: function (element, attrs) {
                attrs.childHtml = element[0].innerHTML.trim();
            }
        };
    }
})();
