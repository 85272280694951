(function () {
    'use strict';
    angular.module('app.backofficeAdministor')
        .controller("SetPermissionsController", SetPermissionsController);
    SetPermissionsController.$inject = ["$scope", "$controller", "SetPermissionsService", "$stateParams", "$state", "$timeout", "utilityService", "$q", "Messages"];
    function SetPermissionsController($scope, $controller, SetPermissionsService, $stateParams, $state, $timeout, utilityService, $q, Messages) {
        $controller('MainController', { $scope: $scope });

        var key = sessionStorage.Key;
        $scope.MenuTreeData = [];
        $scope.init = function () {
            $scope.getRoleList();
            $scope.name = $stateParams.rolename;
            $scope.group = $stateParams.usertype;
            $scope.description = $stateParams.description;
            $scope.roleid = $stateParams.roleid;
        };
        var loadingMenuTreeData = false;
        $scope.loadMenuTreeData = function () {
            loadingMenuTreeData = true;
            if (($scope.roleid && $scope.roleid != 0) || ($scope.group && $scope.group != 0)) {
                var urlParam = "?Key=" + (key ? key : '') + "&roleid=" + ($scope.roleid ? $scope.roleid : 0) + "&usertypeid=" + ($scope.group ? $scope.group : 0);
                SetPermissionsService.GetJSTreeMenuList(urlParam).then(function (res) {
                    if (res && res.TreeItemList) {
                        $scope.MenuTreeData = res.TreeItemList;
                        GenerateTreeModel();
                    }
                    HideLoader();
                    loadingMenuTreeData = false;
                }, function () {
                    HideLoader();
                    loadingMenuTreeData = false;
                });
            } else {
                HideLoader();
                loadingMenuTreeData = false;
                $scope.MenuTreeData = [];
            }
        };

        $scope.getRoleList = function () {
            try {
                ShowLoader("Please wait...");
                SetPermissionsService.UserRoleGetAll(key)
                    .then(function (response) {
                        $scope.usertypes = response.UserType;
                        $scope.loadMenuTreeData();
                        AuditVisitInfoSave();
                    })
                    .catch(function () {
                        HideLoader();
                        if (data != null) {
                            showStatusMessage(data.message, "error");
                        }
                    });
            }
            catch (ex) {
                HideLoader();
                if (ex != null) {
                    showStatusMessage(ex.message, "error");
                }
            }
        };
        $scope.TreeDataModel = [];
        var GenerateTreeModel = function () {
            try {
                if ($scope.MenuTreeData && $scope.MenuTreeData.length > 0) {
                    var nodeL = [];
                    var node = {};
                    var modelItem = {};
                    for (var i = 0; i < $scope.MenuTreeData.length; i++) {
                        nodeL = $scope.MenuTreeData[i];
                        if (nodeL && nodeL.length > 0) {
                            for (var j = 0; j < nodeL.length; j++) {
                                node = nodeL[j];
                                if (node && node.state && node.id.split('_').length >= 3) {
                                    var id = node.parent;
                                    var ind = $scope.TreeDataModel.map(function (i) { return i.id }).indexOf(id);
                                    if (ind != -1) {
                                        switch (node.id.split('_')[2]) {
                                            case 'Create': {
                                                $scope.TreeDataModel[ind].Create = node.state.selected;
                                                break;
                                            }
                                            case 'Update': {
                                                $scope.TreeDataModel[ind].Update = node.state.selected;
                                                break;
                                            }
                                            case 'Delete': {
                                                $scope.TreeDataModel[ind].Delete = node.state.selected;
                                                break;
                                            }
                                            case 'Read': {
                                                $scope.TreeDataModel[ind].Read = node.state.selected;
                                                break;
                                            }
                                        }
                                    } else {
                                        modelItem.id = id;
                                        switch (node.id.split('_')[2]) {
                                            case 'Create': {
                                                modelItem.Create = node.state.selected;
                                                break;
                                            }
                                            case 'Update': {
                                                modelItem.Update = node.state.selected;
                                                break;
                                            }
                                            case 'Delete': {
                                                modelItem.Delete = node.state.selected;
                                                break;
                                            }
                                            case 'Read': {
                                                modelItem.Read = node.state.selected;
                                                break;
                                            }
                                        }
                                        $scope.TreeDataModel.splice(0, 0, modelItem);
                                        modelItem = {};
                                    }
                                }
                            }
                        }
                    }
                }
            }
            catch (ee) {
                //  console.log('Exception:: ' + ee);
                alert(Messages.General.Error.SomeError);
            }
        };

        var UpdateTreeModel = function (id, field, actionRes) {
            try {
                if ($scope.TreeDataModel) {
                    var ind = $scope.TreeDataModel.map(function (i) { return i.id }).indexOf(id);
                    if (ind != -1) {
                        switch (field) {
                            case 'Create': {
                                $scope.TreeDataModel[ind].Create = actionRes;
                                break;
                            }
                            case 'Update': {
                                $scope.TreeDataModel[ind].Update = actionRes;
                                break;
                            }
                            case 'Delete': {
                                $scope.TreeDataModel[ind].Delete = actionRes;
                                break;
                            }
                            case 'Read': {
                                $scope.TreeDataModel[ind].Read = actionRes;
                                break;
                            }
                        }
                    }
                }
            }
            catch (ee) {
                //  console.log('Exception:: ' + ee);
                alert(Messages.General.Error.SomeError);
            }
        };

        $scope.changedTreeViewSelection = function (ee, node) {
            try {
                if (node && node.node && !loadingMenuTreeData) {
                    var selectedNodeID = node.selected;
                    var currentNodeId = node.node.id;
                    var arr = [];
                    //is current node selected??
                    if (selectedNodeID.indexOf(currentNodeId) == -1) {
                        //get all children's id
                        selectedNodeID = node.node.children_d;

                        selectedNodeID.push(currentNodeId);
                        for (var i = 0; i < selectedNodeID.length; i++) {
                            arr = selectedNodeID[i].split('_');
                            if (arr.length >= 3) {
                                UpdateTreeModel(arr[0] + '_' + arr[1], arr[2], false);
                            }
                        }
                    } else {
                        for (var i = 0; i < selectedNodeID.length; i++) {
                            arr = selectedNodeID[i].split('_');
                            if (arr.length >= 3) {
                                UpdateTreeModel(arr[0] + '_' + arr[1], arr[2], true);
                            }
                        }
                    }
                }
            }
            catch (ee) {
                // console.log('Exception:: ' + ee);
                alert(Messages.General.Error.SomeError);
            }
        };

        $scope.save = function () {
            ShowLoader();
            var param = {
                Name: $scope.name,
                Description: $scope.description,
                RoleId: $stateParams.roleid == undefined ? 0 : $stateParams.roleid,
                UserTypeId: $scope.group,
                IsActive: true,
                CreatedOn: $scope.date,
                BoardAuthorityId: 2
            };
            SetPermissionsService.savePermissions(key, param)
                .then(function (response) {
                    if (response.Status && $scope.TreeDataModel) {
                        var RoleId = response.Role[0].RoleId;
                        var paramList = [];
                        for (var i = 0; i < $scope.TreeDataModel.length; i++) {
                            var item = $scope.TreeDataModel[i];
                            var arr = item.id.split('_');
                            if ((item && arr.length > 1 && arr[1] != '0') || item.Create || item.Update || item.Read || item.Delete) {
                                var param = {
                                    RoleMenuId: arr[1],
                                    RoleId: RoleId,
                                    MenuId: arr[0],
                                    Create: item.Create,
                                    Update: item.Update,
                                    Read: item.Read,
                                    Delete: item.Delete,
                                    IsActive: true,
                                    IsDeleted: false
                                };
                                paramList.splice(0, 0, param);
                            }
                        }
                        if (paramList.length > 0) {
                            SetPermissionsService.RoleMenusSave(key, paramList)
                                .then(function (response) {
                                    if (response.Status) {
                                        HideLoader();
                                        if (response.StatusCode == 0) {
                                            $timeout(function () {
                                                hideStatusMessage();
                                                $state.go('app.Role');
                                            }, 500);
                                        }
                                    }
                                    else {
                                        HideLoader();
                                    }
                                }, function (res) {
                                    console.log('err', res);
                                    HideLoader();
                                })
                                .catch(function (response) {
                                    HideLoader();
                                });
                        } else {
                            HideLoader();
                            if (response.StatusCode == 0) {
                                $timeout(function () {
                                    hideStatusMessage();
                                    $state.go('app.Role');
                                }, 500);
                            }
                        }
                    } else {
                        HideLoader();
                    }
                }, function () {
                    HideLoader();
                })
                .catch(function (response) {
                    HideLoader();
                });
        };
        function AuditVisitInfoSave() {
            var data = {
                "PageName": "Roles Management",
                "SessionId": sessionStorage.Key,
                "IndividualId": sessionStorage.IndividualId,
                "EntityId": 0,
                "UserId": sessionStorage.UserId,
                "Username": "",
                "RequestUrlReferrer": document.referrer
            };
            utilityService.AuditVisitInfo(data);
        }
    }
})();
