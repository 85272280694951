﻿(function () {
    'use strict'
    angular.module('app.login')
        .controller("FirmLoginController", ["$scope", "$controller", "$cookies", "LoginwemailService", "UserAccountService", "$state","Messages",
            function ($scope, $controller, $cookies, LoginwemailService, UserAccountService, $state, Messages) {
                // Variables
                $scope.recordlist = [];
                $scope.Email = "";
                $scope.Password = "";
                // page init method
                $scope.init = function () {
                    try {
                        sessionStorage.IsLicenseActive = undefined;
                        sessionStorage.key = "";
                        if ($scope.isUserSessionActive()) { } else {
                            sessionStorage.Key = "";
                        }
                    } catch (ex) {
                        if (ex != null) { $scope.showStatusMessage(ex.message, "error"); }
                    }
                    //
                };

                // keep me login
                $scope.keepMeLoggedin = function () {
                    try {
                        if ($scope.KeepMeLoggedIn) {
                            var userdata = {
                                USERNAME: btoa($scope.Email),
                                PASSWORD: btoa($scope.Password)
                            }
                            var jsonuserdata = JSON.stringify(userdata);
                            var date = new Date();
                            date.setTime(date.getTime() + (24 * 60 * 60 * 1000));
                            $cookies.put('USERAUTH', jsonuserdata, {
                                expires: date.toUTCString()
                            });

                        } else {
                            $cookies.remove('USERAUTH', '');
                        }
                    } catch (ex) {
                        if (ex != null) { $scope.showStatusMessage(ex.message, "error"); }
                    }
                }

                // Login User
                $scope.login = function () {
                    try {
                        if (validateData('loginwemailvalidation')) {
                            ShowLoader("Please wait...");
                            var Email = $scope.Email;
                            var Password = $scope.Password;
                            var LoginWithoutEmail = false;

                            LoginwemailService.EntityLogin(Email, Password, LoginWithoutEmail)
                                .then(function (res) {
                                    var response = res.data;
                                    if ($scope.checkServerResponse(response)) {
                                        $scope.Key = response.Key;
                                        $scope.keepMeLoggedin();
                                        if (response.UserInfo.UserTypeName == "Applicant") {
                                            sessionStorage.Key = $scope.Key;
                                            $state.go('app.Dashboard');
                                            sessionStorage.UserId = response.IndividualID;
                                        } else if (response.UserInfo.UserTypeName == "Licensee") {
                                            sessionStorage.Key = $scope.Key;
                                            sessionStorage.UserId = response.IndividualID;
                                            sessionStorage.Email = $scope.Email;
                                            $state.go('app.LicenseRenewalHASnA');
                                        } else if (response.UserInfo.UserTypeName == "Staff") {
                                            $scope.showStatusMessage(Messages.General.Errors.Credentials, "error");
                                            $scope.Password = "";
                                        } else {
                                            $scope.showStatusMessage(Messages.General.Errors.Credentials, "error");
                                            $scope.Password = "";
                                        }
                                        sessionStorage.isBackOfficeUser = false;
                                    } else {
                                        $scope.showStatusMessage(response.Message, "error");
                                    }
                                }, function (res) {
                                    var data = res.data;
                                    if (data != null) { $scope.showStatusMessage(data.message, "error"); }
                                });
                        }
                    } catch (ex) {
                        $scope.showStatusMessage(Messages.General.Errors.LoginWUsername(ex.message), "error");
                    }
                    HideLoader();
                };

                // Get UserDetail if Keep me Login Active
                var UserDetails = $cookies.get('USERAUTH');
                if (UserDetails) {
                    var user = angular.fromJson(UserDetails);
                    $scope.Email = atob(user.USERNAME);
                    $scope.Password = atob(user.PASSWORD);
                    $scope.KeepMeLoggedIn = true;
                }

                // Goto Register New User Page
                $scope.register = function () {
                    $state.go('app.FirmRegister');
                }

                ////////////////////////
                // Login new User
                $scope.NewApplicantLogin = function () {
                    try {
                        if (validateData('loginwemailvalidation')) {
                            ShowLoader("Please wait...");
                            var Email = $scope.Email;
                            var Password = $scope.Password;
                            var LoginWithoutEmail = false;

                            LoginwemailService.EntityLogin(Email, Password, LoginWithoutEmail)
                                .then(function (res) {

                                    var response = res.data;
                                    if (response.Status) {
                                        UserRoleGetBYUserID(response);
                                    } else {
                                        HideLoader();
                                        $scope.showStatusMessage(response.Message, "error");
                                    }
                                }, function (res) {
                                    HideLoader();
                                    var data = res.data;
                                    if (data != null) { $scope.showStatusMessage(data.Message, "error"); }
                                });
                        }
                    } catch (ex) {
                        HideLoader();
                        $scope.showStatusMessage(ex.message, "error");
                    }
                    //
                };
                /////////////////////  

                var UserRoleGetBYUserID = function (data) {
                    var userData = data;

                    var FormatedUserData = {
                        Key: userData.Key,
                        ProviderId: userData.UserInfo.EntityId,
                        Email: userData.UserInfo.Email,
                        FirstName: userData.UserInfo.FirstName,
                        LastName: userData.UserInfo.LastName,
                        TemporaryPassword: userData.UserInfo.TemporaryPassword,
                        UserID: userData.UserInfo.UserID,
                        UserName: userData.UserInfo.UserName,
                        UserTypeID: userData.UserInfo.UserTypeID,
                        UserTypeName: userData.UserInfo.UserTypeName
                    };

                    sessionStorage.ProviderName = userData.UserInfo.ProviderName;
                    sessionStorage.LicenseNumber = userData.UserInfo.LicenseNumber;
                    sessionStorage.LicenseTypeName = userData.UserInfo.LicenseTypeName ? userData.UserInfo.LicenseTypeName : '';
                    sessionStorage.EIN = userData.UserInfo.EIN;
                    var urlParm = "?Key=" + userData.Key + "&UserId=" + userData.UserID;
                    LoginwemailService.UserRoleGetBYUserID(urlParm).then(function (res) {
                        HideLoader();
                        if (res.data.Status) {
                            var logRequest = {
                                EntityId: userData.UserInfo.EntityId,
                                ProviderId: userData.UserInfo.EntityId,                               
                                MasterTransactionId: 1,
                                PageModuleId: 1,
                                PageModuleTabSubModuleId: 1,
                                PageTabSectionId: 1,
                                Type: "L",
                                CommentLogSource: "WSAPI",
                                IsInternalOnly: true,
                                IsForInvestigationOnly: true,
                                IsForPublic: false,
                                IsActive: true,
                                NotesTypeId: 1,
                                DoNotProcessNotesLog: true,
                                EntityType: 'P'
                            };
                            logRequest.messageType = "C";                            
                            var UserRole = res.data.UserRole[0];
                            SetSessionStorageUserData(FormatedUserData);
                            SetSessionStorageUserRoleData(UserRole);
                            var userTypeID = userData.UserInfo.UserTypeID;
                            //console.log(userData);
                            // if (userData.UserInfo.UserTypeName == "Applicant") {
                            //     $scope.showStatusMessage(Messages.afdbcfd, 'error');
                            //     //  if (userData.UserInfo.TemporaryPassword) {
                            //     //    $state.go('app.ChangePassword');
                            //     //} else {
                            //     //    $state.go('app.Dashboard');
                            //     //}
                            // } else 
                            // if (userData.UserInfo.UserTypeName == "Licensee") {
                            if (userData.UserInfo.TemporaryPassword) {
                                logRequest.CommentLogText ="Change Password Attempt - Success.";
                                LoginwemailService.FirmLogging(logRequest, ((!!sessionStorage.Key) ? sessionStorage.Key : '')).then(function (res) {
                                }, function (res) {
                                    HideLoader();
                                });
                                $state.go('app.FirmChangePassword');
                            } else {
                                logRequest.CommentLogText ="Login Attempt - Sucessfull Login.";
                                LoginwemailService.FirmLogging(logRequest, ((!!sessionStorage.Key) ? sessionStorage.Key : '')).then(function (res) {
                                }, function (res) {
                                    HideLoader();
                                });
                                $state.go('app.providerdashboard');
                            }
                            // } else if (userData.UserInfo.UserTypeName == "Staff") {
                            //     $scope.showStatusMessage(Messages.aadaebb, "error");
                            //     $scope.Password = "";
                            // } else {
                            //     $scope.showStatusMessage(Messages.aadaebb, "error");
                            //     $scope.Password = "";
                            // }
                            //if (userData.UserInfo.TemporaryPassword) {
                            //    //window.location.replace('@Url.Action("ResetPassword", "Account")');
                            //}
                            //else {
                            //    ApplicationBYIndividualId(userData);
                            //    HideLoader();
                            //}
                            // ends here
                        } else {
                            HideLoader();
                            $scope.showStatusMessage(res.data.Message, 'error');
                        }
                    }, function (res) {
                        HideLoader();
                        LogSave("API ERR: api/Users/UserRoleGetBYUserID" + urlParm, "Error response - " + JSON.stringify(res) + " Requested Data::: " + JSON.stringify(FormatedUserData));
                        $scope.showStatusMessage(Messages.ffaaddd, 'error');
                    })
                };

                var ApplicationBYIndividualId = function (userData) {
                    var urlParm = "?Key=" + userData.Key + "&IndividualId=" + userData.IndividualID;
                    LoginwemailService.ApplicationBYIndividualId(urlParm).then(function (res) {
                        if (res.data.Status) {
                            var userType = sessionStorage.UserTypeID;
                            HideLoader();
                            var App = res.data.ApplicationResponseList[0];
                            if (App) {
                                SaveApplicationDetailsToSession(App);
                                //var AppTypeID = App.ApplicationTypeId;
                                //var ApplicationStatusId = App.ApplicationStatusId;
                            }
                            if (userType == 2) {
                                $state.go('app.Dashboard');
                                //window.location.replace('@Url.Action("Application", "Certification")' + '#/instruction');
                            } else if (userType == 6) {
                                $state.go('app.LicenseRenewalHASnA');
                                //window.location.replace('@Url.Action("Index", "ReCertification")');
                            } else if (userType == 4) {
                                //window.location.replace('@Url.Action("Index","Search",new { Area = "Agency" })');
                            } else {
                                //window.location.replace('@Url.Action("Index","Home",new { Area = "BackOffice" })');
                            }
                        } else {
                            HideLoader();
                            $scope.showStatusMessage(res.data.Message);
                        }
                    }, function (res) {
                        LogSave("API ERR: api/Individual/ApplicationBYIndividualId" + urlParm, "Error response - " + JSON.stringify(res));
                        HideLoader();
                        $scope.showStatusMessage(res.data.Message);
                    });
                };

                //audit api call
                var AuditvisitInfoSave = function () {
                    var parm = 'key';
                    var data = {
                        HostIPAddress: location.host,
                        PageName: 'Individual Login',
                        RequestUrl: window.location.href,
                        RequestUrlReferrer: document.referrer,
                        RequestBrowserTypeVersion: 'Type: ' + navigator.appCodeName + ', Version: ' + navigator.appVersion,
                        SessionID: '', //document.cookie,
                        TimeStamp: new Date(),
                        IndividualId: 0,
                        EntityId: '',
                        UserId: 0,
                        Platform: navigator.platform,
                        MachineDeviceName: '',
                        DeviceId: '',
                        AppDomainName: '',
                        UserHostAddress: '',
                        UserHostName: '',
                        IsJavascriptEnabled: true,
                        IsCookieEnabled: navigator.cookieEnabled,
                        IsCrawler: '',
                        IsActiveXControlEnabled: ''
                    };
                    LoginwemailService.AuditvisitInfoSave(parm, data).then(function (res) {
                        /// auditSaved
                    }, function (res) {

                    });
                };

                var LogSave = function (message, stackTrace) {
                    var parm = 'key';
                    var data = {
                        Source: '2',
                        EventID: 0,
                        IndividualId: 0,
                        EntityId: 0,
                        UserId: 0,
                        UserName: '',
                        Priority: 5,
                        Severity: 5,
                        Title: "JS Exception",
                        Timestamp: new Date(),
                        MachineName: 'NA',
                        AppDomainName: window.location.hostname,
                        Application: 'Individual Login',
                        ProcessID: 0,
                        ProcessName: 'Login Process API Call',
                        ThreadName: '',
                        Win32ThreadId: '',
                        RequestUrl: window.location.href,
                        RequestUrlReferrer: document.referrer,
                        RequestBrowserTypeVersion: 'Type: ' + navigator.appCodeName + ', Version: ' + navigator.appVersion,
                        UserAgent: navigator.userAgent,
                        UserHostAddress: '',
                        UserHostName: '',
                        Message: 'Javascript API call error',
                        FormattedMessage: '',
                        StackTrace: stackTrace,
                        Exception: message,
                        ElapsedMs: 0,
                        SessionId: '',
                        IsDebug: false
                    };
                    LoginwemailService.LogSave(parm, data).then(function (res) {
                        /// auditSaved
                    }, function (res) {

                    });
                }
                var SetSessionStorageUserData = function (data) {

                    //sessionStorage._UserDetails = JSON.stringify(userInfo);
                    sessionStorage.ProviderId = data.ProviderId;
                    sessionStorage.Email = data.Email;
                    sessionStorage.FirstName = data.FirstName;
                    sessionStorage.ProviderId = data.ProviderId;
                    sessionStorage.LastName = data.LastName;
                    sessionStorage.TemporaryPassword = data.TemporaryPassword;
                    sessionStorage.UserID = data.UserID;
                    sessionStorage.UserId = data.UserID;
                    sessionStorage.UserName = data.UserName;
                    sessionStorage.UserTypeID = data.UserTypeID;
                    sessionStorage.UserTypeName = data.UserTypeName;
                    sessionStorage.Key = data.Key;
                    sessionStorage.isBackOfficeUser = false;
                };
                var SetSessionStorageUserRoleData = function (data) {
                    sessionStorage.Grantable = data.Grantable;
                    sessionStorage.Role = data.Role;
                    sessionStorage.RoleID = data.RoleID;
                    sessionStorage.Selected = data.Selected;
                    sessionStorage.UserRoleID = data.UserRoleID;
                };

                var SaveApplicationDetailsToSession = function (data) {
                    if (data) {
                        sessionStorage.ApplicationId = data.ApplicationId;
                        sessionStorage.ApplicationStatus = data.ApplicationStatus;
                        sessionStorage.IndividualNameId = data.IndividualNameId;
                        sessionStorage.ProviderId = data.ProviderId;
                    }
                };
                $scope.GotoforgetPassword = function () {
                    // $state.go('app.ChangePassword');
                    $state.go('app.FirmForgotPassword');
                };

                $scope.GotoforgetUserName = function () {
                    $state.go('app.FirmForgotUserName');
                };
                
                $scope.Loginwemail = function () {
                    $state.go('app.Loginwemail');
                };

                var currentState = $state.current.name;
                $scope.backtLogin = function () {
                    switch (currentState) {
                        case 'app.ForgotPassword': {
                            $state.go('app.BackofficeLoginstaff');
                            break;
                        }
                        case 'app.ApplicantForgotPassword': {
                            $state.go('app.Loginwemail');
                            break;
                        }
                    }
                };
                AuditvisitInfoSave();
            }
        ]);
})();

// $scope.register = function () {
//                     $state.go('app.FirmRegister');
//                 }
//                 //AuditvisitInfoSave();
//             }]);
// })();