(function () {
    'use strict'

    angular.module('app.backofficeProvider')
        .controller("BackofficeProviderCorrespondenceController", BackofficeProviderCorrespondenceController);

    BackofficeProviderCorrespondenceController.$inject = ["$rootScope", "$scope", "$state", "backofficeProviderCorrespondenceService", "utilityService", "WebApiUrl"];

    function BackofficeProviderCorrespondenceController($rootScope, $scope, $state, backofficeProviderCorrespondenceService, utilityService, WebApiUrl) {

        $scope.viewConfig = {
            showAddBlock: false
        };
        $scope.isCorespondenceEdit = false;
        $scope.sortType = 'CommunicationLogDate';
        $scope.sortOrder = '+';
        $scope.changeSort = function (sortType) {
            if ($scope.sortType == sortType) {
                if ($scope.sortOrder == '+')
                    $scope.sortOrder = '-';
                else
                    $scope.sortOrder = '+';
            } else {
                $scope.sortOrder = '+';
            }
            $scope.sortType = sortType;
        };
        //Tab Initilizer
        $scope.$on("individualTabChanged", function (evt, providerId) {
            if ($rootScope.individualFirmCurrentTab.name == "correspondence") {
                $scope.providerId = providerId;

                $scope.individualCorrespondenceByIndividualId(providerId, true);
                AuditVisitInfoSave();
            }
        });

        $scope.onLoad = function (e, reader, file, fileList, fileOjects, fileObj) {
            $scope.Base64String = fileObj.base64;
            $scope.fileNameWithExtension = file.name;
            $scope.isFileLoaded = true;
        };

        var clearFileValues = function () {
            $scope.files = [];
            $scope.Base64String = null;
            $scope.fileNameWithExtension = '';
            $scope.isFileLoaded = false;
        };

        $scope.openAddEditCorrespondence = function (isEdit, individualCorrespondence) {
            ShowLoader();
            clearFileValues();
            $scope.isCorrespondenceEdit = isEdit;
            $scope.viewConfig.showAddBlock = true;
            if (!$scope.isCorrespondenceEdit) {
                $scope.individualCorrespondence = {
                    IndividualId: $scope.providerId,
                    IsActive: true,
                    CommunicationLogDate: new Date()
                }
            } else {
                $scope.individualCorrespondence = angular.copy(individualCorrespondence);
            }
            HideLoader();
        };

        $scope.closeAddEditCorrespondence = function () {
            $scope.isCorrespondenceEdit = false;
            $scope.viewConfig.showAddBlock = false;
        };

        $scope.individualCorrespondenceByIndividualId = function (providerId, hideMessages) {
            ShowLoader("Please Wait...");
            backofficeProviderCorrespondenceService.individualCorrespondenceByIndividualId(sessionStorage.Key, providerId)
                .then(function (response) {
                    if (hideMessages) {
                        $scope.hideStatusMessage();
                        $scope.hideValidationSummary();
                    }
                    HideLoader();
                    if ($scope.checkServerResponse(response)) {
                        if (!!response.IndividualCommunicationLogRequest) {
                            $scope.individualCorrespondences = response.IndividualCommunicationLogRequest;
                        }
                        else {
                            $scope.individualCorrespondences = [];
                        }

                    }
                },
                    function (data) {
                        $scope.showStatusMessage(data, "error");
                    });
        };

        $scope.saveIndividualCorrespondence = function (correspondenceForm) {
            try {
                $scope.hideStatusMessage();
                $scope.hideValidationSummary();
                correspondenceForm.$submitted = true;
                if ($scope.validateData(correspondenceForm)) {
                    ShowLoader("Please wait...");
                    $scope.saveIndividualCorrespondenceRecord();
                }
            } catch (ex) {
                HideLoader();
                if (ex != null) { $scope.showStatusMessage(ex.message, "error"); }
            }
        };

        $scope.saveIndividualCorrespondenceRecord = function () {
            var individualCorrespondenceData = angular.copy($scope.individualCorrespondence);
            individualCorrespondenceData.IndividualCommunicationLogId = undefined;
            if ($scope.isFileLoaded)
                individualCorrespondenceData.DocumentUploadList = [{
                    IndividualDocumentId: 1,
                    DocumentLkToPageTabSectionId: 1,
                    DocumentLkToPageTabSectionCode: "FR",
                    DocumentTypeName: "Correspondence",
                    DocumentPath: null,
                    DocNameWithExtention: $scope.fileNameWithExtension,
                    DocStrBase64: $scope.Base64String,
                    EffectiveDate: new Date(),
                    EndDate: null,
                    IsDocumentUploadedbyIndividual: true,
                    IsDocumentUploadedbyStaff: true,
                    ReferenceNumber: null,
                    DocumentId: 1,
                    DocumentCd: null,
                    DocumentTypeId: 1,
                    OtherDocumentTypeName: null,
                    EntityType: "P"
                }];

            backofficeProviderCorrespondenceService.individualCorrespondenceSave(individualCorrespondenceData,
                sessionStorage.Key).then(function (response) {
                    if ($scope.checkServerResponse(response)) {
                        $scope.individualCorrespondenceByIndividualId($scope.providerId);
                        $scope.closeAddEditCorrespondence();
                        $scope.showStatusMessage(response.Message, "success");
                    }
                    HideLoader();
                }, function (data) {
                    HideLoader();
                    if (data != null) { $scope.showStatusMessage(data.message, "error"); }
                });
        };

        function AuditVisitInfoSave() {
            var data = {
                "PageName": "IndividualInfo-CorrespondenceTab",
                "SessionId": sessionStorage.Key,
                "IndividualId": $scope.providerId,
                "ProviderId": $scope.providerId,
                "EntityId": 0,
                "UserId": sessionStorage.UserId,
                "Username": "",
                "RequestUrlReferrer": document.referrer
            };
            utilityService.AuditVisitInfo(data);
        }
    }
})();