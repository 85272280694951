﻿(function () {
    'use strict';
    
    angular.module('task.management')
    .controller('TaskExecutionLogController', ['$scope', '$q', '$state', 'TaskExecutionLogService', TaskExecutionLogController]);

    function TaskExecutionLogController($scope, $q, $state, TaskExecutionLogService) {
        var _key = sessionStorage.Key;

        var resetSearchCriteria = function () {
            $scope.searchCriteria = {
                FromDate: null,
                ToDate: null,
                Command: 'All',
                CmdStatus: 1,
                TaskExecutionStatusId: 0,
                FromRowNum: 0,
                RecordToFetch: 20,
                Order: 1,
                OrderBy: 'TaskSchduleDate',
                GMTMin: new Date().getTimezoneOffset(),
                LocalDate: new Date()
            };

            $scope.paginationParams = {
                showPager: true,
                totalRecords: 0,
                currentPage: 1,
                pageSize: 20,
                pageChanged: $scope.pageChanged
            };

            $scope.sortHeader = null;
        };

        var searchTaskExecutionLog = function () {
            try {
                $scope.loadingTasks(true, 'Searching...');

                TaskExecutionLogService.getTaskScheduleList(_key, $scope.taskScheduleAutoId, $scope.currentSearchCriteria)
                .then(function (data) {
                    if(data.Status) {
                        $scope.taskExecutionList = data.TaskScheduleExecutionList;
                        $scope.paginationParams.totalRecords = data.RecordCount;
                    }
                    $scope.loadingTasks(false);
                }, function (error) {
                    $scope.loadingTasks(false);
                });
            } catch (ex) {
                $scope.loadingTasks(false);
            }
        };

        $scope.init = function () {
            resetSearchCriteria();

            $scope.datePicker = {
                fromDateOpened: false,
                toDateOpened: false,
                format: 'MM/dd/yyyy'
            };

            $scope.currentSearchCriteria = angular.copy($scope.searchCriteria);

            $scope.showSearchSection = true;

            $scope.tableEvents = {
                showHideSearch: $scope.showHideSearch,
                viewTaskExecutionLogDetails: $scope.viewTaskExecutionLogDetails,
                executeTask: $scope.executeTask
            };

            $scope.tableHeaders = {
                TaskTypeName: {
                    label: 'Task Type',
                    sizeRatio: 2
                },
                TaskScheduleName: {
                    label: 'Task Name',
                    sizeRatio: 2
                },
                TaskScheduleStatus: {
                    label: 'Schedule Status'
                },
                TaskSchduleDate: {
                    label: 'Scheduled Date/Time',
                    templateUrl: 'taskExecutionDate.html',
                    sizeRatio: 1.5
                },
                TaskCompletionStatus: {
                    label: 'Execution Status'
                },
                TaskScheduleExecutionStartDateTime: {
                    label: 'Started Date/Time',
                    templateUrl: 'taskExecutionDate.html',
                    sizeRatio: 1.5
                },
                TaskScheduleExecutionEndDateTime: {
                    label: 'Ended Date/Time',
                    templateUrl: 'taskExecutionDate.html',
                    sizeRatio: 1.5
                },
                OriginalRecordCount: {
                    label: '# Records',
                    sizeRatio: 0.75
                },
                Action: {
                    label: 'Action',
                    templateUrl: 'taskExecutionActions.html',
                    sizeRatio: 0.75
                }
            };

            $scope.taskScheduleAutoId = 0;
            if ($state.params && $state.params.TaskScheduleAutoId) {
                $scope.taskScheduleAutoId = $state.params.TaskScheduleAutoId;
            }

            $q.all([TaskExecutionLogService.getTaskList(_key), TaskExecutionLogService.getTaskStatusList(_key), TaskExecutionLogService.getTaskExecutionStatusList(_key), TaskExecutionLogService.getTaskScheduleList(_key, $scope.taskScheduleAutoId, $scope.currentSearchCriteria)])
            .then(function (data) {
                $scope.taskTypeList = [{TaskTypeCode: 'All', TaskTypeName: 'All'}].concat(data[0].ReferenceEntities);
                
                $scope.taskStatusList = data[1].ReferenceEntities;
                
                $scope.taskExecutionStatusList = [{TaskExecutionStatusId: 0, TaskExecutionStatusName: 'All'}].concat(data[2].ReferenceEntities);
                
                $scope.taskExecutionList = data[3].TaskScheduleExecutionList;
                $scope.paginationParams.totalRecords = data[3].RecordCount;
                
                $scope.loadingTasks(false);
            });
        };

        $scope.showHideSearch = function () {
            $scope.showSearchSection = !$scope.showSearchSection;
        };

        $scope.searchClicked = function () {
            $scope.searchCriteria.FromRowNum =  0;
            $scope.currentSearchCriteria = angular.copy($scope.searchCriteria);

            searchTaskExecutionLog();
        };

        $scope.clearClicked = function () {
            resetSearchCriteria();

            $scope.searchClicked();
        };

        $scope.executeTask = function (task) {
            try {
                if(task.TaskTypeId == 27 || task.TaskTypeId == 28)
                {
                    task.isFirm = true,
                    task.EntityType = 'P';
                }
                $scope.loadingTasks(true, 'Executing Task...');

                TaskExecutionLogService.executeTask(_key, task)
                .then(function (data) {
                    if (data.Status) {

                    } else {

                    }

                    $scope.loadingTasks(false); 

                    searchTaskExecutionLog();
                }, function (error) {
                    $scope.loadingTasks(false);
                })
            } catch (ex) {
                $scope.loadingTasks(false);
            }
        };

        $scope.pageChanged = function (newPage) {
            $scope.currentSearchCriteria.FromRowNum = (newPage - 1) * $scope.searchCriteria.RecordToFetch;

            searchTaskExecutionLog();
        };

        $scope.sortBy = function(sortParams) {
            $scope.currentSearchCriteria.Order = (sortParams.$sortDirection == 'asc') ? 1 : 0;
            $scope.currentSearchCriteria.OrderBy = sortParams.$headerProperty;

            searchTaskExecutionLog();
        };
    }
})();