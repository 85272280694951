(function () {
    'use strict';

    //In order to configure further Qs for this listing controller, you must first alter application-listing.server-config.json and
    //then in the Renewal_By_Search_WithPager, in the "IF QueuePage <> '' THEN...END IF;" block, you have to add the configured queue's 
    //"Name" property to the ELSEIF list.

    angular
        .module('app.InitialApplication')
        .controller("ApplicationListingController", ApplicationListingController);

    ApplicationListingController.$inject = ['$scope', '$stateParams', '$rootScope', 'utilityService', '$q', '$uibModal',
        'licenseRenewalService', 'BackofficeFirmRenewalListService', '$state', 'initialApplicationstatusService', 'warningService', 'initialLicenseApplicationService',
        'licenseApplicationListingService', 'applicationListingService', 'ApplicationSettings', 'Messages',
        'LicenseRenewalHASnAService'
    ];

    function ApplicationListingController($scope, $stateParams, $rootScope, utilityService, $q, $uibModal,
        licenseRenewalService, BackofficeFirmRenewalListService, $state, initialApplicationstatusService, warningService, initialLicenseApplicationService,
        licenseApplicationListingService, applicationListingService, ApplicationSettings, Messages, LicenseRenewalHASnAService) {

        var _key = sessionStorage.Key;
        var _userId = sessionStorage.UserID;

        $scope.maskConfig = {
            clearOnBlur: false,
            allowInvalidValue: true,
            clearOnBlurPlaceholder: false
        };

        $scope.current = {
            TemplateUrl: null,
            Controller: null,
            person: null,
            application: null,
            allowSearching: true,
            isAllSelected: false,
            allowExportToExcel: true
        };

        $scope.cleanAuditForm = function () {
            $scope.searchAuditData = {
                Percentage: 5,
                minPercentage: 0,
                maxLimit: 0,
                numberOfDays: 0,
                AuditDateFrom: '',
                AuditDateTo: '',
                AuditFinalized: false,
            };
            $scope.AuditDateFromDatePicker = {
                dateOpened: false,
                format: 'M!/d!/yyyy',
                dateOptions: {
                    formatYear: 'yy',
                    startingDay: 1
                },
                fromDateOpened: false,
                toDateOpened: false
            };

            $scope.AuditDateToDatePicker = {
                dateOpened: false,
                format: 'M!/d!/yyyy',
                dateOptions: {
                    formatYear: 'yy',
                    startingDay: 1
                },
                fromDateOpened: false,
                toDateOpened: false
            };

            $scope.applicationList = [];
            if (!!$scope.paginationParams) {
                $scope.paginationParams.totalRecords = 0;
                $scope.paginationParams.pageSize = 0;
            }
            $scope.auditStatusList = [];
            $scope.searchAuditStatusList = [
                {
                    AuditStatusId: 1 //"AC"
                    , IsActive: 1
                    , IsDeleted: 0
                    , Name: "Audit Complete"
                    , SortOrder: 1
                },
                {
                    AuditStatusId: 2 // "AI"
                    , IsActive: 1
                    , IsDeleted: 0
                    , Name: "Audit Incomplete"
                    , SortOrder: 2
                },
                {
                    AuditStatusId: 3 // "ER"
                    , IsActive: 1
                    , IsDeleted: 0
                    , Name: "Audit Enforcement"
                    , SortOrder: 3
                },
            ];

            $scope.auditFromDateDatePicker = {
                dateOpened: false,
                format: 'M!/d!/yyyy',
                dateOptions: {
                    formatYear: 'yy',
                    startingDay: 1
                },
                fromDateOpened: false,
                toDateOpened: false
            };

            $scope.auditToDateDatePicker = {
                dateOpened: false,
                format: 'M!/d!/yyyy',
                dateOptions: {
                    formatYear: 'yy',
                    startingDay: 1
                },
                fromDateOpened: false,
                toDateOpened: false
            };

        }
        $scope.cleanAuditForm();
        $scope.countAuditDays = function () {
            let fromDate = Date.now();
            let toDate = Date.now();
            let countDays = 0;
            if ($scope.searchAuditData && $scope.searchAuditData.AuditDateFrom) {
                fromDate = new Date(Date.parse($scope.searchAuditData.AuditDateFrom));
                fromDate.setHours(0, 0, 0, 0);
                countDays = 1;
            }
            if ($scope.searchAuditData && $scope.searchAuditData.AuditDateTo) {
                toDate = new Date(Date.parse($scope.searchAuditData.AuditDateTo));
                toDate.setHours(0, 0, 0, 0);

                countDays += countDays == 0 ? 1 : Math.abs((toDate - fromDate) / (1000 * 3600 * 24));

                countDays = Math.round(countDays);
            }
            $scope.searchAuditData.numberOfDays = countDays;
        };

        $scope.CheckPercentage = function () {
            let p = $scope.searchAuditData.Percentage;
            if (p == undefined || p < $scope.searchAuditData.minPercentage) {
                $scope.searchAuditData.Percentage = $scope.searchAuditData.minPercentage;
            }
            else if (p > 100) {
                $scope.searchAuditData.Percentage = 100;
            }
        }

        $scope.CheckMaxLimit = function () {
            let m = $scope.searchAuditData.maxLimit;
            if (m == undefined || m < 0) {
                $scope.searchAuditData.maxLimit = 0;
            }
        }

        //BEGIN METHODS TO BE OVERWRITTEN IN LOGIC
        $scope.startAppCreationProcess = function () { };
        $scope.hideTemplateSections = function () { };
        //END METHODS TO BE OVERWRITTEN IN LOGIC

        //BEGIN INITIALIZATION AND APPLICATION SEARCH
        var getQueueConfig = function () {
            return $scope.config.Queues[$stateParams.queueType];
        };

        var applyConfiguration = function () {
            $scope.searchApplicationStatusList = angular.copy($scope.applicationStatusList);
            $scope.searchApplicationInternalStatusList = angular.copy($scope.applicationInternalStatusList);
            $scope.searchApplicationTypeList = angular.copy($scope.applicationTypeList);
            $scope.searchApplicationSubTypeList = angular.copy($scope.applicationSubTypeList);

            //Filter application statuses
            if (!!$scope.queueConfig && !!$scope.queueConfig.SearchRestrictions) {
                if (!!$scope.queueConfig.SearchRestrictions.ApplicationStatusIds && !!$scope.queueConfig.SearchRestrictions.ApplicationStatusIds.value && !!$scope.applicationStatusList) {
                    $scope.searchApplicationStatusList = $scope.applicationStatusList.joinObjects($scope.queueConfig.SearchRestrictions.ApplicationStatusIds.value, 'item1.ApplicationStatusId==item2', 'this1');
                }

                if (!!$scope.queueConfig.SearchRestrictions.ApplicationInternalStatusIds && !!$scope.queueConfig.SearchRestrictions.ApplicationInternalStatusIds.value && !!$scope.applicationInternalStatusList) {
                    $scope.searchApplicationInternalStatusList = $scope.applicationInternalStatusList.joinObjects($scope.queueConfig.SearchRestrictions.ApplicationInternalStatusIds.value, 'item1.ApplicationInternalStatusId==item2', 'this1');
                }

                if (!!$scope.queueConfig.SearchRestrictions.ApplicationTypeIds && !!$scope.queueConfig.SearchRestrictions.ApplicationTypeIds.value && !!$scope.applicationTypeList) {
                    $scope.searchApplicationTypeList = $scope.applicationTypeList.joinObjects($scope.queueConfig.SearchRestrictions.ApplicationTypeIds.value, 'item1.ApplicationTypeId==item2', 'this1');
                }

                if (!!$scope.queueConfig.SearchRestrictions.ApplicationSubTypeIds && !!$scope.queueConfig.SearchRestrictions.ApplicationSubTypeIds.value && !!$scope.applicationSubTypeList) {
                    $scope.searchApplicationSubTypeList = $scope.applicationSubTypeList.joinObjects($scope.queueConfig.SearchRestrictions.ApplicationSubTypeIds.value, 'item1.ApplicationSubTypeId==item2', 'this1');
                }
            }

            //Filter add application types
            if (!!$scope.queueConfig.AddAppConfig) {
                if (!!$scope.queueConfig.AddAppConfig.ApplicationTypeIds) {
                    $scope.addApplicationTypes = $scope.addApplicationTypes.joinObjects($scope.queueConfig.AddAppConfig.ApplicationTypeIds, 'item1.ApplicationTypeId==item2', 'this1');
                }
            }
        };

        $scope.processRouteData = function () {
            if (!!$scope.queueConfig && $scope.queueConfig.RouteData) {
                $state.current.routeData = $scope.queueConfig.RouteData;
                $rootScope.routeData = $state.current.routeData;
                $scope.routeData = $state.current.routeData;
            }
        };

        $scope.getConfig = function (refPromises) {
            var promises = [
                utilityService.getConfigData('/components/backoffice/application/application-listing/application-listing.server-config.json')
            ].concat(refPromises);
            $q.all(promises)
                .then(function (responses) {
                    $scope.config = responses[0];

                    $scope.queueConfig = getQueueConfig();

                    $scope.processRouteData();
                    $scope.isRouteDataLoaded = true;
                    $scope.processDeficiencyReason();
                    applyConfiguration();

                    $scope.loadSearchDefaults();
                    $scope.AssignToId = null;
                    $scope.isConfigLoaded = true;
                    $scope.isSearchCollapsed = $scope.routeData.queueType == "AdminQ";
                    if ($scope.$root.routeData.queueType != 'AdminQ') {
                        $scope.searchForApplications(1);
                    }
                    else {
                        $scope.operationPending(false);
                    }
                }, function (err) {
                    $scope.operationPending(false);
                    console.log(err);
                    $scope.showStatusMsg('-', 'An error occurred retrieving the configuration');
                });
        };

        var filterAppsFromQueueConfig = function () {
            if (!!$scope.queueConfig && !!$scope.queueConfig.SearchRestrictions) {
                //Filter application types out
                if (!!$scope.queueConfig.SearchRestrictions.ApplicationTypeIds && !!$scope.queueConfig.SearchRestrictions.ApplicationTypeIds.value) {
                    $scope.applicationList = $scope.applicationList.where(function (app) {
                        return $scope.queueConfig.SearchRestrictions.ApplicationTypeIds.value.includes(app.ApplicationTypeId);
                    });
                }

                //Filter application statuses out
                if (!!$scope.queueConfig.SearchRestrictions.ApplicationStatusIds && !!$scope.queueConfig.SearchRestrictions.ApplicationStatusIds.value) {
                    $scope.applicationList = $scope.applicationList.where(function (app) {
                        return $scope.queueConfig.SearchRestrictions.ApplicationStatusIds.value.includes(app.ApplicationStatusId);
                    });
                }
            }
        };

        $scope.$watch(function () {
            return '' + $scope.ApplicationId + '' + $scope.IndividualId + '' + $scope.ApplicationTypeId;
        }, function () {
            $scope.tableMethods.IndividualId = $scope.IndividualId;
            $scope.tableMethods.ApplicationId = $scope.ApplicationId;
            $scope.tableMethods.ApplicationTypeId = $scope.ApplicationTypeId;
        });

        $scope.$watch('searchData', function (newVal) {
            if (!!$scope.hideStatusMsg) {
                $scope.hideStatusMsg();
            }
        }, true);

        $scope.usersGetAllByRole = function () {
            licenseRenewalService.usersGetAllByRole(sessionStorage.Key, { RoleName: 'Approver' })
                .then(function (response) {
                    if (response.Status) {
                        $scope.staffUserList = response.UsersInRole;
                        $scope.staffUserList.map(function (staffUserItem) {
                            staffUserItem.Name = (!!staffUserItem.FirstName ? staffUserItem.FirstName : '') + ' ' + (!!staffUserItem.LastName ? staffUserItem.LastName : '');
                        })
                    }
                }, function (data) {
                    $scope.showStatusMsg('-', data);
                });
        };

        $scope.init = function () {
            $scope.sortColumn = 'SubmittedDate';
            $scope.sortOrder = 'DESC';
            $scope.pageSize = 10;
            $scope.pagination = {
                currentPage: 1,
                maxSize: 5,
                totalItems: 10
            };
            $scope.userId = _userId;
            $scope.current = {
                TemplateUrl: null,
                Controller: null,
                person: null,
                application: null,
                allowSearching: true,
                isAllSelected: false
            };
            $scope.listConfig = {};
            $scope.uiLogic = {};

            $scope.isBackoffice = sessionStorage.isBackOfficeUser == 'true' || sessionStorage.isBackOfficeUser == true;
            $scope.isBackoffice = true;
            $scope.showApplicationListingSection();

            $scope.paginationParams = {
                pageSize: 10,
                pageChanged: $scope.pageChanged
            };

            $scope.usersGetAllByRole();

            $scope.openBcCheck = function (renewalItem) {
                if (!!$scope.current.application) {
                    $scope.closeApplication($scope.current.application);
                }
                resetApplicationListing();
                $scope.isAssignTo = false;
                $scope.DisplayAddApplication = false;
                $scope.displayAddApplication = false;
                $scope.currentRenewalItem = renewalItem;

                $scope.isSomeRowDetailsOpen = true;


                $scope.ApplicationId = renewalItem.ApplicationId;
                $scope.IndividualId = renewalItem.IndividualId;
                $scope.ApplicationTypeId = renewalItem.ApplicationTypeId;
                $scope.isBackoffice = true;
                renewalItem.showInternalForm = true;

                //$scope.setupTemplateController();
                $scope.current.allowSearching = false;
                $scope.current.application = renewalItem;
                renewalItem.ShowBcCheck = true;

                $scope.current.TemplateUrl = "app/components/individual/license/renewal/background-check/background-check.html";
                $scope.current.Controller = "BackgroundQueueCheckController";
            };

            $scope.tableMethods = {
                getAppStatusButtonText: $scope.getAppStatusButtonText,
                openExistingApplication: $scope.openExistingApplication,
                openLicenseOutputForm: $scope.openLicenseOutputForm,
                openEmailDeficiency: $scope.openEmailDeficiency,
                closeApplication: $scope.closeApplication,
                closeDeficiencyEmailForm: $scope.closeDeficiencyEmailForm,
                updateApplicationDefeciency: $scope.updateApplicationDefeciency,
                showAppSearchPanel: $scope.showAppSearchPanel,
                pageSizeChanged: $scope.pageSizeChanged,
                getAppStatusButton: $scope.getAppStatusButton,
                deleteApplication: $scope.deleteApplication,
                getApplicationLicense: $scope.getApplicationLicense,
                openBcCheck: $scope.openBcCheck,

                current: $scope.current,
                listConfig: $scope.listConfig,
                userId: $scope.userId
            };

            var resetApplicationListing = function () {
                if (!!$scope.applicationList) {
                    angular.forEach($scope.applicationList, function (renewalItem, key) {
                        renewalItem.ShowInternalForm = false;
                        renewalItem.ShowEmailForm = false;
                        renewalItem.ShowBcCheck = false;
                        renewalItem.ShowLicenseOutputForm = false;
                    });
                }
                $scope.isSomeRowDetailsOpen = false;
            }
        };

        $scope.deleteApplication = function (application) {
            closeOpenApplication();

            if (confirm(Messages.ddceade)) {
                $scope.operationPending(true, 'Deleting...');
                initialApplicationstatusService.deleteApplication(sessionStorage.Key, application.ApplicationId)
                    .then(function (data) {
                        $scope.searchForApplications($scope.paginationParams.currentPage);
                    }, function (err) {
                        $scope.operationPending(false);
                    });
            }
        };

        $scope.pageSizeChanged = function () {
            $scope.searchForApplications($scope.paginationParams.currentPage);
        };

        $scope.headerClicked = function (sortProp, headerProperty) {
            if (sortProp != $scope.sortColumn) {
                headerProperty.sortDirection = null;
            }
            $scope.sortColumn = sortProp;
            if (!headerProperty.sortDirection || headerProperty.sortDirection == 'desc') {
                headerProperty.sortDirection = 'asc';
            } else if (headerProperty.sortDirection == 'asc') {
                headerProperty.sortDirection = 'desc';
            }

            $scope.sortOrder = headerProperty.sortDirection;
            $scope.searchForApplications($scope.paginationParams.currentPage);
        };

        $scope.pageChanged = function () {
            $scope.searchForApplications($scope.paginationParams.currentPage);
        };

        $scope.applySearchRestrictions = function (searchDataItem) {
            if (!!$scope.queueConfig.SearchRestrictions) {
                for (var searchKey in $scope.queueConfig.SearchRestrictions) {
                    if (!!$scope.queueConfig.SearchRestrictions[searchKey] && !!$scope.queueConfig.SearchRestrictions[searchKey].applyOnSearch && !searchDataItem[searchKey]) {
                        if (Array.isArray($scope.queueConfig.SearchRestrictions[searchKey].value)) {
                            searchDataItem[searchKey] = $scope.queueConfig.SearchRestrictions[searchKey].value.join(',');
                        } else {
                            searchDataItem[searchKey] = $scope.queueConfig.SearchRestrictions[searchKey].value;
                        }
                    }
                }
            }
        };
        function downloadXLS(xlsRows) {
            var createXLSLFormatObj = [];

            /* XLS Head Columns */
            var xlsHeader = Object.getOwnPropertyNames($scope.queueConfig.tableHeaders);

            /* XLS Rows Data */
            var xlsHeaderTitle = xlsHeader.map(h => $scope.queueConfig.tableHeaders[h].label);

            xlsHeaderTitle.push("payment");
            xlsHeaderTitle.push("deficiency");
            xlsHeaderTitle.push("Convicted");
            xlsHeaderTitle.push("FP");
            xlsHeaderTitle.push("PR");
            xlsHeaderTitle.push("Military");
            xlsHeaderTitle.push("CE");
            xlsHeaderTitle.push("Audit Status");

            createXLSLFormatObj.push(xlsHeaderTitle);
            $.each(xlsRows, function (index, value) {
                var innerRowData = [];

                $.each(xlsHeader, function (ind, attr) {
                    if (attr == "Actions") {
                        let payment = value.InvoiceStatus != 'CLOSED' ? "not paid" : "paid";
                        let deficiency = ((value.ApplicationStatusId != 3 && value.DeficiencyStatusId == 0 || value.BackgroundDeficiencyStatusId == 0) ? 'Enforcement Referral' :
                            ((value.ApplicationStatusId != 3 && !value.DeficiencyStatusId && !value.BackgroundDeficiencyStatusId) ? 'Not Deficient' :
                                ((value.ApplicationStatusId != 3 && value.DeficiencyStatusId == 1 || value.BackgroundDeficiencyStatusId == 1) ? 'Applicant Deficiencies' :
                                    ((value.ApplicationStatusId != 3 && value.DeficiencyStatusId == 2 || value.BackgroundDeficiencyStatusId == 2) ? 'Staff Deficiencies' :
                                        ((value.ApplicationStatusId != 3 && value.DeficiencyStatusId == 3 || value.BackgroundDeficiencyStatusId == 3) ? 'Deficiency Review Pending' :
                                            (((value.ApplicationStatusId == 3 && !(!value.DeficiencyStatusId && !value.BackgroundDeficiencyStatusId)) || value.DeficiencyStatusId == 4 || value.BackgroundDeficiencyStatusId == 4) ? 'Deficiency Resolved' : ''
                                            ))))));

                        let Convicted = ((value.IsConvicted >= 1 && value.ApplicationStatusId != 46 && value.ApplicationStatusId != 3) ? 'Conviction Applicant' :
                            ((value.IsConvicted >= 1 && value.ApplicationStatusId == 46) ? 'Conviction Pending' :
                                ((value.IsConvicted >= 1 && value.ApplicationStatusId == 3) ? 'Conviction Resolved' :
                                    ''
                                )));

                        let FP = ((value.FPIndividualDefeciencyDtlId != null && value.IsFPDefeciencyResolved == false) ? 'Fingerprint required' :
                            ((value.FPIndividualDefeciencyDtlId != null && value.IsFPDefeciencyResolved == true) ? 'Fingerprint received' : ''
                            ));

                        let PR = ((value.ApplicationStatusId != 3 && value.IsPeerReview > 0 && value.PRDefeciencyCount > 0 && (value.PRDefeciencyCount > value.PRDefeciencyResolvedCount)) ? 'Peer Review missing/incomplete' :
                            (((value.IsPeerReview > 0 && value.ApplicationStatusId == 3) || (value.IsPeerReview > 0 && value.PRDefeciencyCount > 0 && (value.PRDefeciencyCount == value.PRDefeciencyResolvedCount))) ? 'Peer Review resolved' :
                                ((value.ApplicationStatusId != 3 && (value.IsPeerReview > 0 && !value.PRDefeciencyCount)) ? 'Peer Review Yes' : ''
                                )));

                        let Military = ((false && value.ApplicationStatusId != 3 && value.IsMilitary > 0 && (value.DeficiencyStatusId == 1 || value.DeficiencyStatusId == 2 || value.DeficiencyStatusId == 3)) ? 'Military missing/incomplete documents' :
                            (((value.IsMilitary > 0 && value.ApplicationStatusId == 3) || (value.IsMilitary > 0 && value.DeficiencyStatusId == 4)) ? 'Military resolved' :
                                ((value.ApplicationStatusId != 3 && value.IsMilitary > 0) ? 'Military Yes' : ''
                                )));

                        let CE = ((value.IsCE > 0 && value.ApplicationStatusId != 3 && value.CEDefeciencyCount > 0 && (value.CEDefeciencyCount > value.CEDefeciencyResolvedCount)) ? 'CE Defeciency Pending' :
                            ((value.IsCE > 0 && (value.ApplicationStatusId == 3 || (value.CEDefeciencyCount > 0 && (value.CEDefeciencyCount == value.CEDefeciencyResolvedCount)))) ? 'CE Defeciency Resolved' :
                                ((value.IsCE > 0 && value.ApplicationStatusId != 3 && !value.CEDefeciencyCount) ? 'Application did not pass CE validation rules.' : ''
                                )));

                        let AuditStatus = !value.IsSelectedForAudit ? "" : (
                            value.IsAuditApproved == null ? "Selected for Audit" : (
                                !!value.IsEnforcementAudit ? "Audit: Enforcement" : (
                                    "Audit: " +
                                    (value.IsAuditApproved ? "Complete - No Issues" : "Incomplete - Issues Found") + (!!value.Auditor.trim() ? " By " + value.Auditor : "")
                                )
                            )
                        );

                        innerRowData.push("");
                        innerRowData.push(payment);
                        innerRowData.push(deficiency);
                        innerRowData.push(Convicted);
                        innerRowData.push(FP);
                        innerRowData.push(PR);
                        innerRowData.push(Military);
                        innerRowData.push(CE);
                        innerRowData.push(AuditStatus);

                    }
                    else if (value.hasOwnProperty(attr)) {
                        if (attr.toLowerCase().indexOf("date") >= 0) {
                            let DateVal = value[attr] != null ? value[attr].split('T')[0] : "";
                            innerRowData.push(DateVal);
                        }
                        else {
                            innerRowData.push(value[attr]);
                        }
                    }
                    else {
                        innerRowData.push("");
                    }
                });
                createXLSLFormatObj.push(innerRowData);
            });

            let today = new Date();

            /* Sheet Name */
            var ws_name = today.toISOString().split('T')[0];
            /* File Name */
            var filename = "Data-" + ws_name.replaceAll("-", "") + ".xlsx";

            if (typeof console !== 'undefined') console.log(new Date());
            var wb = XLSX.utils.book_new(),
                ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj);

            /* Add worksheet to workbook */
            XLSX.utils.book_append_sheet(wb, ws, ws_name);

            /* Write workbook and Download */
            if (typeof console !== 'undefined') console.log(new Date());
            XLSX.writeFile(wb, filename);
            if (typeof console !== 'undefined') console.log(new Date());

        }

        $scope.getIndividualSearchData = function () {
            return licenseRenewalService.RenewalSearchWithPager(sessionStorage.Key, $scope.xlPageNumber, $scope.xlPageSize, $scope.sortColumn, $scope.sortOrder, $scope.xlShowAllRecords, $scope.xlSearchDataItem, $scope.queueConfig.Name, true);
        };

        $scope.getProviderSearchData = function () {
            return BackofficeFirmRenewalListService.ProviderRenewalSearchWithPager(sessionStorage.Key, 1, 1000, $scope.sortColumn, $scope.sortOrder, false, $scope.xlSearchDataItem,  $scope.queueConfig.Name);
        };

        $scope.processIndvList = function (IndvArr) {
            $scope.IndvArr = IndvArr.leftJoinObjects($scope.licenseSubTypeList, 'item1.LicenseApplicationForId==item2.ApplicationSubTypeId', 'this1, LicenseApplicationSubTypeName: item2.ApplicationSubTypeName');

            $scope.IndvArr.forEach(function (appItem) {
                appItem.TemplateUrl = $scope.config.ApplicationTypeConfigs[appItem.ApplicationTypeId].TemplateUrl;
                appItem.Controller = $scope.config.ApplicationTypeConfigs[appItem.ApplicationTypeId].Controller;
                appItem.LicenseTypeCode = appItem.LicenseTypeId == 1 ? 'CPA' : '';
                appItem.ExternalApplicationStatusName = $scope.applicationStatusList.whereEquals(appItem.ApplicationStatusId, 'ApplicationStatusId').select('ExternalApplicationStatusName').firstOrDefault();
                appItem.FingerprintStatus = warningService.getBackgroundStatus(appItem.DOJFingerPrintStatus, appItem.DOJApprovalStatus, appItem.FBIFingerPrintStatus, appItem.FBIApprovalStatus);
            });

            $scope.operationPending(false);

            return ($scope.IndvArr);
        }

        $scope.processPrvdList = function (PrvdArr) {
            
            $scope.PrvdArr = PrvdArr;

            $scope.PrvdArr.forEach(function (appItem) {
                appItem.LicenseTypeCode = appItem.LicenseTypeName == 'Corporation' ? 'COR' : 'PAR';

            });

            $scope.filterCriteria = null;

            if (!!$scope.queueConfig.Name || $scope.queueConfig.Name === "") {
                if($scope.queueConfig.Name === "IntakeQ")
                    $scope.filterCriteria = 8;
                    
                if($scope.queueConfig.Name === "ReviewQ")
                    $scope.filterCriteria = 42;

                if($scope.queueConfig.Name === "ComplianceReviewQ")
                    $scope.filterCriteria = 44;

                if($scope.queueConfig.Name === "DeficiencyQ")
                    $scope.filterCriteria = 43;
                
                if($scope.queueConfig.Name === "ComplianceReviewDeficiency")
                    $scope.filterCriteria = 45;
                
                if($scope.queueConfig.Name === "EnforcementReferralQ")
                    $scope.filterCriteria = 46;
                
                if($scope.queueConfig.Name === "ManagerQ")
                    $scope.filterCriteria = 28;
                

                if($scope.queueConfig.Name != "")
                {
                    $scope.PrvdArr = $scope.PrvdArr.filter(ap => ap.ApplicationStatusId === $scope.filterCriteria);
                }
                else{
                    $scope.PrvdArr = $scope.PrvdArr;
                }
                    
                $scope.PrvdArr.forEach(function (appItem) {

                    appItem.TemplateUrl = $scope.config.ApplicationTypeConfigs[appItem.ApplicationTypeId].TemplateUrl;
                    appItem.Controller = $scope.config.ApplicationTypeConfigs[appItem.ApplicationTypeId].Controller;
                    //appItem.LicenseTypeName = $scope.licenseTypes.whereEquals(appItem.LicenseTypeId, 'LicenseTypeId').select('LicenseTypeName').firstOrDefault();
                    appItem.ExternalApplicationStatusName = $scope.applicationStatusList.whereEquals(appItem.ApplicationStatusId, 'ApplicationStatusId').select('ExternalApplicationStatusName').firstOrDefault();
                    //appItem.FingerprintStatus = warningService.getBackgroundStatus(appItem.DOJFingerPrintStatus, appItem.DOJApprovalStatus, appItem.FBIFingerPrintStatus, appItem.FBIApprovalStatus);
                });
                
                if($scope.queueConfig.Name != "")
                {
                    $scope.PrvdArr = $scope.PrvdArr.filter(ap => ap.ApplicationStatusId === $scope.filterCriteria);
                }
                else{
                    $scope.PrvdArr = $scope.PrvdArr;
                }
                $scope.PrvdArr.forEach(function (appItem) {

                    appItem.TemplateUrl = $scope.config.ApplicationTypeConfigs[appItem.ApplicationTypeId].TemplateUrl;
                    appItem.Controller = $scope.config.ApplicationTypeConfigs[appItem.ApplicationTypeId].Controller;
                    //appItem.LicenseTypeName = $scope.licenseTypes.whereEquals(appItem.LicenseTypeId, 'LicenseTypeId').select('LicenseTypeName').firstOrDefault();
                    appItem.ExternalApplicationStatusName = $scope.applicationStatusList.whereEquals(appItem.ApplicationStatusId, 'ApplicationStatusId').select('ExternalApplicationStatusName').firstOrDefault();
                    //appItem.FingerprintStatus = warningService.getBackgroundStatus(appItem.DOJFingerPrintStatus, appItem.DOJApprovalStatus, appItem.FBIFingerPrintStatus, appItem.FBIApprovalStatus);
                });
                
                $scope.operationPending(false);
            }

            return($scope.PrvdArr);
        }

        $scope.getExcelForDataReport = function () {
            try {
                $scope.xlShowAllRecords = true;
                $scope.xlPageNumber = 0;
                $scope.xlPageSize = 0;

                $scope.hideStatusMsg();
                $scope.operationPending(true, 'Getting data...');

                $scope.xlSearchDataItem = angular.copy($scope.searchData);

                $scope.xlSearchDataItem.IsSearchForRenewal = false;
                $scope.xlSearchDataItem.AssignToUserId = sessionStorage.UserID;
                $scope.xlSearchDataItem.AssignToUserIds = $scope.xlSearchDataItem.AssignedToIds;

                $scope.applySearchRestrictions($scope.xlSearchDataItem);

                var dataPromises = [];
                dataPromises.push($scope.getIndividualSearchData());
                dataPromises.push($scope.getProviderSearchData());
                $q.all(dataPromises).then(function (response) {
                    console.log('res', response);
                    if (response[0].data.RenewalApplicationList != null && response[1].data.ProviderRenewalApplicationList != null) {
                        
                        $scope.applicationIndividualListXls = $scope.processIndvList(response[0].data.RenewalApplicationList);
                        $scope.applicationProvidervListXls = $scope.processPrvdList(response[1].data.ProviderRenewalApplicationList);
                        $scope.applicationListXls = $scope.applicationIndividualListXls.concat($scope.applicationProvidervListXls);
                        downloadXLS($scope.applicationListXls);
                        $scope.applicationListXls = [];
                        // console.log('1', response[0].data.RenewalApplicationList);
                        // console.log('list1', response[0].data.RenewalApplicationList);
                        // console.log('2', response[1].data.ProviderRenewalApplicationList);
                        // console.log('list2', response[1].data.ProviderRenewalApplicationList);
                        
                    }
                    else if (response[0].data.RenewalApplicationList != null && response[1].data.ProviderRenewalApplicationList == null) {
                    
                        $scope.applicationListXls = $scope.processIndvList(response[0].data.RenewalApplicationList);
                        downloadXLS($scope.applicationListXls);
                        $scope.applicationListXls = [];
                    }   
                    else if (response[1].data.ProviderRenewalApplicationList != null && response[0].data.RenewalApplicationList == null) {
                        $scope.applicationListXls = $scope.processPrvdList(response[1].data.ProviderRenewalApplicationList);
                        downloadXLS($scope.applicationListXls);
                        $scope.applicationListXls = [];
                    }

                    else {
                        $scope.operationPending(false);
                    }

                }, function (err) {
                    HideLoader();
                    err = err.data;
                        $scope.operationPending(false);
                        console.log(err);
                        $scope.showStatusMsg('-', 'An error occurred searching for the applications');
                });


                // licenseRenewalService.RenewalSearchWithPager(sessionStorage.Key, pageNumber, pageSize, $scope.sortColumn, $scope.sortOrder, !!showAllRecords, searchDataItem, $scope.queueConfig.Name, true)
                //     .then(function (response) {
                //         response = response.data;
                //         if (response.Status) {
                //             $scope.applicationListXls = response.RenewalApplicationList;

                //             $scope.applicationListXls = $scope.applicationListXls.leftJoinObjects($scope.licenseSubTypeList, 'item1.LicenseApplicationForId==item2.ApplicationSubTypeId', 'this1, LicenseApplicationSubTypeName: item2.ApplicationSubTypeName');

                //             $scope.applicationListXls.forEach(function (appItem) {
                //                 appItem.TemplateUrl = $scope.config.ApplicationTypeConfigs[appItem.ApplicationTypeId].TemplateUrl;
                //                 appItem.Controller = $scope.config.ApplicationTypeConfigs[appItem.ApplicationTypeId].Controller;
                //                 appItem.LicenseTypeCode = appItem.LicenseTypeId == 1 ? 'CPA' : '';
                //                 appItem.ExternalApplicationStatusName = $scope.applicationStatusList.whereEquals(appItem.ApplicationStatusId, 'ApplicationStatusId').select('ExternalApplicationStatusName').firstOrDefault();
                //                 appItem.FingerprintStatus = warningService.getBackgroundStatus(appItem.DOJFingerPrintStatus, appItem.DOJApprovalStatus, appItem.FBIFingerPrintStatus, appItem.FBIApprovalStatus);
                //             });

                //             $scope.operationPending(false);

                //             downloadXLS($scope.applicationListXls);
                //             $scope.applicationListXls = [];
                //         } else {
                //             $scope.applicationListXls = [];

                //             $scope.operationPending(false);
                //             // $scope.showStatusMsg('-', response.Message);
                //         }
                //     }, function (err) {
                //         err = err.data;
                //         $scope.operationPending(false);
                //         console.log(err);
                //         $scope.showStatusMsg('-', 'An error occurred searching for the applications');
                //     });
            } catch (ex) {
                $scope.operationPending(false);
                console.log(ex);
                $scope.showStatusMsg('-', 'An error occurred searching for the applications');
            }
        };

        $scope.searchForApplications = function (pageNumber, showAllRecords) {
            try {
                if (!$scope.current.allowSearching) {
                    return;
                }

                $scope.hideStatusMsg();
                $scope.operationPending(true, 'Searching...');

                $scope.paginationParams.currentPage = pageNumber;
                $scope.paginationParams.ignoreDataLength = true;
                $scope.searchData.IsSearchForRenewal = false;
                $scope.searchData.AssignToUserId = sessionStorage.UserID;

                var searchDataItem = angular.copy($scope.searchData);
                $scope.applySearchRestrictions(searchDataItem);
                    
                //$scope.searchData.FirstName = "Race";
                $scope.current.isAllSelected = false;
                var noOfLicSelected = 0;
                if(!$scope.searchData || !$scope.searchData.LicenseTypeIds )
                {
                    noOfLicSelected = 3;
                }
                else{
                    var LicIds = $scope.searchData.LicenseTypeIds;
                    if($scope.searchData.LicenseTypeIds != "")
                    {
                        noOfLicSelected = LicIds.split(',').length;
                    }
                }

                searchDataItem.AssignToUserIds = searchDataItem.AssignedToIds;
                if (!!$scope.searchData.LicenseTypeIds && ($scope.searchData.LicenseTypeIds.includes(2) || $scope.searchData.LicenseTypeIds.includes(3)) && noOfLicSelected < 3) {
                    searchDataItem.ProviderName = searchDataItem.Name;
                    // Firm - Selected
                    BackofficeFirmRenewalListService.ProviderRenewalSearchWithPager(sessionStorage.Key, $scope.paginationParams.currentPage, $scope.paginationParams.pageSize, $scope.sortColumn, $scope.sortOrder, !!showAllRecords, searchDataItem,  $scope.queueConfig.Name)
                        .then(function (response) {

                            response = response.data;
                            if (response.Status) {
                                $scope.paginationParams.totalRecords = response.Total_Record;

                                $scope.applicationListUnfiltered = response.ProviderRenewalApplicationList;

                                $scope.filterCriteria = null;

                                $scope.applicationListUnfiltered.forEach(function (appItem) {
                                    appItem.LicenseTypeCode = appItem.LicenseTypeName == 'Corporation' ? 'COR' : 'PAR';

                                });

                                if (!!$scope.queueConfig.Name) {
                                    if($scope.queueConfig.Name === "IntakeQ")
                                        $scope.filterCriteria = 8;
                                        
                                    if($scope.queueConfig.Name === "ReviewQ")
                                        $scope.filterCriteria = 42;

                                    if($scope.queueConfig.Name === "ComplianceReviewQ")
                                        $scope.filterCriteria = 44;

                                    if($scope.queueConfig.Name === "DeficiencyQ")
                                        $scope.filterCriteria = 43;
                                    
                                    if($scope.queueConfig.Name === "ComplianceReviewDeficiency")
                                        $scope.filterCriteria = 45;
                                    
                                    if($scope.queueConfig.Name === "EnforcementReferralQ")
                                        $scope.filterCriteria = 46;
                                    
                                    if($scope.queueConfig.Name === "ManagerQ")
                                        $scope.filterCriteria = 28;

                                    $scope.applicationList = $scope.applicationListUnfiltered.filter(ap => ap.ApplicationStatusId === $scope.filterCriteria)

                                    if ($scope.applicationList.length === 0) {
                                        $scope.paginationParams.totalRecords = 0;
                                        $scope.applicationList = [];
                                        $scope.operationPending(false);
                                    }
                                    $scope.applicationList.forEach(function (appItem) {

                                        appItem.TemplateUrl = $scope.config.ApplicationTypeConfigs[appItem.ApplicationTypeId].TemplateUrl;
                                        appItem.Controller = $scope.config.ApplicationTypeConfigs[appItem.ApplicationTypeId].Controller;
                                        //appItem.LicenseTypeName = $scope.licenseTypes.whereEquals(appItem.LicenseTypeId, 'LicenseTypeId').select('LicenseTypeName').firstOrDefault();
                                        appItem.ExternalApplicationStatusName = $scope.applicationStatusList.whereEquals(appItem.ApplicationStatusId, 'ApplicationStatusId').select('ExternalApplicationStatusName').firstOrDefault();
                                        //appItem.FingerprintStatus = warningService.getBackgroundStatus(appItem.DOJFingerPrintStatus, appItem.DOJApprovalStatus, appItem.FBIFingerPrintStatus, appItem.FBIApprovalStatus);
                                    });
                                    $scope.operationPending(false);
                                }
                                else {
                                    if($scope.queueConfig.Name === "")
                                    {
                                        $scope.applicationList = $scope.applicationListUnfiltered;
                                    }
                                    else{
                                        $scope.paginationParams.totalRecords = 0;
                                    $scope.applicationList = [];
                                    $scope.operationPending(false);
                                    }
                                    $scope.operationPending(false);
                                }
                                //TestingThis
                            }
                            else{
                                $scope.paginationParams.totalRecords = 0;
                                $scope.applicationList = [];
                                $scope.operationPending(false);
                            }
                        }, function (res) {
                            HideLoader();
                        });
                }
                else if(!!$scope.searchData.LicenseTypeIds && $scope.searchData.LicenseTypeIds.includes(1) && noOfLicSelected < 3){
                    // CPA - Selected
                    licenseRenewalService.RenewalSearchWithPager(sessionStorage.Key, $scope.paginationParams.currentPage, $scope.paginationParams.pageSize, $scope.sortColumn, $scope.sortOrder, !!showAllRecords, searchDataItem, $scope.queueConfig.Name, true)
                        .then(function (response) {
                            response = response.data;
                            if (response.Status) {
                                $scope.paginationParams.totalRecords = response.Total_Recard;

                                $scope.applicationList = response.RenewalApplicationList;

                                if (!!$scope.gettingAudtiList && response.Total_Recard > 0 && !!$scope.applicationList && $scope.applicationList.length > 0) {
                                    // $scope.searchAuditData   Percentage      maxLimit
                                    let ToAuditDataSource = $scope.applicationList.where(a => a.DeficientMarkedDate == null && (a.LicenseStatusTypeId != 9 && a.LicenseStatusTypeId != 10 && a.LicenseStatusTypeId != 4));

                                    let auditCount = $scope.searchAuditData.maxLimit;
                                    if ($scope.searchAuditData.Percentage > 0) {
                                        auditCount = $scope.paginationParams.totalRecords * $scope.searchAuditData.Percentage / 100.0;
                                        auditCount = Math.ceil(auditCount);
                                    }

                                    if ($scope.searchAuditData.maxLimit > 0) {
                                        auditCount = Math.min(auditCount, $scope.searchAuditData.maxLimit);
                                    }

                                    let applicationListForAudit = ToAuditDataSource.filter(a => a.IsSelectedForAudit);
                                    applicationListForAudit = applicationListForAudit ?? [];
                                    ToAuditDataSource = ToAuditDataSource.filter(a => !a.IsSelectedForAudit);

                                    for (let i = applicationListForAudit.length; i < auditCount && ToAuditDataSource.length > 0; i++) {
                                        let randomElemIndx = Math.random() * ToAuditDataSource.length;
                                        randomElemIndx = Math.round(randomElemIndx);
                                        randomElemIndx = (randomElemIndx >= ToAuditDataSource.length) ? ToAuditDataSource.length - 1 : randomElemIndx;
                                        randomElemIndx = (randomElemIndx < 0) ? 0 : randomElemIndx;

                                        let movedItem = ToAuditDataSource.splice(randomElemIndx, 1);
                                        applicationListForAudit.push(movedItem[0]);
                                    }

                                    $scope.applicationList = applicationListForAudit;
                                    $scope.paginationParams.totalRecords = applicationListForAudit.length;
                                    $scope.paginationParams.pageSize = applicationListForAudit.length;
                                    $scope.gettingAudtiList = false;
                                    $scope.current.allowSearching = false;
                                }


                                $scope.applicationList = $scope.applicationList.leftJoinObjects($scope.licenseSubTypeList, 'item1.LicenseApplicationForId==item2.ApplicationSubTypeId', 'this1, LicenseApplicationSubTypeName: item2.ApplicationSubTypeName');

                                $scope.applicationList.forEach(function (appItem) {

                                    appItem.TemplateUrl = $scope.config.ApplicationTypeConfigs[appItem.ApplicationTypeId].TemplateUrl;
                                    appItem.Controller = $scope.config.ApplicationTypeConfigs[appItem.ApplicationTypeId].Controller;
                                    appItem.LicenseTypeCode = appItem.LicenseTypeId == 1 ? 'CPA' : '';
                                    //appItem.LicenseTypeName = $scope.licenseTypes.whereEquals(appItem.LicenseTypeId, 'LicenseTypeId').select('LicenseTypeName').firstOrDefault();
                                    appItem.ExternalApplicationStatusName = $scope.applicationStatusList.whereEquals(appItem.ApplicationStatusId, 'ApplicationStatusId').select('ExternalApplicationStatusName').firstOrDefault();
                                    appItem.FingerprintStatus = warningService.getBackgroundStatus(appItem.DOJFingerPrintStatus, appItem.DOJApprovalStatus, appItem.FBIFingerPrintStatus, appItem.FBIApprovalStatus);
                                });

                                $scope.operationPending(false);
                                //$scope.openExistingApplication($scope.applicationList[0]);
                            } else {
                                $scope.paginationParams.totalRecords = 0;
                                $scope.applicationList = [];

                                $scope.operationPending(false);
                                // $scope.showStatusMsg('-', response.Message);
                            }
                        }, function (err) {
                            err = err.data;
                            $scope.operationPending(false);
                            console.log(err);
                            $scope.showStatusMsg('-', 'An error occurred searching for the applications');
                        });
                }
                else {
                    // Default - ALL License Types
                    $scope.recCountCPA = 100000000;
                    $scope.pagenoCPA = 1;
                    $scope.recCount = 0;
                    licenseRenewalService.RenewalSearchWithPager(sessionStorage.Key, $scope.pagenoCPA, $scope.recCountCPA , $scope.sortColumn, $scope.sortOrder, !!showAllRecords, searchDataItem, $scope.queueConfig.Name, true)
                        .then(function (response) {
                            response = response.data;
                            if (response.Status) {
                                $scope.recCount = response.Total_Recard;

                                $scope.applicationList = response.RenewalApplicationList;

                                if (!!$scope.gettingAudtiList && response.Total_Recard > 0 && !!$scope.applicationList && $scope.applicationList.length > 0) {
                                    // $scope.searchAuditData   Percentage      maxLimit
                                    let ToAuditDataSource = $scope.applicationList.where(a => a.DeficientMarkedDate == null && (a.LicenseStatusTypeId != 9 && a.LicenseStatusTypeId != 10 && a.LicenseStatusTypeId != 4));

                                    let auditCount = $scope.searchAuditData.maxLimit;
                                    if ($scope.searchAuditData.Percentage > 0) {
                                        auditCount = $scope.recCount * $scope.searchAuditData.Percentage / 100.0;
                                        auditCount = Math.ceil(auditCount);
                                    }

                                    if ($scope.searchAuditData.maxLimit > 0) {
                                        auditCount = Math.min(auditCount, $scope.searchAuditData.maxLimit);
                                    }

                                    let applicationListForAudit = ToAuditDataSource.filter(a => a.IsSelectedForAudit);
                                    applicationListForAudit = applicationListForAudit ?? [];
                                    ToAuditDataSource = ToAuditDataSource.filter(a => !a.IsSelectedForAudit);

                                    for (let i = applicationListForAudit.length; i < auditCount && ToAuditDataSource.length > 0; i++) {
                                        let randomElemIndx = Math.random() * ToAuditDataSource.length;
                                        randomElemIndx = Math.round(randomElemIndx);
                                        randomElemIndx = (randomElemIndx >= ToAuditDataSource.length) ? ToAuditDataSource.length - 1 : randomElemIndx;
                                        randomElemIndx = (randomElemIndx < 0) ? 0 : randomElemIndx;

                                        let movedItem = ToAuditDataSource.splice(randomElemIndx, 1);
                                        applicationListForAudit.push(movedItem[0]);
                                    }

                                    $scope.applicationList = applicationListForAudit;
                                    $scope.recCount = applicationListForAudit.length;
                                    $scope.paginationParams.pageSize = applicationListForAudit.length;
                                    $scope.gettingAudtiList = false;
                                    $scope.current.allowSearching = false;
                                }


                                $scope.applicationList = $scope.applicationList.leftJoinObjects($scope.licenseSubTypeList, 'item1.LicenseApplicationForId==item2.ApplicationSubTypeId', 'this1, LicenseApplicationSubTypeName: item2.ApplicationSubTypeName');

                                $scope.applicationList.forEach(function (appItem) {

                                    appItem.TemplateUrl = $scope.config.ApplicationTypeConfigs[appItem.ApplicationTypeId].TemplateUrl;
                                    appItem.Controller = $scope.config.ApplicationTypeConfigs[appItem.ApplicationTypeId].Controller;
                                    appItem.LicenseTypeCode = appItem.LicenseTypeId == 1 ? 'CPA' : '';
                                    //appItem.LicenseTypeName = $scope.licenseTypes.whereEquals(appItem.LicenseTypeId, 'LicenseTypeId').select('LicenseTypeName').firstOrDefault();
                                    appItem.ExternalApplicationStatusName = $scope.applicationStatusList.whereEquals(appItem.ApplicationStatusId, 'ApplicationStatusId').select('ExternalApplicationStatusName').firstOrDefault();
                                    appItem.FingerprintStatus = warningService.getBackgroundStatus(appItem.DOJFingerPrintStatus, appItem.DOJApprovalStatus, appItem.FBIFingerPrintStatus, appItem.FBIApprovalStatus);
                                });

                                //$scope.operationPending(false);
                                //$scope.openExistingApplication($scope.applicationList[0]);
                            } else {
                                $scope.recCount = 0;
                                $scope.applicationList = [];

                                //$scope.operationPending(false);
                                // $scope.showStatusMsg('-', response.Message);
                            }
                            searchDataItem.ProviderName = searchDataItem.Name;
                            $scope.pageno = 1;
                            $scope.recCountFirm = 100000000;
                            BackofficeFirmRenewalListService.ProviderRenewalSearchWithPager(sessionStorage.Key,$scope.pageno, $scope.recCountFirm, $scope.sortColumn, $scope.sortOrder, !!showAllRecords, searchDataItem,  $scope.queueConfig.Name)

                        .then(function (response2) {


                            response2 = response2.data;
                            if (response2.Status) {
                                $scope.paginationParams.totalRecords = $scope.recCount + response2.Total_Record;

                                $scope.applicationListUnfiltered = response2.ProviderRenewalApplicationList;

                                $scope.applicationListUnfiltered.forEach(function (appItem) {
                                    appItem.LicenseTypeCode = appItem.LicenseTypeName == 'Corporation' ? 'COR' : 'PAR';

                                });

                                $scope.filterCriteria = null;

                                if (!!$scope.queueConfig.Name || $scope.queueConfig.Name === "") {
                                    if($scope.queueConfig.Name === "IntakeQ")
                                        $scope.filterCriteria = 8;
                                        
                                    if($scope.queueConfig.Name === "ReviewQ")
                                        $scope.filterCriteria = 42;

                                    if($scope.queueConfig.Name === "ComplianceReviewQ")
                                        $scope.filterCriteria = 44;

                                    if($scope.queueConfig.Name === "DeficiencyQ")
                                        $scope.filterCriteria = 43;
                                    
                                    if($scope.queueConfig.Name === "ComplianceReviewDeficiency")
                                        $scope.filterCriteria = 45;
                                    
                                    if($scope.queueConfig.Name === "EnforcementReferralQ")
                                        $scope.filterCriteria = 46;
                                    
                                    if($scope.queueConfig.Name === "ManagerQ")
                                        $scope.filterCriteria = 28;
                                    

                                    if($scope.queueConfig.Name != "")
                                    {
                                        $scope.applicationPreList = $scope.applicationListUnfiltered.filter(ap => ap.ApplicationStatusId === $scope.filterCriteria);
                                    }
                                    else{
                                        $scope.applicationPreList = $scope.applicationListUnfiltered;
                                    }
                                        
                                    
                                    if ($scope.applicationList.length === 0 && $scope.applicationPreList.length === 0) {
                                        $scope.paginationParams.totalRecords = 0;
                                        $scope.applicationList = [];
                                        $scope.operationPending(false);
                                    }
                                    $scope.applicationPreList.forEach(function (appItem) {

                                        appItem.TemplateUrl = $scope.config.ApplicationTypeConfigs[appItem.ApplicationTypeId].TemplateUrl;
                                        appItem.Controller = $scope.config.ApplicationTypeConfigs[appItem.ApplicationTypeId].Controller;
                                        //appItem.LicenseTypeName = $scope.licenseTypes.whereEquals(appItem.LicenseTypeId, 'LicenseTypeId').select('LicenseTypeName').firstOrDefault();
                                        appItem.ExternalApplicationStatusName = $scope.applicationStatusList.whereEquals(appItem.ApplicationStatusId, 'ApplicationStatusId').select('ExternalApplicationStatusName').firstOrDefault();
                                        //appItem.FingerprintStatus = warningService.getBackgroundStatus(appItem.DOJFingerPrintStatus, appItem.DOJApprovalStatus, appItem.FBIFingerPrintStatus, appItem.FBIApprovalStatus);
                                    });
                                    if(!!$scope.applicationList && $scope.applicationList.length > 0)
                                    {
                                        for (let i = 0; i < $scope.applicationPreList.length; i++) {
                                            $scope.applicationList.push($scope.applicationPreList[i]);
                                          }
                                    }
                                    else{

                                        if($scope.queueConfig.Name != "")
                                        {
                                            $scope.applicationList = $scope.applicationListUnfiltered.filter(ap => ap.ApplicationStatusId === $scope.filterCriteria);
                                        }
                                        else{
                                            $scope.applicationList = $scope.applicationListUnfiltered;
                                        }
                                        $scope.applicationList.forEach(function (appItem) {

                                            appItem.TemplateUrl = $scope.config.ApplicationTypeConfigs[appItem.ApplicationTypeId].TemplateUrl;
                                            appItem.Controller = $scope.config.ApplicationTypeConfigs[appItem.ApplicationTypeId].Controller;
                                            //appItem.LicenseTypeName = $scope.licenseTypes.whereEquals(appItem.LicenseTypeId, 'LicenseTypeId').select('LicenseTypeName').firstOrDefault();
                                            appItem.ExternalApplicationStatusName = $scope.applicationStatusList.whereEquals(appItem.ApplicationStatusId, 'ApplicationStatusId').select('ExternalApplicationStatusName').firstOrDefault();
                                            //appItem.FingerprintStatus = warningService.getBackgroundStatus(appItem.DOJFingerPrintStatus, appItem.DOJApprovalStatus, appItem.FBIFingerPrintStatus, appItem.FBIApprovalStatus);
                                        });
                                    }
                                    $scope.operationPending(false);

                                    $scope.paginationParams.totalRecords = $scope.applicationList.length;
                                    $scope.endRecords = $scope.paginationParams.currentPage * $scope.paginationParams.pageSize;
                                    $scope.startRecords = $scope.endRecords - $scope.paginationParams.pageSize;
                                    if($scope.endRecords > $scope.paginationParams.totalRecords)
                                    {
                                        $scope.endRecords = $scope.paginationParams.totalRecords;
                                    }
                                    $scope.applicationList = $scope.applicationList.slice($scope.startRecords, $scope.endRecords);
                                    
                                }
                                else {

                                    
                                    $scope.paginationParams.totalRecords = 0;
                                    $scope.applicationList = [];
                                    $scope.operationPending(false);
                                    $scope.operationPending(false);
                                }
                                //TestingThis
                            }
                            else if($scope.applicationList != null && $scope.applicationList.length > 0 )
                            {
                                $scope.operationPending(false);
                                if ($scope.recCount > 0) {
                                    $scope.paginationParams.totalRecords = $scope.recCount;
                                }

                            }
                            else{
                                $scope.paginationParams.totalRecords = 0;
                                $scope.applicationList = [];
                                $scope.operationPending(false);
                            }
                            //console.log($scope.paginationParams.totalRecords);
                        }, function (res) {
                            HideLoader();
                        });
                        }, function (err) {
                            err = err.data;
                            $scope.operationPending(false);
                            console.log(err);
                            $scope.showStatusMsg('-', 'An error occurred searching for the applications');
                        });
                        

                }
            } catch (ex) {
                $scope.operationPending(false);
                console.log(ex);
                $scope.showStatusMsg('-', 'An error occurred searching for the applications');
            }
        };

        $scope.searchForAuditApplications = function () {

        }
        $scope.GetAuditQData = function () {

            //$scope.searchAuditData
            /*
            Percentage: 5,
            maxLimit: 0,
            AuditDateFrom: '',
            AuditDateTo: '',
            numberOfDays: 0,
            */

            // $scope.searchData
            // $scope.searchAuditData
            // objSearch.SubmittedToDate; objSearch.SubmittedFromDate; objSearch.SubmittedDate
            // searchAuditData.AuditDateFrom searchAuditData.AuditDateTo
            if (!!$scope.searchAuditData && $scope.searchAuditData.numberOfDays > 0) {
                $scope.searchData = $scope.searchData ?? {};
                $scope.searchAuditData.AuditDateTo = $scope.searchData.SubmittedToDate = !!$scope.searchAuditData.AuditDateTo ? $scope.searchAuditData.AuditDateTo : $scope.searchAuditData.AuditDateFrom;
                $scope.searchAuditData.AuditDateFrom = $scope.searchData.SubmittedFromDate = !!$scope.searchAuditData.AuditDateFrom ? $scope.searchAuditData.AuditDateFrom : $scope.searchAuditData.AuditDateTo;

                if (!!$scope.searchData.SubmittedFromDate || !!$scope.searchData.SubmittedToDate) {
                    $scope.gettingAudtiList = true;
                    $scope.current.allowSearching = true;
                    $scope.searchForApplications(1, true);
                }
            }
            debugger;
        };

        $scope.loadSearchDefaults = function () {
            $scope.searchDataTemplate = {
            };

            if (!!$scope.queueConfig.SearchDefaults && Object.keys($scope.queueConfig.SearchDefaults).length > 0) {
                $scope.searchDataTemplate = Object.assign($scope.searchDataTemplate, $scope.queueConfig.SearchDefaults);
            }

            $scope.searchData = angular.copy($scope.searchDataTemplate);
        };

        $scope.resetSearchClicked = function () {
            $scope.loadSearchDefaults();
            if ($scope.$root.routeData.queueType != 'AuditQ') {
                $scope.sortColumn = 'SubmittedDate';
                $scope.sortOrder = 'DESC';
                $scope.searchForApplications(1);
            }
        };

        $scope.pageChanged = function () {
            $scope.searchForApplications($scope.paginationParams.currentPage);
        };
        //END INITIALIZATION AND APPLICATION SEARCH


        //BEGIN ADD APPLICATION
        $scope.addNewApplication = function (appTypeId) {
            $scope.ApplicationTypeId = appTypeId;

            $scope.startAppCreationProcess();
        };
        //END ADD APPLICATION


        //BEGIN DISPLAY METHODS
        $scope.showApplicationListingSection = function () {
            $scope.hideTemplateSections();

            $scope.showApplicationSearchPanel = true;
            $scope.showApplicationListing = true;
        };

        $scope.hideApplicationListingSection = function () {
            $scope.showApplicationSearchPanel = false;
            $scope.showApplicationListing = false;
        };

        $scope.getAppStatusButton = function (appItem, event) {
            //Get the text
            var effectiveButton = $scope.config.ApplicationStatusButtons[appItem.ApplicationStatusId];
            if (!effectiveButton) {
                effectiveButton = $scope.config.ApplicationStatusButtons["Default"];
            }
            var effectiveButtonByInternalStatus = $scope.config.ApplicationInternalStatusButtons[appItem.ApplicationInternalStatusId];
            if (!!effectiveButtonByInternalStatus) {
                effectiveButton = effectiveButtonByInternalStatus;
            }
            if (!effectiveButton) {
                appItem.buttonText = 'Error!';
            } else {
                appItem.btnClass = effectiveButton.class;
                appItem.buttonText = effectiveButton.label;
            }

            return appItem.buttonText;
        };

        $scope.showAppSearchPanel = function (show) {
            $scope.showApplicationSearchPanel = show;
        };
        //END DISPLAY METHODS


        //BEGIN APPLICATION EVENTS
        $scope.saveApplicationStatus = function (applicationId, applicationStatus, queueApprovalData) {
            return initialApplicationstatusService.saveApplicationStatus(applicationId, sessionStorage.Key, applicationStatus, queueApprovalData);
        };

        $scope.saveApplicationStatusLk = function (applicationId, applicationStatus) {
            var reqData = {
                ApplicationId: applicationId,
                ApplicationTypeId: 2,
                ApplicationStatusId: applicationStatus,
                ApplicationStatusReasonId: 1,
                IsActive: true,
                IsDeleted: false,
                IsArchive: false,
                CreatedBy: $scope.userId,
                ApplicationInternalStatusReasonText: '',
                ApplicationInternalStatusId: 1,
                HighPriority: false
            };
            return initialApplicationstatusService.saveApplicationStatusLk(sessionStorage.Key, $scope.userId, reqData);
        };

        $scope.saveApplicationStatusChangeLog = function (applicationId, applicationStatus) {
            var reqData = {
                ApplicationId: applicationId,
                ApplicationStatusReasonId: 1,
                ApplicationNumber: '',
                ApplicationTypeId: 2,
                ApplicationStatusId: applicationStatus,
                ApplicationSubmitMode: 'B',
                ApplicationStatusChangedBy: $scope.userId,
                ReferenceNumber: '',
                LicenseTypeId: 0,
                HighPriority: false,
                ApplicationInternalStatusId: 1,
                ApplicationInternalStatusReasonText: ''
            };
            return initialApplicationstatusService.saveApplicationStatusChangeLog(sessionStorage.Key, $scope.userId, reqData)
        };

        $scope.updatePaymentAndStatus = function (data) {
            var app = $scope.applicationList.whereEquals(data.applicationId, 'ApplicationId').firstOrDefault();
            if (!!app) {
                var originalStatus = app.ApplicationStatusId;

                app.ApplicationStatusId = data.applicationStatusId;
            }

            if (!!app) {
                var appStatus = $scope.applicationStatusList.whereEquals(data.applicationStatusId, 'ApplicationStatusId').firstOrDefault();

                if (!!appStatus) {
                    app.StatusName = appStatus.Name;
                }
            }

            if (data.updateStatusOnly == false) {
                app.IsPaymentProcessed = true;
            }

            filterAppsFromQueueConfig();
        };

        $scope.$on('applicationStatusChanged', function (event, appInfo) {
            var data = {
                applicationId: appInfo.ApplicationId,
                applicationStatusId: appInfo.ApplicationStatusId,
                updateStatusOnly: true
            };

            $scope.updatePaymentAndStatus(data);
        });

        $scope.$on('updatePaymentAndStatus', function (event, data) {
            $scope.updatePaymentAndStatus(data);
        });

        $scope.$on('closeApplication', function (event, data) {
            closeOpenApplication();
        });
        //END APPLICATION EVENTS

        //BEGIN APPLICATION LISTING ACTIONS
        $scope.getApplicationLicense = function (appItem) {
            closeOpenApplication();

            var deferred = $q.defer();
            console.log(appItem);

            if (!!appItem.licenses) {
                deferred.resolve(appItem.license);

                return deferred.promise;
            }
            try {
                initialLicenseApplicationService.individualLicenseBYIndividualId(appItem.IndividualId)
                    .then(function (data) {
                        if (data.Status) {
                            appItem.licenses = data.IndividualLicenseList.whereEquals(appItem.ApplicationId, 'ApplicationId');
                            deferred.resolve(appItem.licenses);
                        } else {
                            appItem.licenses = [];
                            $scope.showStatusMsg('-', data);
                            deferred.reject(data);
                        }

                        appItem.withdraw = function () {
                            $scope.withdrawApplication(appItem);
                        };
                        appItem.close = function () {
                            $scope.archiveApplication(appItem);
                        };
                    }, function (data) {
                        $scope.showStatusMsg('-', data);
                        deferred.reject(data);
                    });
            } catch (ex) {
                $scope.showStatusMsg('-', ex);
                deferred.reject(data);
            }

            return deferred.promise;
        };

        $scope.withdrawApplication = function (appItem) {
            if (!confirm(Messages.eafebdd)) {
                return;
            }

            $rootScope.displayMainLoader('Withdrawing Application...');
            var statuses = ApplicationSettings.AppLicenseStatus['withdrawn'];

            updateLicenseStatus(statuses.LicenseStatusTypeId, statuses.IsActive, appItem.licenses)
                .then(function () {
                    $rootScope.displayMainLoader(false);

                    //Update to withdrawn
                    updateApplicationStatus(statuses.ApplicationStatusId, appItem);

                    filterAppsFromQueueConfig();
                }, function (err) {
                    console.log(err);
                    $rootScope.displayMainLoader(false);
                });
        };

        $scope.archiveApplication = function (appItem) {
            if (!confirm(Messages.bfabdaf)) {
                return;
            }

            $rootScope.displayMainLoader(true, 'Closing Application...');
            var statuses = ApplicationSettings.AppLicenseStatus['archived'];

            updateLicenseStatus(statuses.LicenseStatusTypeId, statuses.IsActive, appItem.licenses)
                .then(function () {
                    $rootScope.displayMainLoader(false);

                    //Update to closed
                    updateApplicationStatus(statuses.ApplicationStatusId, appItem);

                    filterAppsFromQueueConfig();
                }, function (err) {
                    console.log(err);
                    $rootScope.displayMainLoader(false);
                });
        };

        var updateLicenseStatus = function (statusId, isActive, licenses) {
            var deferred = $q.defer();
            var saveLicensePromises = [];

            var licStatus = $scope.licenseStatusList.whereEquals(statusId, 'LicenseStatusTypeId').firstOrDefault();

            licenses.forEach(function (license) {
                license.LicenseStatusTypeId = licStatus.LicenseStatusTypeId;
                license.LicenseStatusTypeCode = licStatus.LicenseStatusTypeCode;
                license.LicenseStatusTypeName = licStatus.LicenseStatusTypeName;

                license.ActionFlag = 'M';
                license.IsLicenseActive = isActive;

                saveLicensePromises.push(licenseApplicationListingService.saveIndividualLicense(sessionStorage.Key, license));
            });

            $q.all(saveLicensePromises)
                .then(function (response) {
                    if (!response.all('!!item.Status')) {
                        $scope.showStatusMessage({
                            messageContent: response.Message,
                            messageType: 'error'
                        });
                    }
                    deferred.resolve(response);
                }, function (error) {
                    deferred.reject(error);
                    $scope.showStatusMessage({
                        messageContent: 'Some error occurred !',
                        messageType: 'error'
                    });
                });

            return deferred.promise;
        };

        var updateApplicationStatus = function (statusId, appItem) {
            var appId = appItem.ApplicationId;

            appItem.ApplicationStatusId = statusId;

            $scope.saveApplicationStatus(appId, statusId);
            $scope.saveApplicationStatusLk(appId, statusId);
            $scope.saveApplicationStatusChangeLog(appId, statusId);
        };

        //END APPLICATION LISTING ACTIONS


        //BEGIN APPLICATION
        $scope.setupTemplateController = function () {
            $scope.current.TemplateUrl = $scope.config.ApplicationTypeConfigs[$scope.ApplicationTypeId].TemplateUrl;
            $scope.current.Controller = $scope.config.ApplicationTypeConfigs[$scope.ApplicationTypeId].Controller;
        };

        $scope.openExistingApplication = function (appItem) {
            if (!!$scope.current.application) {
                $scope.closeApplication($scope.current.application);
            }
            $scope.ApplicationId = appItem.ApplicationId;
            $scope.IndividualId = appItem.IndividualId;
            if (appItem.LicenseTypeName == 'Corporation' || appItem.LicenseTypeName == 'Partnership') // Firm Condition
            {
                $scope.ProvideId = appItem.ProviderId;
                $scope.ProviderLicenseTypeId = appItem.ProviderLicenseTypeId;
                $scope.IndividualId = appItem.ProviderId;
                $scope.EntityType = 'B';
            }
            else
            {
                $scope.ProvideId = null;
                $scope.ProviderLicenseTypeId = null;
                $scope.EntityType = null;
            }
            $scope.ApplicationTypeId = appItem.ApplicationTypeId;
            $scope.isBackoffice = true;
            $scope.setupTemplateController();
            $scope.current.allowSearching = false;
            $scope.current.application = appItem;

            if (appItem.ApplicationTypeId != 1) {
                appItem.showInternalForm = true;
            } else {
                if (appItem.ApplicationStatusId == 14 || appItem.ApplicationStatusId == 1 || appItem.ApplicationStatusId == 8 || appItem.ApplicationStatusId == 10) {
                    //Edit
                    $scope.IsPendingApplication = true;
                    appItem.disableSubmitControls = false;
                    appItem.disableRenewalForm = false;
                    appItem.DisableSaveButton = false;
                    appItem.DisableSubmitButton = false;
                    appItem.ShowPayAndApproveButton = true;
                    appItem.ShowConfirmAndApproveButton = true;
                    $scope.ProceedtoPaymentSectionEnable = false;
                    //"Save" ,"Pay Only","Pay and Approve", "Close"
                    //Form Not disabled

                } else if (appItem.ApplicationStatusId == 2 || appItem.ApplicationStatusId == 13 || appItem.ApplicationStatusId == 15) {
                    //Verify
                    appItem.disableSubmitControls = false;
                    appItem.disableRenewalForm = true;
                    appItem.DisableSaveButton = true;
                    appItem.DisableSubmitButton = true;
                    appItem.ShowPayAndApproveButton = true;
                    appItem.ShowConfirmAndApproveButton = true;
                    $scope.ProceedtoPaymentSectionEnable = true;
                    //"Save" ,"Confirm And Approve", "Close"
                    //Form Not disabled
                } else if (appItem.ApplicationStatusId == 3) {
                    //View
                    appItem.disableSubmitControls = true;
                    appItem.disableRenewalForm = true;
                    appItem.DisableSaveButton = true;
                    appItem.DisableSubmitButton = true;
                    appItem.ShowPayAndApproveButton = false;
                    appItem.ShowConfirmAndApproveButton = true;
                    $scope.ProceedtoPaymentSectionEnable = true;

                    //"Save"----Disabled ,"Confirm And Approve"----Disabled, "Close"
                    //Form disabled
                } else if (appItem.ApplicationStatusId == 16 || appItem.ApplicationStatusId == 17 ||
                    appItem.ApplicationStatusId == 18 || appItem.ApplicationStatusId == 19 ||
                    appItem.ApplicationStatusId == 20 || appItem.ApplicationStatusId == 21 || appItem.ApplicationStatusId == 22) {
                    appItem.disableRenewalForm = true;
                    $scope.ProceedtoPaymentSectionEnable = true;
                }
                $scope.checkAndCreatePendingRenewal($scope.IndividualId, appItem.ApplicationId,$scope.ProvideId, $scope.EntityType, appItem);
            }
        };

        function initialProcessing() {
            angular.forEach($scope.renewalCheckData.IndividualLicenseList, function (licenseType) {
                if (licenseType.LicenseStatusTypeId == 1 || licenseType.LicenseStatusTypeId == 7 || licenseType.LicenseStatusTypeId == 8) {
                    licenseType.applicationType = 'active';
                    licenseType.currentApplicationType = 'active';
                }
            });
            processApplicationData();
        };

        function initialFirmProcessing() {
            angular.forEach($scope.renewalCheckData.ProviderLicenseList, function (licenseType) {
                if (licenseType.LicenseStatusTypeId == 1 || licenseType.LicenseStatusTypeId == 7 || licenseType.LicenseStatusTypeId == 8) {
                    licenseType.applicationType = 'active';
                    licenseType.currentApplicationType = 'active';
                }
            });
            processFirmApplicationData();
        };

        var processApplicationData = function () {
            var renewalGroups = [];
            $scope.renewalCheckData.selectedGroups = {};
            var historyQuestionContentId = '';
            angular.forEach($scope.renewalCheckData.IndividualLicenseList, function (licenseType) {
                if (licenseType.IsSelectedToRenew) {
                    if (renewalGroups.indexOf(licenseType.RenewalApplicationGrouping) == -1)
                        renewalGroups.push(licenseType.RenewalApplicationGrouping);
                    $scope.renewalCheckData.selectedGroups["group" + licenseType.RenewalApplicationGrouping] = true;
                    if (!!licenseType.RenewalApplicationHistoryQuestionContentId) {
                        if (!historyQuestionContentId)
                            historyQuestionContentId = licenseType.RenewalApplicationHistoryQuestionContentId;
                        else
                            historyQuestionContentId.concat(',', licenseType.RenewalApplicationHistoryQuestionContentId);
                    }
                }
            });
            $scope.renewalCheckData.RenewalApplicationHistoryQuestionContentId = historyQuestionContentId.split(',').distinct();
            $scope.renewalCheckData.renewalGroups = renewalGroups;
        };

        var processFirmApplicationData = function () {
            var renewalGroups = [];
            $scope.renewalCheckData.selectedGroups = {};
            var historyQuestionContentId = '';
            angular.forEach($scope.renewalCheckData.ProviderLicenseList, function (licenseType) {
                if (licenseType.IsSelectedToRenew) {
                    if (renewalGroups.indexOf(licenseType.RenewalApplicationGrouping) == -1)
                        renewalGroups.push(licenseType.RenewalApplicationGrouping);
                    $scope.renewalCheckData.selectedGroups["group" + licenseType.RenewalApplicationGrouping] = true;
                    if (!!licenseType.RenewalApplicationHistoryQuestionContentId) {
                        if (!historyQuestionContentId)
                            historyQuestionContentId = licenseType.RenewalApplicationHistoryQuestionContentId;
                        else
                            historyQuestionContentId.concat(',', licenseType.RenewalApplicationHistoryQuestionContentId);
                    }
                }
            });
            $scope.renewalCheckData.RenewalApplicationHistoryQuestionContentId = historyQuestionContentId.split(',').distinct();
            $scope.renewalCheckData.renewalGroups = renewalGroups;
        };

        $scope.checkAndCreatePendingRenewal = function (individualId, applicationId,providerId, entityType,appItem) {
            ShowLoader();
            $scope.renewalCheckData = {
                IndividualId: individualId,
                ApplicationId: applicationId,
                IsBackOffice: $scope.isBackoffice,
                CheckRenewalEligibility: false,
                CreatePendingInternally: false,
                OpenExistingApplication: true,
                BoardInfoId: 1,
                ReceivedDate: $scope.ReceivedDate,
                ProviderId: providerId,
                EntityType: entityType,
                LicenseNumber: appItem.LicenseNumber,
            };
            licenseRenewalService.checkAndCreatePendingRenewal(sessionStorage.Key, $scope.renewalCheckData).then(function (responseLicenseCheck) {
                if (!!responseLicenseCheck.Status) {
                    HideLoader();
                    sessionStorage.IndividualId = individualId;
                    if(!!providerId)
                        sessionStorage.ProviderId = providerId;
                    // $scope.individualLicenseData
                    $scope.renewalCheckData = responseLicenseCheck.RenewalCheckData;
                    if (!appItem) {
                        $scope.DisplayAddApplication = true;
                        $scope.IsPendingApplication = true;
                        $scope.SearchLicenseNumber = "";
                        $scope.licenseBoSearch = false;
                        licenseSearchForm.$submitted = false;
                    } else {
                        appItem.showInternalForm = true;
                        if (!!providerId)
                            initialFirmProcessing();
                        else
                            initialProcessing();
                            
                    }
                } else {
                    $scope.DisplayAddApplication = false;
                    $scope.IsPendingApplication = false;
                    showStatusMessage(responseLicenseCheck.Message, "error");
                    HideLoader();
                }
            }, function (data) {
                HideLoader();
                if (data != null) {
                    showStatusMessage(data.message, "error");
                }
            });
        };

        var closeOpenApplication = function () {
            var openApp = [$scope.applicationList.whereEquals(true, 'showInternalForm').firstOrDefault(), $scope.current.application].coalesce();
            $scope.closeApplication(openApp);
        };

        $scope.closeApplication = function (appItem) {
            $scope.ApplicationId = null;
            $scope.IndividualId = null;
            $scope.ApplicationTypeId = null;
            $scope.ProviderId = null;

            if (!!appItem) {
                appItem.showInternalForm = false;
            }

            $scope.applicationType = null;

            $scope.current.TemplateUrl = null;
            $scope.current.Controller = null;
            $scope.current.allowSearching = $scope.$root.routeData.queueType != 'AuditQ';
            $scope.current.person = null;
            $scope.current.application = null;

            $scope.listConfig = {};
            $scope.tableMethods.listConfig = $scope.listConfig;

            if ($scope.$root.routeData.queueType != 'AuditQ') {
                filterAppsFromQueueConfig();
                $scope.showApplicationListingSection();
                $scope.searchForApplications($scope.paginationParams.currentPage);
            }
            else {
                $scope.showApplicationListingSection();
            }
        };

        $scope.$on('updatecurrentApplicationStatus', function (event, data) {

            if (!$scope.currentApplication) {
                $scope.currentApplication = {};
            }
            $scope.currentApplication = data.application;
        });
        //END APPLICATION

        $scope.saveEmailDeficiency = function (updateDeficiencyStatus, deficiencyStatusId) {
            var isSaveAllTabs = (!!$scope.current && !!$scope.current.application && !!$scope.current.application.ApplicationTypeId && $scope.current.application.ApplicationTypeId == 1);
            $scope.$broadcast("SaveDeficiency", {
                isSaveAllTabs: isSaveAllTabs,
                updateDeficiencyStatus: updateDeficiencyStatus,
                deficiencyStatusId: deficiencyStatusId
            });
        };

        // BEGIN DEFICIENCY
        $scope.openEmailDeficiency = function (renewalItem, isDenyCall, isAssignTo, isDeficiencyCall) {
            if (!renewalItem.showEmailDeficienyForm) {
                ShowLoader();
                hideStatusMessage();
                renewalItem.isDenyCall = isDenyCall;
                renewalItem.isAssignTo = isAssignTo;
                renewalItem.isDeficiencyCall = isDeficiencyCall;

                // $scope.isDenyCall = !!isDenyCall;
                // $scope.isAssignTo = !!isAssignTo;
                // $scope.isDeficiencyCall = !!isDeficiencyCall;
                if (!!renewalItem.isAssignTo && !renewalItem.moveToQueue && !renewalItem.AssignToUserId) {
                    showStatusMessage(Messages.fcfdcaa, "error");
                    HideLoader();
                    return;
                }
                if (!!renewalItem.isAssignTo && !!renewalItem.moveToQueue) {
                    var applicationStatusId = getAssignToApplicationStatus(renewalItem.ApplicationTypeId, renewalItem.ApplicationStatusId, renewalItem.moveToQueue);
                    if (!applicationStatusId) {
                        showStatusMessage(Messages.dcdddfa, "error");
                        HideLoader();
                        return;
                    }
                }
                if (!!renewalItem.isDeficiencyCall) {
                    $scope.saveEmailDeficiency();
                    if(renewalItem.LicenseTypeName === 'Partnership' || renewalItem.LicenseTypeName === 'Corporation')
                    {
                        $scope.saveApplicationStaffDefeciency();
                    }
                    else if (!!$scope.listConfig?.individualViewConfig?.onlystaffDeficiencyExists) {
                        $scope.saveApplicationStaffDefeciency();
                        return;
                    }
                } else {
                    HideLoader();
                }
                hideStatusMessage();
                renewalItem.showInternalForm = true;
                renewalItem.showEmailDeficienyForm = true;
            }
        };

        $scope.closeDeficiencyEmailForm = function () {
            if (!!$scope.current.application) {
                $scope.current.application.showEmailDeficienyForm = false;
            }
        };

        $scope.saveApplicationStaffDefeciency = function () {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'staffDeficiencyConfirmation.html',
                size: 'md',
                controller: ['$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {
                    $scope.onCancel = function () {
                        $uibModalInstance.dismiss('cancel');
                    };
                    $scope.onConfirm = function () {
                        $uibModalInstance.close('success');
                    };
                }]
            });

            modalInstance.result.then(function () {
                $scope.updateApplicationDefeciency($scope.current.application.ApplicationId, $scope.current.application.ApplicationStatusId, null, null);
            });
        };

        $scope.updateApplicationDefeciency = function (applicationId, applicationStatusId, renewalItem, validateAndResolveDeficiency) {
            var closeApplication = true;
            var queueApprovalData = {
                UpdateApplicationStatus: (!!applicationStatusId),
                IndividualId: $scope.current.application?.IndividualId || $scope.current.application?.ProviderId,
                ApplicationId: applicationId,
                ApplicationStatusId: applicationStatusId,
            };
            if($scope.current.application?.LicenseTypeName === 'Corporation' || $scope.current.application?.LicenseTypeName  === 'Partnership')
            {
                if (['FingerprintQ', 'FingerprintDeficiencyQ'].indexOf($scope.routeData.queueType) != -1) {
                    queueApprovalData.ProcessBackgroundDeficiency = true;
                    queueApprovalData.BackgroundDeficiencyStatusId = 1;
                    // if (!!$scope.listConfig.providerViewConfig.onlystaffDeficiencyExists) {
                    //     queueApprovalData.BackgroundDeficiencyStatusId = 2;
                    // }
                    if (!!validateAndResolveDeficiency) {
                        queueApprovalData.BackgroundDeficiencyStatusId = 4;
                    }
                } else {
                    queueApprovalData.ProcessDeficiency = true;
                    queueApprovalData.DeficiencyStatusId = 1;
                    // if (!!$scope.listConfig.providerViewConfig.onlystaffDeficiencyExists) {
                    //     queueApprovalData.DeficiencyStatusId = 2;
                    // }
                    if (!!validateAndResolveDeficiency) {
                        queueApprovalData.DeficiencyStatusId = 4;
                    }
                }

                if (!!validateAndResolveDeficiency) {
                    if ($scope.listConfig.providerViewConfig.IsDefecient &&
                        !$scope.listConfig.providerViewConfig.isDeficiencyResolved) {
                        showStatusMessage(Messages.General.Errors.ResolveDef, "error");
                        return;
                    } else {
                        hideStatusMessage();
                        $scope.saveEmailDeficiency(true, queueApprovalData.DeficiencyStatusId);
                        closeApplication = false;
                    }
                }

                ShowLoader();
            initialApplicationstatusService.processFirmLicenseAndApplication(sessionStorage.Key, queueApprovalData)
                .then(function (response) {
                    if (response.Status == false) {
                        showStatusMessage(response.Message, "error");
                        HideLoader();
                        return;
                    }

                    if (!!closeApplication) {
                        closeOpenApplication();
                        $scope.searchForApplications($scope.paginationParams.currentPage);
                    }
                    HideLoader();
                }, function (data) {
                    HideLoader();
                    showStatusMessage(data.message, "error");
                });
            }
            else{
                if (['FingerprintQ', 'FingerprintDeficiencyQ'].indexOf($scope.routeData.queueType) != -1) {
                    queueApprovalData.ProcessBackgroundDeficiency = true;
                    queueApprovalData.BackgroundDeficiencyStatusId = 1;
                    if (!!$scope.listConfig.individualViewConfig.onlystaffDeficiencyExists) {
                        queueApprovalData.BackgroundDeficiencyStatusId = 2;
                    }
                    if (!!validateAndResolveDeficiency) {
                        queueApprovalData.BackgroundDeficiencyStatusId = 4;
                    }
                } else {
                    queueApprovalData.ProcessDeficiency = true;
                    queueApprovalData.DeficiencyStatusId = 1;
                    if (!!$scope.listConfig.individualViewConfig.onlystaffDeficiencyExists) {
                        queueApprovalData.DeficiencyStatusId = 2;
                    }
                    if (!!validateAndResolveDeficiency) {
                        queueApprovalData.DeficiencyStatusId = 4;
                    }
                }

                if (!!validateAndResolveDeficiency) {
                    if ($scope.listConfig.individualViewConfig.IsDefecient &&
                        !$scope.listConfig.individualViewConfig.isDeficiencyResolved) {
                        showStatusMessage(Messages.General.Errors.ResolveDef, "error");
                        return;
                    } else {
                        hideStatusMessage();
                        $scope.saveEmailDeficiency(true, queueApprovalData.DeficiencyStatusId);
                        closeApplication = false;
                    }
                }

                ShowLoader();
            initialApplicationstatusService.processLicenseAndApplication(sessionStorage.Key, queueApprovalData)
                .then(function (response) {
                    if (response.Status == false) {
                        showStatusMessage(response.Message, "error");
                        HideLoader();
                        return;
                    }

                    if (!!closeApplication) {
                        closeOpenApplication();
                        $scope.searchForApplications($scope.paginationParams.currentPage);
                    }
                    HideLoader();
                }, function (data) {
                    HideLoader();
                    showStatusMessage(data.message, "error");
                });
            }            
        };
        // END DEFICIENCY

        $scope.onSelectionChanged = function (isAllSelectedClicked, currentValue) {
            if (!!isAllSelectedClicked) {
                $scope.applicationList.map(function (applicationItem) {
                    applicationItem.isSelected = currentValue;
                    // if (!applicationItem.DisciplineFlag && (applicationItem.ApplicationSubTypeId == 2 || applicationItem.FingerprintStatus == "MatchedPass")
                    //     && applicationItem.IsPaymentProcessed == true && (applicationItem.ApplicationSubTypeId == 2 || !applicationItem.ExamRequired || applicationItem.ExamStatus == 'Passed')) {
                    //     applicationItem.isSelected = currentValue;
                    // }
                });
                $scope.applicationList = $scope.applicationList.slice(0,$scope.paginationParams.pageSize);
            } else {
                $scope.current.isAllSelected = ($scope.applicationList.where(function (item) {
                    return (!item.DisciplineFlag && (item.ApplicationSubTypeId == 2 || item.FingerprintStatus == "MatchedPass") && item.IsPaymentProcessed == true && (item.ApplicationSubTypeId == 2 || !item.ExamRequired || item.ExamStatus == "Passed"));
                }).all('!!item.isSelected'));

                $scope.current.isAllSelected = $scope.applicationList.all('!!item.isSelected')
            }
            $scope.current.isAnySelected = ($scope.applicationList.any('!!item.isSelected'));
        };

        $scope.AssignToChanged = function (item) {
            $scope.AssignToId = item;
        }

        $scope.processSelectedApplication = function (bulkApprovalConfig, trigger) {
            if (trigger == "AssignTo") {
                // alert("Assign to function was called!");
                let assignToId = $scope.AssignToId;
                var selectedApplicationData = $scope.applicationList.whereEquals(true, 'isSelected');
                console.table(selectedApplicationData);
                $scope.operationPending(true, 'Processing...');

                var SelectedApplicationIds = selectedApplicationData.select(a => a.ApplicationId);

                let requestObj = {
                    SelectedApplicationIds: SelectedApplicationIds,
                    AssignToUser: true,
                    AssignToUserId: assignToId,
                    AssignToQueue: false,
                    AssignedToQueueName: null
                };
                LicenseRenewalHASnAService.assignApplications(sessionStorage.Key, requestObj)
                    .then(function (data) {
                        if (!data.Status) {
                            $scope.showStatusMsg('-', data);
                            return;
                        }
                        $scope.operationPending(false);
                        $scope.searchForApplications(1);
                        $scope.showStatusMsg('+', 'Applications assigned successfully.');

                    }, function (error) {
                        $scope.searchForApplications(1);
                        $scope.showStatusMsg('-', error);
                    });
                $scope.operationPending(false);
                $scope.showStatusMsg('+', 'Applications assigned successfully.');
            }
            else if ((trigger == "Approve" || trigger == "ManagerApprove")) {
                var selectedApplicationData = $scope.applicationList.whereEquals(true, 'isSelected');
                console.table(selectedApplicationData);
                let requestObj = [];
                $scope.operationPending(true, 'Processing...');
                angular.forEach(selectedApplicationData, function (selectedApplicationItem) {
                    var applicationApprovalRequest = {
                        EntityId: selectedApplicationItem?.IndividualId || selectedApplicationItem?.ProviderId,
                        EntityType: "I",
                        ApplicationId: selectedApplicationItem.ApplicationId,
                        IndividualLicenseId: selectedApplicationItem?.IndividualLicenseId || selectedApplicationItem?.ProviderlLicenseId,
                        ProviderlLicenseId: selectedApplicationItem?.ProviderlLicenseId,
                        BoardInfoId: 1,
                        EventTrigger: bulkApprovalConfig.BulkApprovalTrigger,
                        GenerateDCALicenseTransferFile: (!!bulkApprovalConfig && bulkApprovalConfig.GenerateDCALicenseTransferFile),
                        GenerateLicensePaymentFile: (!!bulkApprovalConfig && bulkApprovalConfig.GenerateLicensePaymentFile),
                        LicensePaymentFileRequest: {
                            EntityId: selectedApplicationItem?.IndividualId || selectedApplicationItem?.ProviderId,
                            EntityType: "I",
                            ApplicationId: selectedApplicationItem.ApplicationId,
                            IndicatorDContentItemId: 396,
                            IndicatorGHContentItemIds: "430",
                            IndicatorCContentItemId: 459
                        }
                    };

                    if (applicationApprovalRequest.GenerateDCALicenseTransferFile) {
                        applicationApprovalRequest.LicenseTransferFileRequest = {
                            EntityId: selectedApplicationItem?.IndividualId || selectedApplicationItem?.ProviderId,
                            EntityType: "I",
                            ApplicationId: selectedApplicationItem.ApplicationId,
                            IndividualLicenseId: selectedApplicationItem?.IndividualLicenseId || selectedApplicationItem?.ProviderlLicenseId,
                            ProviderlLicenseId: selectedApplicationItem?.ProviderlLicenseId,
                            ResidentialAddressConfig: {
                                AddressTypeId: 2,
                                CheckIsMailingFlag: false
                            },
                            MailingAddressConfig: {
                                AddressTypeId: 1,
                                CheckIsMailingFlag: false
                            },
                            MailingPhoneContactTypeId: 1,
                            ResidentialPhoneContactTypeId: 2,
                            EmailContactTypeId: 8
                        };
                    }

                    requestObj.push(applicationApprovalRequest);
                });
                applicationListingService.processApplicationApproval(sessionStorage.Key, requestObj)
                    .then(function (data) {
                        if (!data.Status) {
                            $scope.showStatusMsg('-', data);
                            return;
                        }
                        $scope.operationPending(false);
                        $scope.searchForApplications($scope.paginationParams.currentPage);
                        $scope.showStatusMsg('+', 'Applications approved successfully.');
                    }, function (error) {
                        $scope.showStatusMsg('-', error);
                    });
            }
            else if (trigger == "SelectToAudit") {
                var selectedApplicationData = $scope.applicationList.whereEquals(true, 'isSelected');
                selectedApplicationData = selectedApplicationData.whereEquals(false, 'IsSelectedForAudit');
                var SelectedApplicationIds = selectedApplicationData.select(a => a.ApplicationId)

                if (!!SelectedApplicationIds && SelectedApplicationIds.length > 0) {


                    let requestObj = {
                        AuditBy: sessionStorage.UserID,
                        IsSeleted: true,
                        IsApproved: null,
                        ApplicationIds: SelectedApplicationIds,
                        IsFirstSelet: true
                    };

                    $scope.CurrentAuditRequest = requestObj;
                    $scope.CurrentAuditRequest.applications = selectedApplicationData;

                    applicationListingService.processApplicationAudit(sessionStorage.Key, requestObj)
                        .then(function (data) {
                            if (!data.Status) {
                                $scope.showStatusMsg(' - ', data);
                                return;
                            }
                            else {
                                if (!!$scope.CurrentAuditRequest && !!$scope.CurrentAuditRequest.applications && $scope.CurrentAuditRequest.applications.length > 0) {
                                    for (let i = 0; i < $scope.CurrentAuditRequest.applications.length; i++) {
                                        $scope.CurrentAuditRequest.applications[i].IsSelectedForAudit = true;
                                    }

                                    $scope.CurrentAuditRequest = null;
                                }
                                $scope.showStatusMsg('+', 'Operation was completed successfully.');
                                $scope.operationPending(false);
                            }
                        }, function (error) {
                            $scope.showStatusMsg('- ', error);
                        });
                }
            }
            else {
                if ($scope.$root.routeData.queueType == 'AuditQ') {
                    debugger;
                    // 'AuditQ' Approve ????
                    var selectedApplicationData = $scope.applicationList.whereEquals(true, 'isSelected');
                    console.table(selectedApplicationData);
                    var requestObj = [];
                    $scope.operationPending(true, 'Processing...');

                    var requestObj = [];
                    angular.forEach(selectedApplicationData, function (selectedApplicationItem) {
                        var applicationApprovalRequest = {
                            EntityId: selectedApplicationItem.IndividualId,
                            EntityType: "I",
                            ApplicationId: selectedApplicationItem.ApplicationId,
                            IndividualLicenseId: selectedApplicationItem.IndividualLicenseId,
                            BoardInfoId: 1,
                            EventTrigger: bulkApprovalConfig.BulkApprovalTrigger,
                            GenerateDCALicenseTransferFile: (!!bulkApprovalConfig && bulkApprovalConfig.GenerateDCALicenseTransferFile),
                        };
                        requestObj.push(applicationApprovalRequest);

                    });


                    $scope.operationPending(false);
                    $scope.searchForApplications($scope.paginationParams.currentPage);
                    $scope.showStatusMsg('+', 'Applications approved successfully.');
                }
            }
        };

        $scope.processDeficiencyReason = function () {
            if (!!$scope.isDeficiencyReasonLoaded && $scope.isRouteDataLoaded && !!$scope.deficiencyReasonList) {
                $scope.deficiencyReasonListFiltered = $scope.deficiencyReasonList.filter(function (deficiencyReasonItem) {
                    deficiencyReasonItem.DeficiencyReasonMessageDetail = (deficiencyReasonItem.DeficiencyReasonMessage + ' (' + (!!deficiencyReasonItem.ResolvedByStaff && deficiencyReasonItem.ResolvedByStaff.toLowerCase() == 'y' ? 'Staff Only' : 'Applicant') + ')');
                    return (deficiencyReasonItem.EditableInQueueMenuIds && deficiencyReasonItem.EditableInQueueMenuIds.split(',').includes($scope.routeData.queueMenuId.toString()));
                });
            } else {
                $scope.deficiencyReasonListFiltered = [];
            }
        };

        $scope.deficiencyReasonLoaded = function (deficiencyReasonList) {
            $scope.deficiencyReasonList = deficiencyReasonList;
            $scope.isDeficiencyReasonLoaded = true;
            $scope.processDeficiencyReason();
        };

        $scope.examIntervalLoaded = function (examIntervalList) {
            // var examTypeSelectionMapping = {
            //     "ExamType2": 4,
            //     "ExamType3": 4,
            //     "ExamType4": 2
            // };
            if (!!examIntervalList) {
                // var examIntervalByTypes = {};
                // var examIntervalFilteredIds = [];
                // var examIntervalFiltered = [];
                // examIntervalList.map(function (examInterval) {
                //     var isValid = !!utilityService.checkIfDateGreaterOrEqual(examInterval.PaymentWindowEndDate, new Date());
                //     if (!!isValid) {
                //         var examTypesForInterval = examInterval.ExamTypeIds.split(",");
                //         angular.forEach(examTypesForInterval, function (examTypeForIntervalItem) {
                //             if (!!examIntervalByTypes && (!examIntervalByTypes[examTypeForIntervalItem] || examIntervalByTypes[examTypeForIntervalItem] < examTypeSelectionMapping["ExamType" + examTypeForIntervalItem])) {
                //                 if (examIntervalFilteredIds.indexOf(examInterval.ExamIntervalId) == -1) {
                //                     examIntervalFilteredIds.push(examInterval.ExamIntervalId);
                //                     examIntervalFiltered.push(examInterval);
                //                     examIntervalByTypes[examTypeForIntervalItem] = (!!examIntervalByTypes[examTypeForIntervalItem] ? examIntervalByTypes[examTypeForIntervalItem] + 1 : 1)
                //                 }
                //             }
                //         });

                //     }
                // });
                $scope.examIntervalListFiltered = examIntervalList.select("ExamIntervalGroupCode").distinct().map(function (item) {
                    return {
                        ExamIntervalGroupCode: item
                    }
                });
            } else {
                $scope.examIntervalListFiltered = [];
            }
        };

        $scope.auditApplication = function (appItem, hasIssue, isEnforcementAudit) {
            if (!!$scope.current.application) {
                // $scope.closeApplication($scope.current.application);
                /*    
                    $scope.ApplicationId = appItem.ApplicationId;
                    $scope.IndividualId = appItem.IndividualId;
                    $scope.ApplicationTypeId = appItem.ApplicationTypeId;
                    $scope.isBackoffice = true;
                */
            }
            // $scope.setupTemplateController();
            // $scope.current.allowSearching = false;
            // $scope.current.application = appItem;

            let requestObj = {
                AuditBy: sessionStorage.UserID,
                IsSeleted: true,
                IsApproved: !hasIssue,
                IsEnforcementAudit: isEnforcementAudit,
                ApplicationIds: [appItem.ApplicationId],
                IsFirstSelet: !appItem.IsSelectedForAudit
            };

            $scope.CurrentAuditRequest = requestObj;
            $scope.CurrentAuditRequest.applications = [appItem];

            applicationListingService.processApplicationAudit(sessionStorage.Key, requestObj)
                .then(function (data) {
                    if (!data.Status) {
                        $scope.showStatusMsg('- ', data);
                        return;
                    }
                    else {
                        if (!!$scope.CurrentAuditRequest && !!$scope.CurrentAuditRequest.applications && $scope.CurrentAuditRequest.applications.length > 0) {
                            $scope.CurrentAuditRequest.applications[0].IsSelectedForAudit = true;
                            $scope.CurrentAuditRequest.applications[0].IsAuditApproved = $scope.CurrentAuditRequest.IsApproved;

                            $scope.CurrentAuditRequest.applications[0].IsEnforcementAudit = $scope.CurrentAuditRequest.IsEnforcementAudit;

                            $scope.CurrentAuditRequest = null;
                        }
                        $scope.showStatusMsg('+', 'Operation was completed successfully.');
                        $scope.operationPending(false);
                    }
                }, function (error) {
                    $scope.showStatusMsg('- ', error);
                });
        };
    };
})();
