(function () {
    'use strict'
    angular.module('app.core')
        .controller('ProviderDashboardApplicationDetailsController', ['$scope', '$rootScope', '$state', '$controller', '$q', 'providerDashboardApplicationDetailsService',
            'examFactory', 'typeValuesService', 'WebApiUrl', 'licenseApplicationListingService','backofficeProviderLicenseService','utilityService',
            function ($scope, $rootScope, $state, $controller, $q, providerDashboardApplicationDetailsService, examFactory, typeValuesService, WebApiUrl,
                 licenseApplicationListingService,backofficeProviderLicenseService,utilityService) {
                $controller('GlobalController', {
                    $scope: $scope
                });
                var _Key = '';
                var _UserId = '';
               
                $scope.FirstName = '';
                $scope.LastName = '';
                $scope.DashboardPage = {};
                
                $scope.DownloadDocumentAPIURL = '';
                $scope.userId = sessionStorage.UserID;
                $scope.natValidation = {};
                // page init method
                $scope.init = function () {
                    try {
                        if ($scope.isSessionActive()) {
                            _Key = sessionStorage.Key;
                            _UserId = sessionStorage.UserID;
                            $scope.providerId = parseInt(sessionStorage.ProviderId);
                            $scope.DownloadDocumentAPIURL = WebApiUrl + 'Provider/ProviderDocumentByProviderDocumentId/' + sessionStorage.Key + '?ProviderDocumentId=';
                             $scope.applicationDocumentByProviderId();
                        } else {
                            $state.go('app.Loginwemail');
                        }
                    } catch (ex) {
                        showStatusMessage(ex.message, "error");
                    }
                };

                $scope.applicationDocumentByProviderId = function () {
                    // var entityExamPager = {
                    //     sortType: 'ExamId',
                    //     sortOrder: 'asc',
                    //     currentPage: 1,
                    //     totalRecords: 10,
                    //     pageSize: 100,
                    //     maxSize: 5,
                    //     ExamId: null,
                    //     FromDate: null,
                    //     ToDate: null,
                    //     EntityType: 'I',
                    //     EntityId: $scope.IndividualId
                    // };

                    // var entityExamResultRequest = {
                    //     EntityId: $scope.IndividualId
                    // };
                    var dataPromises = {
                        application: providerDashboardApplicationDetailsService.applicationDocumentByProviderId(_Key, $scope.providerId),
                        licensetype: typeValuesService.getTypeValuesByTypeNameAndParamsPublic("RefTablesGetAll", true, ("?tableKey=licensetype")),
                        // entityExam: examFactory.getEntityExams(sessionStorage.Key, entityExamPager),
                        // examType: typeValuesService.getTypeValuesByTypeNameAndParams("RefTablesGetAll", sessionStorage.Key, true, ("?tableKey=examtype")),
                        // examStatus: typeValuesService.getTypeValuesByTypeNameAndParams("RefTablesGetAll", sessionStorage.Key, true, ("?tableKey=examstatus")),
                        // reExam: typeValuesService.getTypeValuesByTypeNameAndParams("RefTablesGetAll", sessionStorage.Key, true, ("?tableKey=reexam")),
                        // entityExamResult: examFactory.getEntityExamResult(sessionStorage.Key, entityExamResultRequest),
                        applicationSubType: typeValuesService.getTypeValuesByTypeNameAndParams("RefTablesGetAll", sessionStorage.Key, true, ("?tableKey=applicationsubtype")),
                        individualBYIndividual: backofficeProviderLicenseService.getProviderById(sessionStorage.Key, $scope.providerId)
                    }
                    $q.all(dataPromises)
                        .then(function (response) {
                            if (response.licensetype.Status) {
                                $scope.licenseTypes = response.licensetype.ReferenceEntities;
                                $scope.licenseTypeList = _.filter(response.licensetype.ReferenceEntities, function (i) { return (i.LicenseTypeId == 5 || i.LicenseTypeId == 16) });
                            }
                            // if (response.examType.Status) {
                            //     $scope.examTypes = response.examType.ReferenceEntities;
                            // }
                            // if (response.examType.Status) {
                            //     $scope.examStatuses = response.examStatus.ReferenceEntities;
                            // }
                            // if (response.entityExamResult.data.Status && response.entityExamResult.data.EntityRecords) {
                            //     $scope.allExamResults = response.entityExamResult.data.EntityRecords;
                            // }
                            if (response.applicationSubType.Status) {
                                $scope.applicationSubTypes = response.applicationSubType.ReferenceEntities;
                            }
                            
                            if (response.individualBYIndividual.Status) {
                                $scope.individualData = response.individualBYIndividual.ProviderResponse;
                            }
                            if (response.application.data.Status) {
                                var applicationList = response.application.data.ApplicationDocumentList;

                                if (!!applicationList) {
                                    applicationList = applicationList.where(function (applicationItem) {
                                        return !(applicationItem.ApplicationTypeId == 1 && applicationItem.ApplicationStatusId == 1);
                                    });
                                    applicationList = applicationList.leftJoinObjects($scope.licenseTypes,
                                        "item1.LicenseTypeId==item2.LicenseTypeId", 'this1, licenseTypeInfo: this2');

                                    applicationList = applicationList.leftJoinObjects($scope.applicationSubTypes,
                                        "item1.ApplicationSubTypeId==item2.ApplicationSubTypeId", 'this1, ApplicationSubTypeName: item2.ApplicationSubTypeName');

                                    angular.forEach(applicationList, function (applicationItem) {
                                        if (!!$scope.entityExams) {
                                            applicationItem.allExams = $scope.allExams.whereEquals(applicationItem.ApplicationId, 'ApplicationId');
                                            applicationItem.pendingExam = $scope.entityExams.whereEquals(applicationItem.ApplicationId, 'ApplicationId')
                                                .whereEquals(false, 'ExamCompletion').firstOrDefault();
                                            applicationItem.examPassed = $scope.entityExams.whereEquals(applicationItem.ApplicationId, 'ApplicationId')
                                                .whereEquals(true, 'ExamCompletion')
                                                .whereEquals(1, 'ResultStatus').firstOrDefault();
                                        }
                                        // if (!!$scope.examTypes) {
                                        //     if (applicationItem.ApplicationSubTypeId == 2) {
                                        //         var listOfReExamRecord = [];
                                        //         _.each($scope.reExamList, function (i) {
                                        //             if (i.LicenseTypeId == applicationItem.LicenseTypeId && applicationItem.ConfirmationNumber == i.AppATSID) {
                                        //                 listOfReExamRecord.push(i.ExamTypeId);
                                        //             }
                                        //         });
                                        //         applicationItem.examTypes = $scope.examTypes.whereEquals(2, 'ExamNameId').where(function (examType) {
                                        //             return (!!examType.ExamTypeId && _.find(listOfReExamRecord, function (item) { return (examType.ExamTypeId == item) }));
                                        //         });
                                        //     }
                                        //     else {
                                        //         applicationItem.examTypes = $scope.examTypes.whereEquals(2, 'ExamNameId').where(function (examType) {
                                        //             return (!!examType.LicenseTypeIds && !!applicationItem.licenseTypeInfo && examType.LicenseTypeIds.split(',').indexOf(applicationItem.licenseTypeInfo.LicenseTypeId.toString()) != -1);
                                        //         });
                                        //     }

                                        //     if (!!applicationItem.examTypes && applicationItem.examTypes.length > 0) {
                                        //         applicationItem.canRequestStateExam = false;
                                        //         if (!!$scope.entityStateExams && $scope.entityStateExams.length > 0) {
                                        //             angular.forEach(applicationItem.examTypes, function (examType) {
                                        //                 var entityStateExamByType = $scope.entityStateExams.whereEquals(examType.ExamTypeId, 'ExamTypeId')
                                        //                     .whereIncludes([2, 3, 4], 'ExamStatusId').firstOrDefault();
                                        //                 if (!entityStateExamByType) {
                                        //                     applicationItem.canRequestStateExam = true;
                                        //                 }
                                        //             });
                                        //         } else {
                                        //             applicationItem.canRequestStateExam = true;
                                        //         }
                                        //     }
                                        // }
                                    })
                                }
                                $scope.applicationList = applicationList;
                                
                                applicationList.forEach(application => {
                                    utilityService.applicationObject = null;
                                    if (application.ApplicationType == "Renewal") {
                                        utilityService.applicationObject = {"LicenseNumber" : application.LicenseNumber, "isRenewal": true};
                                    } else {
                                        utilityService.applicationObject = {"LicenseNumber" : application.LicenseNumber, "isRenewal": false};
                                    }
                                });
                            }


                        });
                };

                $scope.openApplication = function (applicationItem, isExam) {
                    if (!!applicationItem) {
                        sessionStorage.currentApplicationId = applicationItem.ApplicationId;
                    } else {
                        sessionStorage.removeItem('currentApplicationId');
                    }
                    if (!!isExam) {

                        $state.go('app.OnlineNewExamApplication');
                    } else {
                        $state.go('app.OnlineNewApplication');
                    }
                };

                $scope.openReExamApplication = function () {
                    $scope.natValidation.errorMessages = [];
                    $scope.reExam = {};
                    $('#reExamVDIModal').modal('show');
                }

                $scope.openExamApplication = function (applicationItem) {
                    sessionStorage.currentApplicationId = applicationItem.ApplicationId;
                    if (!!applicationItem) {
                        sessionStorage.applicationItemForExam = JSON.stringify(applicationItem)
                    }
                    $state.go('app.OnlineExam');
                };

                $scope.openConfirmationPage = function (application) {
                    $state.go('app.PaymentConfirmation', {
                        entityId: $scope.individualid,
                        entityType: 'I',
                        applicationId: application.ApplicationId,
                        applicationTypeId: application.ApplicationTypeId,
                        paymentMethodTypeId: '',
                        isReSubmit: false
                    });
                };

                var resetApplications = function () {
                    angular.forEach($scope.applicationList, function (applicationItem) {
                        applicationItem.showApplicationDetails = false;
                    });
                };

                $scope.openStatusCircles = function (applicationItem) {
                    if (!applicationItem.showApplicationDetails) {
                        resetApplications();
                        applicationItem.showApplicationDetails = true;
                        applicationItem.activeTab = 0;
                    } else {
                        applicationItem.showApplicationDetails = false;
                        applicationItem.activeTab = null;
                    }
                };

                $scope.processDeficiencyApplication = function (applicationItem) {
                    sessionStorage.currentApplicationId = applicationItem.ApplicationId;
                    sessionStorage.deficientApplicationId = applicationItem.applicationId;
                    sessionStorage.isDeficientApplication = true;
                    if(!!applicationItem.ProviderId && !applicationItem.IndividualId && applicationItem.ApplicationTypeId == 1)
                    {
                        $state.go('app.FirmLicenseRenewalApplication');
                    }
                    else if (applicationItem.ApplicationTypeId == 1) {
                        $state.go('app.LicenseRenewalApplication');
                    } else {
                        $state.go('app.OnlineNewApplication');
                    }
                };


                $scope.startExam = function (currentForm) {

                    if (!!currentForm) {
                        currentForm.$submitted = true;
                        $scope.natValidation.errorMessages = angular.copy(currentForm.errorMessages);
                    }
                    if (!$scope.natValidation.errorMessages)
                        $scope.natValidation.errorMessages = [];

                    if (!$scope.reExamList || $scope.reExamList.length == 0) {
                        var errorObj = {
                            propName: "reExamListerror",
                            errorMessage: "ReExam list not loaded"
                        };
                        $scope.natValidation.errorMessages.push(errorObj);
                    }
                    else {

                        var listOfRecord = [];
                        _.each($scope.reExamList, function (i) {
                            if (i.LicenseTypeId == $scope.reExam.LicenseTypeId && $scope.reExam.ATS == i.AppATSID && utilityService.checkIfDateGreaterOrEqual(new Date(i.DOB) ,new Date($scope.individualData.DateOfBirth))) {
                                listOfRecord.push(i);
                            }
                        });
                        if (listOfRecord.length == 0) {
                            var errorObj = {
                                propName: "reExamListessrror",
                                errorMessage: "According to our records your request cannot be processed at this time. Please contact the Board at (916) 999-3635 if you have any questions."
                            };
                            $scope.natValidation.errorMessages.push(errorObj);
                        }

                        _.each($scope.applicationList, function (i) {
                            if (i.ConfirmationNumber == $scope.reExam.ATS && i.LicenseTypeId == $scope.reExam.LicenseTypeId) {
                                var errorObj = {
                                    propName: "reExamListessrror",
                                    errorMessage: "Same type of ATS number application already exist"
                                };
                                $scope.natValidation.errorMessages.push(errorObj);
                            }
                        });
                       // $scope.individualData
                    }

                    if (!!$scope.natValidation.errorMessages && $scope.natValidation.errorMessages.length > 0) {
                        $scope.natValidation.showError = true;
                        $scope.natValidation.isValid = false;
                        return false;
                    }

                    generateApplicationId(0, $scope.userId, listOfRecord);
                };

                var generateApplicationId = function (applicationID, userID, listOfRecord) {
                    var reqData = {};
                    reqData = {
                        ApplicationTypeId: 2,
                        ApplicationStatusId: 16,
                        ApplicationSubmitMode: 'O',
                        UserId: userID,
                        GoPaperless: true,
                        IsDeleted: 0,
                        IsActive: 1,
                        IsArchive: 0,
                        LicenseApplicationForId: $scope.reExam.LicenseTypeId,
                        ApplicationSubTypeId: 2,
                        LicenseExamTypeId: ((listOfRecord && listOfRecord.length > 0) ? listOfRecord[0].ExamTypeId : null),
                        EntityName: 'Re-Exam Application',
                        LicenseTypeId: $scope.reExam.LicenseTypeId,
                        ConfirmationNumber: $scope.reExam.ATS
                    };
                    ShowLoader();
                    licenseApplicationListingService.generateApplicationId(sessionStorage.Key, applicationID, userID, reqData).then(function (res) {
                        if (res.Status) {
                            $scope.ApplicationId = res.ApplicationId;
                            $scope.ApplicationTypeId = res.ApplicationTypeId;
                            $scope.applicationNumber = res.ApplicationNumber;
                            $scope.IndividualId;
                            createApplicationProcess(userID);
                        } else {
                            HideLoader();
                            $scope.showStatusMessage(Messages.General.Errors.SomeError, "error");
                        }
                    }, function (res) {
                        HideLoader();
                        $scope.showStatusMessage(res.Message, "error");
                    });
                };


                var createApplicationProcess = function (userID) {
                    var promiseSaveIndividualApplication = saveIndividualApplication($scope.IndividualId, $scope.ApplicationId, userID);
                    var promiseSaveIndividualNameTypeLk = saveIndividualNameTypeLk($scope.ApplicationId, $scope.IndividualId);
                    var promiseIndividualLicenseSave = individualLicenseSave({
                        IndividualId: $scope.IndividualId,
                        ApplicationId: $scope.ApplicationId,
                        ApplicationTypeId: 2,
                        IsLicenseTemporary: false,
                        IsLicenseActive: false,
                        LicenseNumber: '',
                        LicenseTypeId: $scope.reExam.LicenseTypeId,
                        LicenseStatusTypeId: 17, //APPLICATION IN PROCESS
                        IsActive: true,
                        IsDeleted: false,
                        ActionFlag: 'N' //Unfortunately, this is the only way to create a new license, and it ignores the IsLicenseActive
                    });

                    $q.all([promiseSaveIndividualApplication, promiseSaveIndividualNameTypeLk, promiseIndividualLicenseSave])
                        .then(function (res) {
                            var isSavedSuccessFully = true;
                            var errMessage = '';
                            angular.forEach(res, function (resData, key) {
                                if (resData.Status == false) {
                                    isSavedSuccessFully = false;
                                    errMessage += resData.Message;
                                }
                            });
                            if (isSavedSuccessFully) {
                                sessionStorage.currentApplicationId = $scope.ApplicationId;
                                $state.go('app.OnlineNewExamApplication');
                                $('#reExamVDIModal').modal('hide');
                            } else {
                                $scope.showStatusMessage(errMessage, "error");
                            }
                            HideLoader();
                        }, function (res) {
                            HideLoader();
                            $scope.showStatusMessage(res.Message, "error");
                        });
                };

                var saveIndividualApplication = function (IndividulaID, AppID, userID) {
                    var reqData = {
                        ApplicationId: AppID,
                        IndividualId: IndividulaID,
                        CreatedBy: userID,
                        CreatedOn: new Date()
                    };

                    return licenseApplicationListingService.saveIndividualApplication(sessionStorage.Key, reqData);
                };

                var saveIndividualNameTypeLk = function (appID, indvID) {
                    var reqData = {
                        IndividualId: indvID,
                        IndividualNameTypeId: 1,
                        IndividualNameStatusId: 16,
                        ApplicationId: appID,
                        IsActive: 1,
                        IsDeleted: 0,
                    };

                    return licenseApplicationListingService.saveIndividualNameTypeLk(sessionStorage.Key, reqData);
                };

                var individualLicenseSave = function (newLicense) {
                    return licenseApplicationListingService.saveIndividualLicense(sessionStorage.Key, newLicense);
                };


                $scope.init();
            }
        ]);
})();